import {useCallback} from "react";
import {Application, Document, downloadFileBlob, IDocuments, openFileBlob} from "library";
import {viewOrDownloadApplicationDocument} from "store/slices";
import {useDispatch} from "store";
import {SupportingDocuments} from "components";

type ApplicationDocumentsFormProps = {
	value: Application | undefined;
	readonly: boolean;
	onDelete: (document: Document) => Promise<void>;
	onAdd: (value: { files: File[], type: keyof IDocuments }) => Promise<void>;
}

const ApplicationDocumentsForm = ({value, readonly, onDelete, onAdd}: ApplicationDocumentsFormProps) => {
	const dispatch = useDispatch();

	const deleteSupportingDocument = useCallback(async (document: Document) => {
		await onDelete(document);
	}, [onDelete]);

	const addSupportingDocuments = useCallback(async (files: File[], type: keyof IDocuments) => {
		await onAdd({files, type});
	}, [onAdd]);

	const onViewDocument = useCallback(async (fileName: string, documentType: string) =>
		await dispatch(viewOrDownloadApplicationDocument({fileName, documentType}))
			.unwrap()
			.then(r => {
				openFileBlob(r.fileBlob);
			}), [dispatch]);

	const onDownloadDocument = useCallback(async (fileName: string, documentType: string) =>
		await dispatch(viewOrDownloadApplicationDocument({fileName, documentType}))
			.unwrap()
			.then(r => {
				downloadFileBlob(r.fileBlob, r.fileName);
			}), [dispatch]);

	return !value ? <></> : (
		<SupportingDocuments documents={value.documents} readonly={readonly} onView={onViewDocument} onDownload={onDownloadDocument} onAdd={addSupportingDocuments} onDelete={deleteSupportingDocument}/>
	);
};

export {
	ApplicationDocumentsForm
};