abstract class RateRule {
    readonly id: string;

    protected constructor(readonly service: string, id?: string) {
        this.id = id ?? crypto.randomUUID();
    }
}

class Rates implements Record<string, RateRule[]> {
    [key: string]: RateRule[];

    constructor(from?: Rates) {
        Object.entries(from ?? {}).forEach(([k, v]) => this[k] = v);
    }
}

export {RateRule, Rates};