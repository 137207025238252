import styles from "styles/RateForm.module.scss";

import {FormControlLabel, Grid} from "@mui/material";
import React, {ReactNode, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "store";
import {Application, ApplicationType, CurrencyDisplay, oneThirdWidth, TrabRateRule, twoThirdWidth} from "library";
import {loadRates} from "store/slices/rates";

type TrabRateCalculationProps = {
    application: Application;
}

const TrabRateCalculation: (props: TrabRateCalculationProps) => ReactNode = ({application}) => {
    const dispatch = useDispatch();
    const labelStyle = {label: styles.label, root: styles.label};
    const {rates: {TRAB: rates}} = useSelector(s => s.rates);

    useEffect(() => {
        dispatch(loadRates());
    }, [dispatch]);

    const rate = useMemo(() => (rates as TrabRateRule[])
        ?.filter(r => (r.bedrooms ?? 0) <= application.bedrooms)
        .sort((a, b) => (a.bedrooms ?? 0) - (b.bedrooms ?? 0))
        .at(-1), [application.bedrooms, rates]);

    if (!rate)
        return <>No rate found that matches conditions of application</>;

    return (
        <Grid container>
            <Grid item {...oneThirdWidth}>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(application.type === ApplicationType.Standard ? rate.year1Amount : rate.year2Amount)}</div>} label={"TRAB Subsidy Amount"}/>
            </Grid>
            <Grid item {...twoThirdWidth}>
                <Grid container>
                    <Grid item {...oneThirdWidth}>
                        <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={styles.keyValue}>{rate.bedrooms}</div>} label={"Bedrooms"}/>
                    </Grid>
                    <Grid item {...oneThirdWidth}>
                        <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.year1Amount)}</div>} label={"Year 1"}/>
                    </Grid>
                    <Grid item {...oneThirdWidth}>
                        <FormControlLabel classes={labelStyle} labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.year2Amount)}</div>} label={"Year 2"}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export {
    TrabRateCalculation
}