import {RateStatus} from "../enums";
import {RateRule} from "./rates";
import {getDateFrom} from "../dates";

class RabRateRule extends RateRule {
    readonly name: string;
    readonly municipality: string;
    readonly effectiveStart: Date;
    readonly effectiveEnd: Date;
    readonly bedrooms?: number;
    readonly minimumSubsidy?: number;
    readonly maximumSubsidy?: number;
    readonly maximumMarketRent?: number;
    readonly status: RateStatus;
    readonly notes: string;

    constructor(from?: Partial<RabRateRule>) {
        super("RAB", from?.id);

        this.name = from?.name ?? "";
        this.municipality = from?.municipality ?? "";
        this.effectiveStart = getDateFrom(from?.effectiveStart);
        this.effectiveEnd = getDateFrom(from?.effectiveEnd);
        this.bedrooms = from?.bedrooms;
        this.minimumSubsidy = from?.minimumSubsidy;
        this.maximumSubsidy = from?.maximumSubsidy;
        this.maximumMarketRent = from?.maximumMarketRent;
        this.status = from?.status ?? RateStatus.Inactive;
        this.notes = from?.notes ?? "";
    }
}

export {RabRateRule};