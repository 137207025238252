import {IncomeTypeStatus} from "./enums";

interface IFixedIncomeRate {
    monthlyIncome?: number;
    priority?: number;
}

class IncomeType {
    readonly id?: string;
    readonly code: string;
    readonly name: string;
    readonly excluded: boolean;
    readonly incomeSupport: boolean;
    readonly status: IncomeTypeStatus;
    readonly fixedIncomeRate?: IFixedIncomeRate;
    readonly isSocialProgram: boolean;

    constructor(from?: Partial<IncomeType>) {
        this.id = from?.id;
        this.code = from?.code ?? "";
        this.name = from?.name ?? "";
        this.excluded = from?.excluded ?? false;
        this.incomeSupport = from?.incomeSupport ?? false;
        this.status = from?.status ?? IncomeTypeStatus.Active;
        this.fixedIncomeRate = from?.fixedIncomeRate;
        this.isSocialProgram = from?.isSocialProgram ?? false;
    }
}

export {
    IncomeType,
    type IFixedIncomeRate
};