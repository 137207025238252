import styles from "styles/PeriodPicker.module.scss";

import {ReactNode} from "react";
import {Button} from "@mui/material";
import {Period} from "library";

interface IPeriodPickerProps {
    period: Period;
    onChange: (period: Period) => void;
}

const PeriodPicker: (props: IPeriodPickerProps) => ReactNode = ({period, onChange}) => {
    return (
        <div className={styles.container}>
            <Button className={styles.button} variant={"outlined"} color={"secondary"} onClick={() => onChange(period.getPrevious())}>Previous</Button>
            <fieldset className={styles.period}>
                <legend>Period</legend>
                <span>
                    {period.year} - {`${period.month}`.padStart(2,'0')}
                </span>
            </fieldset>

            <Button className={styles.button} variant={"outlined"} color={"secondary"} onClick={() => onChange(period.getNext())}>Next</Button>
        </div>
    );
};

export {
    PeriodPicker
}