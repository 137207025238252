import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";
import {ProgramBudget} from "library";

const loadProgramBudgets = createAppAsyncThunk("programBudgets/load", async (_: void, {getState}) => {
	const {programBudgets: {loading, items}} = getState();
	if (!loading) return items;

	return agent.ProgramBudgets.list();
});

const upsertProgramBudget = createAppAsyncThunk("programBudgets/save", async (value: ProgramBudget, {getState}) => {
	const result = await agent.ProgramBudgets.upsert(value);

	// update cache
	const {programBudgets: {items}} = getState();
	const index = items.findIndex((i) => i.id === result.id);

	if (index > -1)
		items[index] = result;
	else
		items.push(result);

	return {items};
});

const deleteProgramBudget = createAppAsyncThunk("programBudgets/delete", async (id: string, {getState}) => {
	const {programBudgets: {items}} = getState();

	await agent.ProgramBudgets.delete(id);
	return {items: items.filter((i) => i.id !== id)};
});

const initialState = {
  loading: true,
  items: [] as ProgramBudget[],
};

const programsBudgets = createSlice({
	name: "programBudgets",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loadProgramBudgets.pending, (state) => ({...state, loading: true}));
		builder.addCase(loadProgramBudgets.fulfilled, (_, {payload}) => ({items: payload, loading: false}));
		builder.addCase(upsertProgramBudget.pending, (state) => ({...state, loading: true}));
		builder.addCase(upsertProgramBudget.fulfilled, (state, {payload}) => ({...state, ...payload, loading: false}));
		builder.addCase(deleteProgramBudget.pending, (state) => ({...state, loading: true}));
		builder.addCase(deleteProgramBudget.fulfilled, (state, {payload}) => ({...state, ...payload, loading: false}));
	}
});

export default programsBudgets.reducer;
export { loadProgramBudgets, upsertProgramBudget, deleteProgramBudget };
