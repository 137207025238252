import styles from "styles/application.module.scss";

import {useCallback, useMemo, useState} from "react";

import {FormControlLabel, Grid, Modal} from "@mui/material";
import MuiChip from "@mui/material/Chip";
import {BankingDetails, ContactDetail, EmergencyContacts, HousingDetails, JourneyForm, MemberDetail, ScoreBreakdown, SituationDetail} from "components";
import {StatusDialog} from "components/StatusDialog";
import {ApplicantInformation, Application, ApplicationContent, ApplicationDocuments, ApplicationStatus, ApplicationType, Document, DocumentTypeMap, fullWidth, halfWidth, IDocuments, Note} from "library";
import {useDispatch, useSelector} from "store";
import {viewApplicationFinancialInfo} from "store/slices/application";
import {ApproveApplication, ApproveApplicationState, ApprovedAnnualReview, ApprovedApplication, ApprovedInterimReview, ApproveInterimReview, ApproveReview, PaymentInformationRequired, RequestDocuments, SubsidyCalculation} from ".";
import {ApplicationActions} from "./Application.Actions";
import {ApplicationKeyData} from "./Application.KeyData";
import {ApplicationNotes} from "./Application.Notes";
import {ApplicationHousehold} from "./Application.Household";
import {ApplicationDocumentsForm} from "./Application.Documents";

enum AfterDialogResponses {
	Approved = 1,
	PaymentInfoRequired = 2
}

type SummaryApplicationProps = {
	application: Application | undefined,
	readonly: boolean,
	onSave: (value: Partial<Application>, note?: Note) => Promise<void>;
	onRequestDocuments: (value: { application: Application, specialMessage: string, sendEmail: boolean }) => Promise<void>;
	onStatusChange: (value: { status: ApplicationStatus, message: string, notes: string, sendEmail: boolean }) => Promise<void>;
	onApprove: (value: ApproveApplicationState) => Promise<void>;
	onDeleteSupportingDocument: (value: Document) => Promise<void>;
	onAddSupportingDocument: (value: { files: File[], type: keyof IDocuments }) => Promise<void>;
};

const ApplicationForm = ({application: value, readonly, onSave, onRequestDocuments, onStatusChange, onApprove, onAddSupportingDocument, onDeleteSupportingDocument}: SummaryApplicationProps) => {
	const dispatch = useDispatch();
	const [processDialogValue, setShowProcessDialog] = useState<ApplicationStatus | AfterDialogResponses>();
	const {programs} = useSelector(s => s.programs);

	const closeProcessDialog = useCallback((_: any = {}, reason: string = "") => {
		if (reason) return;
		setShowProcessDialog(undefined)
	}, [setShowProcessDialog]);

	const onView = useCallback(async () => {
        dispatch(viewApplicationFinancialInfo()).unwrap().then();
    }, [dispatch]);

	const approve = useCallback(async (approval: ApproveApplicationState) => {
		if (!value) return;

		if (!value.documents.bankInfo.canProcess) {
			setShowProcessDialog(AfterDialogResponses.PaymentInfoRequired);
			return;
		}

		setShowProcessDialog(AfterDialogResponses.Approved);
		await onApprove(approval);
	}, [setShowProcessDialog, value, onApprove]);
	
	const changeStatus = useCallback((status: ApplicationStatus, message: string, notes: string, sendEmail: boolean) => {
		if (!value) return;
		onStatusChange({status, notes, message, sendEmail})
			.then(() => closeProcessDialog());
	}, [value, onStatusChange, closeProcessDialog]);

	const sendDocumentRequest = useCallback((documents: Partial<IDocuments>, specialMessage: string, sendEmail: boolean) => {
		if (!value) return;

		const documentsRequested = new ApplicationDocuments(documents);
		Object.entries(DocumentTypeMap).map(([k]) => 
			value.documents[k] = {...value.documents[k], required: documentsRequested[k].required}
		);
		const application = new Application({...value, documents: new ApplicationDocuments({...value.documents, specialMessage})});
		onRequestDocuments({application, specialMessage, sendEmail})
			.then(() => closeProcessDialog());
	}, [value, onRequestDocuments, closeProcessDialog]);

	const statusDescription = useCallback((verb: string, followUpText?: string) => (<>
		An email will be sent out to <a href={`mailto: ${value?.applicant.email?.address}`}>{value?.applicant.email?.address ?? ""}</a>{" "}
		notifying the {ApplicationContent[value!.type]!.applicant.toLowerCase()} that their {ApplicationContent[value!.type].heading.toLowerCase()} has been {verb}.
		{followUpText ?? ` Please complete the following details to complete the processing of this ${verb} application.`}
	</>), [value]);

    const statusContent = useCallback((title: string) => (
        <div className={`${styles.modalText} ${styles.fullWidth}`}>
            <span className={styles.bold}>{title}</span>
			<ul>{value?.programs.map(p => <li key={p}>{p}</li>)}</ul>
        </div>
    ), [value?.programs]);

	const ApproveDialog: Record<ApplicationType, JSX.Element> = useMemo(() => ({
		[ApplicationType.AnnualReview]: <ApproveReview value={value!} onCancel={closeProcessDialog} onApprove={approve} />,
		[ApplicationType.Standard]: <ApproveApplication value={value!} onCancel={closeProcessDialog} onApprove={approve} />,
		[ApplicationType.InterimReview]: <ApproveInterimReview value={value!} onCancel={closeProcessDialog} onApprove={approve} />
	}), [value, closeProcessDialog, approve]);

	const ApprovedDialog : Record<ApplicationType, JSX.Element> = useMemo( () => ({
		[ApplicationType.AnnualReview]: <ApprovedAnnualReview value={value!} onClose={closeProcessDialog} />,
		[ApplicationType.Standard]: <ApprovedApplication value={value!} onClose={closeProcessDialog}/>,
		[ApplicationType.InterimReview]: <ApprovedInterimReview value={value!} onClose={closeProcessDialog}/>
	}), [value, closeProcessDialog])

	const NextStatusDialog: Partial<Record<ApplicationStatus | AfterDialogResponses, JSX.Element>> | undefined = useMemo(() => value && ({
		[AfterDialogResponses.PaymentInfoRequired]: <PaymentInformationRequired onClose={closeProcessDialog}/>,
		[ApplicationStatus.Submitted]: <StatusDialog status={ApplicationStatus.Submitted} description={statusContent("Will be submitted for the following program(s):")} content={""} onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[ApplicationStatus.Eligible]: <StatusDialog status={ApplicationStatus.Eligible} content={""}
													description={statusDescription("deemed eligible for rent subsidy but has not been officially approved (activated)", "Their application will wait on a waitlist for final approval (activation)")}
													onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[ApplicationStatus.Approved]: ApproveDialog[value.type],
		[AfterDialogResponses.Approved] : ApprovedDialog[value.type],
		[ApplicationStatus.WaitingForSupportingDocuments]: <RequestDocuments applicationType={ApplicationType.Standard} applicant={value.applicant} onCancel={closeProcessDialog} onApprove={sendDocumentRequest}/>,
        [ApplicationStatus.Rejected]: <StatusDialog applicationType={value.type} status={ApplicationStatus.Rejected} description={statusDescription("rejected")}
													content={statusContent(`${ApplicationContent[value.type].applicant} does not qualify for the following programs:`)} onCancel={closeProcessDialog} onProceed={changeStatus}/>,
        [ApplicationStatus.Cancelled]: <StatusDialog applicationType={value.type} status={ApplicationStatus.Cancelled} description={statusDescription("cancelled")}
													 content={statusContent(`${ApplicationContent[value.type].heading} to be cancelled was for the following program(s):`)} onCancel={closeProcessDialog} onProceed={changeStatus}/>,
		[ApplicationStatus.OnHold]: <StatusDialog applicationType={value.type} status={ApplicationStatus.OnHold} description={statusDescription("suspended")}
												  content={statusContent("Annual Review will be suspended for the following programs:")} onCancel={closeProcessDialog} onProceed={changeStatus}/>
    }), [value, closeProcessDialog, statusDescription, changeStatus, ApproveDialog, ApprovedDialog, sendDocumentRequest, statusContent]);

	return !value ? <></> : (
		<Grid container>
			<Modal open={!!processDialogValue} onClose={closeProcessDialog}>
				<>{NextStatusDialog![processDialogValue!] ?? <></>}</>
			</Modal>

			<MemberDetail label={ApplicationContent[value.type].applicant} readonly={readonly} requireSin value={value.applicant} className={styles.formDetail}
						  onChange={async v => await onSave({applicant: new ApplicantInformation(v as ApplicantInformation)})}/>
			<ContactDetail value={value.contacts} readonly={readonly} onChange={async v => await onSave({contacts: v})}/>
			<JourneyForm title={"Journey"} journey={value.journey}/>

			<Grid item {...halfWidth} className={`${styles.card} ${styles.unbordered}`}>
				<Grid item {...fullWidth}>
					<FormControlLabel label={ApplicationContent[value.type].programs} labelPlacement={"top"} classes={{label: styles.fullWidth}} control={
						<div className={styles.chips}>
							{value.programs.map(p =>
                                <MuiChip key={p} label={programs.find(inner => inner.shortName === p)?.longName} color={"warning"}/>)}
						</div>} />
				</Grid>
				<Grid item {...fullWidth}>
					<ApplicationKeyData value={value} readonly={readonly} onChange={async (v, note) => await onSave(v, note)}/>
				</Grid>
				{!readonly &&
					<ApplicationActions type={value.type} status={value.status} canProcess={value.canProcess} onStatusChange={setShowProcessDialog}/>
				}
			</Grid>

			<SubsidyCalculation application={value}/>
			{value.type === ApplicationType.Standard && (<>
				<ScoreBreakdown value={value.pointScore}/>
				<SituationDetail value={value.situation} readonly={readonly} onChange={v => onSave({situation: v})}/>
			</>)}
			<HousingDetails value={value.housing} readonly={readonly} onChange={v => onSave({housing: v})}/>
			<ApplicationHousehold value={value} readonly={readonly} onSave={onSave}/>
			<ApplicationDocumentsForm value={value} readonly={readonly} onAdd={onAddSupportingDocument} onDelete={onDeleteSupportingDocument}/>
			<BankingDetails value={value.documents.bankInfo} readonly={readonly} onChange={v => onSave({documents: new ApplicationDocuments({...value?.documents, bankInfo: v})})} onView={onView}/>
			<EmergencyContacts readonly={readonly} value={value.contacts} onChange={async v => await onSave({contacts: v})}/>
			<ApplicationNotes value={value.notes} onChange={v => onSave({notes: v})}/>
		</Grid>
	);
};

export {
	ApplicationForm
};
