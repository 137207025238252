// material-ui
import { styled } from '@mui/material/styles';

// assets
import ppulusLogo from 'assets/images/ppulus_icon_XS.png';
import { ListItem } from "@mui/material";

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    return (
        <ListItemWrapper>
            <ListItem alignItems="center">
                <p>No new notifications, you're all caught up!</p>
            </ListItem>
            <ListItem alignItems="center" sx={{ justifyContent: "center", minHeight: "200px"}}>
                <img alt={"bettering social outcomes"} height={"150px"} src={ppulusLogo} />
            </ListItem>
        </ListItemWrapper>
    );
};

export default NotificationList;
