import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import agent from "api/agent";
import {Identity} from "library/identity";

const getStaff = createAsyncThunk("staff/load", async () => {
	return (await agent.Staff.list().then(s => s.filter(n => !!n.name)).then(i => i.sort((a,b) => a.name!.localeCompare(b.name!))));
});

const staff = createSlice({
	name: "staff",
	initialState: {loading: false, items: new Array<Identity>()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getStaff.pending, (state) => ({loading: true, items: []}));
		builder.addCase(getStaff.fulfilled, (state, {payload}) => ({loading: false, items: payload}));
	}
});

export default staff.reducer;

export {getStaff};