import styles from "styles/application.module.scss";

import {Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {DateDisplay, FileInfo, fullWidth, Note, oneThirdWidth, twoThirdWidth} from "library";
import React, {FC, useState} from "react";
import {DataGrid, GridActionsColDef, GridColDef} from "@mui/x-data-grid";
import {Lock, LockOpen} from "@mui/icons-material";
import {DocumentsCard} from "components/DocumentsCard";
import useAuth from "hooks/useAuth";
import {FormValueDisplay, PpulusLoader} from "components";

const NotesList: FC<{ notes: Note[], onChange: (notes: Note[]) => Promise<void>, onDownload: (fileName: string) => void }> = ({notes, onChange, onDownload}) => {
    const {user} = useAuth();
    const [editMode, setEditMode] = useState(false);
    const [viewedNote, setViewedNote] = useState<Note>();
    const [processing, setProcessing] = useState(false);

    const onView = (note: Note, editMode: boolean) => {
        setViewedNote(note);
        setEditMode(editMode);
    };

    const onSave = async (note: Note) => {
        setProcessing(true);
        note.id
            ? await onChange(notes.map(n => n.id !== note?.id ? n : new Note({...note, modifiedBy: user?.name})))
                .then(() => setProcessing(false))
            : await onChange([...notes, new Note({...note, createdBy: user?.name})])
                .then(() => setProcessing(false));
        setEditMode(false);
        setViewedNote(undefined);
    };

    const onDelete = async (note: Note) => {
        setProcessing(true);
        await onChange(notes.filter(n => n.id !== note.id))
            .then(() => setProcessing(false));
    }

    return (
        <>
            {!!viewedNote
                ? <NoteForm value={viewedNote} editMode={editMode} onCancel={() => setViewedNote(undefined)} onSave={onSave} onDownload={onDownload}/>
                : <NoteGrid notes={notes} processing={processing} onNew={() => onView(new Note(), true)} onView={onView} onDelete={onDelete}/>
            }
        </>
    )
};

const NoteGrid: FC<{ notes: Note[], processing: boolean, onNew: () => void, onView: (note: Note, editMode: boolean) => void, onDelete: (note: Note) => Promise<void> }> = ({notes, processing, onNew, onView, onDelete}) => {
    const columns: GridColDef[] = [
        {field: "createdOn", headerName: "Date", width: 180, headerClassName: styles.columnHeader, valueFormatter: v => DateDisplay.StandardTime(v.value)},
        {field: "subject", headerName: "Title / Subject", flex: 1, headerClassName: styles.columnHeader},
        {field: "message", headerName: "Note", flex: 1, headerClassName: styles.columnHeader},
        {field: "createdBy", headerName: "Created By", width: 140, headerClassName: styles.columnHeader},
        {field: "internal", headerName: "Visibility", width: 90, headerClassName: styles.columnHeader, renderCell: p => p.value ? "Internal" : "Public"},
        {field: "isPriority", headerName: "Priority?", width: 80, headerClassName: styles.columnHeader, renderCell: p => p.value ? "Yes" : "No"},
        {
            field: "actions", type: "actions", width: 200, align: "right", sortable: false, headerClassName: styles.columnHeader, getActions: params => [
                <Button disabled={processing} onClick={() => onView(params.row, false)}>View</Button>,
                <Button disabled={processing} onClick={() => onView(params.row, true)}>Edit</Button>,
                <Button disabled={processing} onClick={() => window.confirm("Are you sure you want to delete this note?") && onDelete(params.row)}>Delete</Button>
            ]
        } as GridActionsColDef<Note>
    ];

    return (
        <>
            <Grid item {...fullWidth} className={styles.buttonGroup}>
                <Typography variant={"h3"}>Notes</Typography>
                <ButtonGroup className={styles.right}>
                    <Button disabled={processing} variant={"outlined"} size="small" onClick={onNew}>New Note</Button>
                </ButtonGroup>
            </Grid>

            <DataGrid autoHeight
                      loading={processing}
                      initialState={{columns: {columnVisibilityModel: {id: false}}, sorting: {sortModel: [{field: "createdOn", sort: "desc"}]}}}
                      columns={columns}
                      paginationMode={"client"} hideFooterPagination
                      rows={notes}
                      getRowId={row => row.id}
                      components={{NoRowsOverlay: () => <div className={styles.emptyGrid}>None</div>}}
            />
        </>
    );
}

const NoteForm: FC<{ value?: Note, editMode: boolean, onCancel: () => void, onSave: (note: Note) => Promise<void>, onDownload: (fileName: string) => void }> = ({value, editMode, onCancel, onSave, onDownload}) => {
    const [note, setNote] = useState(new Note(value));
    const [processing, setProcessing] = useState(false);

    const set = async (value: Partial<Note>) => {
        setNote(new Note({...note, ...value}));
    };

    const saveNote = () => {
        const validated = note.validate();
        setNote(validated);
        if (Object.values(validated.errorState).some(v => !!v)) return;

        setProcessing(true);
        onSave(validated)
            .then(() => setProcessing(false));
    };

    return (
        <>
            {processing && <PpulusLoader/>}
            <Grid item {...fullWidth} display={"flex"} className={styles.noteRow}>
                <Grid item {...twoThirdWidth}>
                    <FormValueDisplay label={""} valueWidth={fullWidth}
                                      value={<TextField label={"Subject / Title"}
                                                        value={note.subject}
                                                        disabled={!editMode || processing}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        onChange={e => set({subject: e.target.value})}
                                      />}
                    />
                </Grid>
                <Grid item {...oneThirdWidth}>
                    <FormValueDisplay label={""} className={styles.flexEnd}
                                      value={<FormControlLabel label={"Priority Note"}
                                                               labelPlacement={"start"}
                                                               checked={note.isPriority}
                                                               disabled={!editMode || processing}
                                                               control={<Checkbox onChange={() => set({isPriority: !note.isPriority})}/>}
                                      />}
                    />
                                      
                    <FormValueDisplay label={""} className={styles.flexEnd}
                                      value={<FormControlLabel label={note.internal ? "Internal" : "Public"}
                                                               labelPlacement={"start"}
                                                               checked={note.internal}
                                                               disabled={!editMode || processing}
                                                               control={<Checkbox icon={<LockOpen/>} checkedIcon={<Lock/>} onChange={() => set({internal: !note.internal})}/>}
                                      />}
                    />
                </Grid>
            </Grid>
            <Grid item {...fullWidth} className={styles.noteRow}>
                <FormValueDisplay label={""} valueWidth={fullWidth}
                                  value={<TextField label={"Note"}
                                                    value={note.message}
                                                    disabled={!editMode || processing}
                                                    error={!!note.errorState.note} helperText={note.errorState.note}
                                                    variant={"outlined"}
                                                    fullWidth multiline rows={4}
                                                    onChange={e => set({message: e.target.value})}/>}
                />
            </Grid>
            <Grid item {...fullWidth} className={styles.noteRow}>
                <div className={styles.fullWidth}>
                    <FormValueDisplay label={""} valueWidth={fullWidth}
                                      value={<DocumentsCard label={"Note files"}
                                                            emptyLabel={"No note files"}
                                                            files={note.files ?? []}
                                                            readonly={!editMode || processing}
                                                            onView={(fileName) => onDownload(fileName)}
                                                            onAdd={(files) => set({files: [...note.files, ...files.map(f => new FileInfo(f))]})}
                                                            onDelete={(file) => set({files: note.files.filter(f => f.name !== file.name && f.size !== file.size)})}/>}
                    />
                </div>
            </Grid>
            <ButtonGroup className={`${styles.fullWidth} ${styles.buttonGroup}`}>
                <Box className={styles.right}>
                    {editMode
                        ? <>
                            <Button disabled={processing} variant={"contained"} color={"error"} className={`${styles.button}`} onClick={onCancel}>Cancel</Button>
                            <Button disabled={processing} variant={"contained"} className={styles.button} onClick={saveNote}>Save</Button>
                        </>
                        : <Button disabled={processing} variant={"contained"} className={styles.button} onClick={onCancel}>Return</Button>
                    }

                </Box>
            </ButtonGroup>
        </>
    );
}

export {
    NotesList
};