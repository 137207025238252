import styles from "styles/application.module.scss";

import React, {ReactNode, useMemo, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {IconDownload, IconEye, IconFile, IconMinus, IconPlus, IconTrash} from "@tabler/icons";
import {Button, Card, CardContent, CardHeader} from "@mui/material";
import {Accept, FileDropzone} from "./FileDropzone";
import {FileInfo} from "library";
import {PpulusLoader} from "./household";

interface IDocumentsCardProps {
    label: string;
    emptyLabel: string;
    readonly?: boolean;
    disableView?: boolean;
    expanded?: boolean;
    files: FileInfo[];
    accept?: Accept;
    onView?: (fileName: string) => void;
    onDownload?: (fileName: string) => void;
    onAdd: (value: File[]) => Promise<void>;
    onDelete: (value: FileInfo) => void;
}

const DocumentsCard: (props: IDocumentsCardProps) => ReactNode = ({label, emptyLabel, expanded, readonly, disableView, files, accept, onView, onDownload, onAdd, onDelete}) => {
    const theme = useTheme();
    const [dropzoneDisplayed, setDisplayDropzone] = useState(expanded);

    const headerButton = useMemo(() => !dropzoneDisplayed
            ? <IconPlus color={theme.palette.primary.main} cursor={"pointer"} onClick={() => setDisplayDropzone(true)}/>
            : <IconMinus color={theme.palette.primary.main} cursor={"pointer"} onClick={() => setDisplayDropzone(false)}/>
        , [theme.palette.primary.main, dropzoneDisplayed, setDisplayDropzone]);

    return (
        <Card variant={"outlined"} className={styles.documentCard}>
            <CardHeader title={label} action={!readonly && headerButton} className={styles.documentHeader} />
            <CardContent className={styles.documentContents}>
                {!!files.length
                    ? files.map((f, i) =>
                        <DocumentItem key={i} value={f} readonly={readonly} disableView={disableView} onView={onView ? () => onView(f.name) : undefined} onDownload={onDownload ? () => onDownload(f.name) : undefined} onDelete={onDelete}/>
                    )
                    : <span className={styles.noDocuments}>{emptyLabel}</span>
                }
                {dropzoneDisplayed && <FileDropzone onFileAdd={onAdd} readonly={readonly} accept={accept}/>}
            </CardContent>
        </Card>
    );
}

interface IDocumentItemProps {
    value: FileInfo;
    readonly?: boolean;
    disableView?: boolean;
    onView?: () => void;
    onDownload?: () => void;
    onDelete: (value: FileInfo) => void
}

const DocumentItem: (props: IDocumentItemProps) => ReactNode = ({value, readonly, disableView, onView, onDownload, onDelete}) => {
    const sizeString = useMemo(() => value instanceof FileInfo ? value.sizeString : new FileInfo(value).sizeString, [value]);

    return (
        <div className={styles.documentContainer}>
            <div className={styles.documentText}><IconFile/> {value.name}</div>
            <div>{sizeString}</div>
            <div className={styles.documentActions}>
                {disableView
                    ? <></>
                    : value.processing
                        ? <Button variant={"text"} disabled><PpulusLoader small/></Button>
                        : <>
                            {!!onView && <Button variant={"text"} onClick={onView}><IconEye /></Button>}
                            {!!onDownload && <Button variant={"text"} onClick={onDownload}><IconDownload /></Button>}
                            {!readonly && <Button variant={"text"} onClick={() => onDelete(value)}><IconTrash /></Button>}
                        </>
                }
            </div>
        </div>
    );
}

export {
    DocumentsCard
}