import {SupportingDocuments} from "components";
import {ReactNode, useCallback, useState} from "react";
import {ApplicationDocuments, Document, IDocuments, downloadFileBlob, openFileBlob} from "library";
import {useDispatch} from "store";
import {addClientDocuments, deleteClientDocument, viewOrDownloadClientDocument} from "store/slices";
import {Card} from "@mui/material";

type ClientDocumentsProps = {
    readonly?: boolean;
    documents: ApplicationDocuments | undefined;
}

const ClientDocuments: (props: ClientDocumentsProps) => ReactNode = ({documents, readonly}) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(documents ?? new ApplicationDocuments())

    const onView = useCallback(async (fileName: string, documentType: keyof IDocuments) => {
        dispatch(viewOrDownloadClientDocument({fileName, documentType}))
        .unwrap()
        .then(r => { openFileBlob(r.fileBlob) })
    }, [dispatch]);

    const onDownload = useCallback(async (fileName: string, documentType: keyof IDocuments) => {
        dispatch(viewOrDownloadClientDocument({fileName, documentType}))
        .unwrap()
        .then(r => { downloadFileBlob(r.fileBlob, r.fileName) })
    }, [dispatch]);

    const onAdd = useCallback(async (files: File[], type: keyof IDocuments) => {
        await dispatch(addClientDocuments({files, type})).unwrap().then(c => setValue(c.documents));
    }, [dispatch, setValue]);

    const onDelete = useCallback(async (document: Document) => {
        dispatch(deleteClientDocument(document)).unwrap().then(c => setValue(c.documents));
    }, [dispatch, setValue]);

    return (
      <Card>
          <SupportingDocuments documents={value} readonly={readonly} onView={onView} onDownload={onDownload} onAdd={onAdd} onDelete={onDelete}/>
      </Card>
    );
};

export {
    ClientDocuments
}