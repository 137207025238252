interface IJourneyEntry {
    eventType: string;
    actionDate: Date;
    user: string;
}

class Journey {
    readonly entries: IJourneyEntry[];
    
    constructor(...entries: IJourneyEntry[]) {
        this.entries = entries ?? [];
    }
}

export type {IJourneyEntry};
export {Journey};