import styles from "styles/application.module.scss";

import {Grid, Input, Typography} from "@mui/material";
import {CurrencyDisplay, DateDisplay, fullWidth, IHousing, oneThirdWidth, YesNo} from "library";
import {IconCurrencyDollar} from "@tabler/icons";
import React, {ReactNode, useEffect, useState} from "react";
import {BedroomCountInput, DateInput, FormValueDisplay, YesNoButton} from "components";
import {Actions} from "./Actions";

interface IHousingDetailsProps {
	value: IHousing;
	readonly?: boolean;
	onChange: (value: IHousing) => Promise<void>;
}

const HousingDetails: (props: IHousingDetailsProps) => ReactNode = ({value, readonly, onChange}) => {
	const [processing, setProcessing] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editValue, setEditValue] = useState(value);

	useEffect(() => {
		setEditValue(value);
	}, [value]);
	
	const set = (value: Partial<IHousing>) => {
		setEditValue({...editValue, ...value});
	}

	const onCancel = () => {
		setEditValue(value);
		setEditMode(false);
	};

	const onSave = async () => {
		if (!onChange) return;
		
		setProcessing(true);
		onChange(editValue)
			.then(() => setEditMode(false))
			.finally(() => setProcessing(false));
	};
	
	return (
		<Grid container className={styles.card}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"} className={styles.title}>
					Housing Details {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onSave={onSave} onCancel={onCancel}/>}
				</Typography>
			</Grid>

			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Monthly Rent"} value={!editMode 
					? CurrencyDisplay(value.monthlyRent) 
					: <Input type={"number"} fullWidth
					         value={editValue.monthlyRent}
					         disabled={processing}
					         startAdornment={<IconCurrencyDollar />}
					         onChange={v => set({monthlyRent: Number(v.target.value)})} />
				} />
				<FormValueDisplay label={"# of Bedrooms"} value={!editMode
					? value.bedrooms
					: <BedroomCountInput value={editValue.bedrooms} disabled={processing} onChange={v => set({bedrooms: v})} />
				} />
				<FormValueDisplay label={"Live-In Aide?"} value={!editMode 
					? value.liveInAide
					: <YesNoButton value={editValue.liveInAide} disabled={processing} onChange={v => set({liveInAide: v})} />
				} /> 
			</Grid>

			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Pay for Water?"} value={!editMode 
					? value.water
					: <YesNoButton value={editValue.water} disabled={processing} onChange={v => set({water: v})} />
				} />
				<FormValueDisplay label={"Pay for Electricity?"} value={!editMode 
					? value.electricity
					: <YesNoButton value={editValue.electricity} disabled={processing} onChange={v => set({electricity: v})} />
 				} />
				<FormValueDisplay label={"Pay for Heat?"} value={!editMode
					? value.heating
					: <YesNoButton value={editValue.heating} disabled={processing} onChange={v => set({heating: v})} />
				} />
			</Grid>

			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"Monthly Utilities"} value={!editMode
					? CurrencyDisplay(value.monthlyUtilities)
					: <Input type={"number"} value={editValue.monthlyUtilities} disabled={processing} fullWidth onChange={v => set({monthlyUtilities: Number(v.target.value)})} />
				} />
				<FormValueDisplay label={"Lease Expiry Date"} value={!editMode
					? value.onAMonthToMonthLease === YesNo.Yes ? "N/A" : DateDisplay.Standard(value.leaseExpiry)
					: editValue.onAMonthToMonthLease === YesNo.Yes ? <></> : <DateInput value={editValue.leaseExpiry} disabled={processing} onChange={v => set({leaseExpiry: v})}/>
				} />
				<FormValueDisplay label={"Month to Month Lease?"} value={!editMode 
					? value.onAMonthToMonthLease ?? YesNo.No
					: <YesNoButton value={editValue.onAMonthToMonthLease} disabled={processing} onChange={v => set({onAMonthToMonthLease: v})} />
				} />
			</Grid>

			<Grid item {...oneThirdWidth} className={styles.column}>
				<FormValueDisplay label={"In Provider Property?"} value={!editMode
					? value.inProviderProperty ?? "-"
					: <YesNoButton value={editValue.inProviderProperty} disabled={processing} onChange={v => set({inProviderProperty: v})}/>
				}/>
			</Grid>
		</Grid>
	);
};

export {
	HousingDetails
};