// project import
import pages from './pages';
import administration from './administration';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [pages, administration]
};

export default menuItems;
