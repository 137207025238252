import {createErrorState, IErrorState} from "./common";
import {AnnouncementStatus, AnnouncementType} from "./enums";
import {getDateFrom} from "./dates";

interface IAnnouncement {
    id: string;
    title: string;
    type: AnnouncementType;
    startDate: Date;
    endDate: Date;
    message: string;
    status: AnnouncementStatus;
}

class Announcement implements IAnnouncement, IErrorState<Partial<IAnnouncement>> {
    readonly id: string;
    readonly title: string;
    readonly type: AnnouncementType;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly message: string;
    readonly status: AnnouncementStatus;
    private readonly validated: boolean;

    constructor(from?: Partial<IAnnouncement>) {
        this.id = from?.id ?? crypto.randomUUID();
        this.title = from?.title ?? "";
        this.type = from?.type ?? AnnouncementType.Info;
        this.startDate = getDateFrom(from?.startDate, new Date());
        this.endDate = getDateFrom(from?.endDate, new Date());
        this.message = from?.message ?? "";
        this.status = from?.status ?? AnnouncementStatus.Active;
        this.validated = (from as Announcement)?.validated ?? false;
    }

    get errorState() {
        return this.validated ? createErrorState<Announcement>({
            title: !this.title ? "Title is required." : "",
            endDate: this.startDate > this.endDate ? "End date must come after start date." : "",
            message: !this.message ? "Announcement content is required." : ""
        }) : {};
    }

    validate() {
        return new Announcement({...this, validated: true});
    }
}

export type { IAnnouncement };
export {Announcement};