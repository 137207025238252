import React, {ReactNode} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";

const DateInput: (props: { value?: Date, label?: string, error?: string, disabled?: boolean, onChange: (value: Date | undefined) => void }) => ReactNode = ({value, label, error, disabled, onChange}) => {
	dayjs.extend(utc);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker value={dayjs(value)}
						label={label}
						inputFormat={"YYYY-MM-DD"}
			            onChange={v => onChange(dayjs.utc(v?.toString()).toDate())} 
			            renderInput={p => <TextField {...p} disabled={disabled} error={!!error} helperText={error} variant={"standard"} />} 
			/>
		</LocalizationProvider>
	);
};

export {
	DateInput
};