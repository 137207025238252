import {RateRule} from "./rates";
import {RateStatus} from "../enums";
import {getDateFrom} from "library";

type ShelterRates = {
    singleAdult?: number;
    multiAdult?: number;
    extraChildAmountSingle?: number;
    extraChildAmountMulti?: number;
}

class CoreShelterRateRule extends RateRule {
    readonly name: string;
    readonly effectiveStart: Date;
    readonly effectiveEnd: Date;
    readonly childCount?: number;
    readonly privateHousingAmounts: ShelterRates;
    readonly socialHousingAmounts: ShelterRates;
    readonly status: RateStatus;
    readonly notes: string;

    constructor(from?: Partial<CoreShelterRateRule>) {
        super("CORE SHELTER", from?.id);

        this.name = from?.name ?? "";
        this.effectiveStart = getDateFrom(from?.effectiveStart);
        this.effectiveEnd = getDateFrom(from?.effectiveEnd);
        this.childCount = from?.childCount;
        this.privateHousingAmounts = {...from?.privateHousingAmounts};
        this.socialHousingAmounts = {...from?.socialHousingAmounts};
        this.status = from?.status ?? RateStatus.Inactive;
        this.notes = from?.notes ?? "";
    }
}

export {CoreShelterRateRule};
export type {ShelterRates};