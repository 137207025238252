class DeactivationReason {
    public readonly id?: string;
    public readonly name: string;

    constructor(from?: Partial<DeactivationReason>) {
        this.id = from?.id;
        this.name = from?.name ?? "";
    }
}

export {
    DeactivationReason
};