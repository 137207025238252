import {PhoneNumberType} from "./enums";

type Email = {address?: string};

class Phone {
	public readonly number?: string;
	public readonly type?: PhoneNumberType;

	constructor(from?: Phone) {
		this.number = from?.number;
		this.type = from?.type;
	}
}

class Address {
	[key: string]: any;

	public readonly streetNumber?: string;
	public readonly streetName?: string;
	public readonly unit?: string;
	public readonly poBox?: string;
	public readonly city?: string;
	public readonly province?: string;
	public readonly country?: string;
	public readonly postalCode?: string;

	constructor(from?: Partial<Address>) {
		Object.entries(from ?? {}).forEach(([k, v]) => this[k] = v);
	}

	static create(value: any): Address | undefined  {
		return Object.keys(new Address()).some(k => !!value[k]) ? new Address(value) : undefined;
	}

	toString() {
		const street = this.unit || this.streetNumber || this.streetName 
			? `${this.unit ?? ""} ${this.streetNumber ?? ""} ${this.streetName ?? ""}`.trim() 
			: this.poBox;
		return `${street ?? ""}${street && this.city ? ", " : ""}${this.city ?? ""}${this.province && (street || this.city) ? ", " : ""}${this.province ?? ""} ${this.postalCode}`.trim();
	}

	equivalentTo(value: Address | undefined) {
		if (!value) return false;

		return value.streetNumber === this.streetNumber &&
			value.unit === this.unit &&
			value.streetName === this.streetName &&
			value.poBox === this.poBox &&
			value.city === this.city &&
			value.province === this.province &&
			value.postalCode === this.postalCode &&
			value.country === this.country;
	}
}

interface IPageData {
	currentPage: number;
	totalPages: number;
	pageSize: number;
	totalCount: number;
}

interface IPageList<T> {
	metaData: IPageData;
	items: T[];
}

type ProgramApproval = { startDate: Date, name: string, amount: number, endDate: Date, isActive?: boolean };

type PropertyChange = {
	propertyName: string,
	change: { oldVersion: object, newVersion: object }
}

export type {Email, IPageList, ProgramApproval, PropertyChange};
export {Phone, Address};