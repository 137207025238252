import styles from "styles/gridTable.module.scss";

import {FC, useCallback, useEffect} from "react";
import {Button, Grid} from "@mui/material";
import {useDispatch, useSelector} from "store";
import {getScreeningResults, setScreeningFilter, setScreeningPage, setScreeningSort} from "store/slices/screening";
import {DataGrid, GridActionsColDef, GridColDef, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";
import {CitizenStatus, ScreeningResult, YesNo, defaultFilterOption} from "library";
import {useNavigate} from "react-router-dom";
import {TableToolbar} from "components";

class ScreeningRow {
	public static Columns: Partial<Record<keyof ScreeningRow, GridColDef>> = {
		displayName: {field: "displayName", headerName: "Name", flex: 2, headerClassName: styles.columnHeader},
		dateOfBirth: {field: "dateOfBirth", headerName: "Date of Birth", width: 120, headerClassName: styles.columnHeader, type: "date"},
		householdIncome: {
			field: "householdIncome",
			headerName: "Household Income",
			flex: 1,
			headerClassName: styles.columnHeader,
			cellClassName: styles.bold,
			align: "right",
			valueFormatter: params => new Intl.NumberFormat("en-CA", {style: "currency", currency: "CAD"}).format(params.value)
		},
		citizenship: {field: "citizenship", headerName: "Citizenship", width: 150, headerClassName: styles.columnHeader},
		signedLease: {
			field: "signedLease",
			headerName: "Has Lease",
			width: 150,
			headerClassName: styles.columnHeader,
			type: "boolean",
			valueGetter: params => params.value === YesNo.Yes,
			cellClassName: params =>  params.value ? styles.success : styles.failure
		},
		approved: {
			field: "approved",
			headerName: "Result",
			width: 140,
			headerClassName: styles.columnHeader,
			valueGetter: params => params.value ? "Screened In" : "Screened Out",
			cellClassName: params => `${params.row.approved ? styles.success : styles.failure} ${styles.bold}`
		}
	};

	constructor(readonly id: string, readonly displayName: string, readonly dateOfBirth: Date, readonly householdIncome: number, readonly citizenship: CitizenStatus, readonly signedLease: YesNo, readonly approved: boolean) {
	}
	
	static From(screen: ScreeningResult): ScreeningRow {
		return new ScreeningRow(
			screen.id,
			screen.displayName,
			screen.dateOfBirth,
			screen.householdIncome,
			screen.citizenship,
			screen.signedLease,
			screen.approved
		);
	}
}

const ScreeningList: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {loading, items, count, page, pageSize, sort} = useSelector(state => state.screening);

	useEffect(() => {
		dispatch(getScreeningResults({...defaultFilterOption, sort: initialTableState.sorting?.sortModel?.shift()}));
		// eslint-disable-next-line 
	}, []);

	const onPage = useCallback((page: number) => {
		dispatch(setScreeningPage(page));
	}, [dispatch]);

	const onSort = useCallback((model: GridSortModel) => {
		dispatch(setScreeningSort(model.shift()));
	}, [dispatch]);

	const onFilter = useCallback((model: GridFilterModel) => {
		dispatch(setScreeningFilter({items: model.items}));
	}, [dispatch]);

	const viewRowItem = (id: string) => navigate(`${id}`, {relative: "path"});

	const columns: (GridColDef | GridActionsColDef)[] = [
		...Object.values(ScreeningRow.Columns),
		{
			field: "actions",
			type: "actions",
			sortable: false,
			width: 100,
			headerClassName: styles.columnHeader,
			getActions: params => [<Button variant={"text"} onClick={() => viewRowItem(`${params.row.id}`)}>View</Button>]
		}
	];

	const initialTableState: GridInitialStateCommunity = {
		sorting: {
			sortModel: [{field: "householdIncome", sort: "asc"}]
		},
		pagination: {page, pageSize}
	}

	return (
		<Grid container columnSpacing={4}>
			<Grid item width={"100%"} className={styles.content}>
				<DataGrid autoHeight
				          initialState={initialTableState}
				          columns={columns}
						  rows={items.map(ScreeningRow.From)}
						  rowsPerPageOptions={[pageSize ?? 15]}
						  rowCount={count}
				          getRowId={row => row.id}
				          getRowClassName={() => styles.row}
				          loading={loading}
						  paginationMode={"server"} onPageChange={onPage}
						  sortingMode={"server"} onSortModelChange={onSort} sortModel={sort ? [sort] : []}
						  filterMode={"server"} onFilterModelChange={onFilter}
						  components={{Toolbar: TableToolbar}}
						  onRowDoubleClick={row => viewRowItem(`${row.id}`)}
				/>
			</Grid>
		</Grid>
	);
};

export {
	ScreeningList
};