import styles from "styles/gridTable.module.scss";

import {ReactNode, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "store";
import {DataGrid, GridColDef, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {Grid} from "@mui/material";
import {TableToolbar} from "components";
import {getDisbursements, initialFilter, setDisbursementsFilter, setDisbursementsPage, setDisbursementsSort} from "store/slices/disbursements";
import {DisbursementRow} from "./Disbursement.Row";

const DisbursementsList: (props: {}) => ReactNode = () => {
    const dispatch = useDispatch();
    const {loading, items: disbursements, count, page, pageSize, sort} = useSelector(state => state.disbursements);

    useEffect(() => {
        dispatch(getDisbursements({pageSize: 15, page: 0, filter: initialFilter}));
    }, [dispatch]);

    const onPage = useCallback((page: number) => {
        dispatch(setDisbursementsPage(page));
    }, [dispatch]);

    const onSort = useCallback((model: GridSortModel) => {
        dispatch(setDisbursementsSort(model.shift()));
    }, [dispatch]);

    const onFilter = useCallback((model: GridFilterModel) => {
        dispatch(setDisbursementsFilter({items: model.items}))
    }, [dispatch]);

    const columns: GridColDef[] = [
        ...Object.values(DisbursementRow.Columns),
    ];

    return (
        <Grid container columnSpacing={4}>
            <Grid item width={"100%"}>
                <DataGrid autoHeight
                          initialState={{pagination: {pageSize, page}}}
                          columns={columns}
                          rowCount={count}
                          rows={disbursements.map(DisbursementRow.From)}
                          loading={loading}
                          getRowClassName={() => styles.row}
                          paginationMode={"server"} onPageChange={onPage} pageSize={pageSize}
                          sortingMode={"server"} onSortModelChange={onSort} sortModel={sort ? [sort] : []}
                          filterMode={"server"} onFilterModelChange={onFilter}
                          components={{Toolbar: TableToolbar}}
                />
            </Grid>
        </Grid>
    );
};

export {
    DisbursementRow,
    DisbursementsList
}