import {ApplicationType, BedroomCount, YesNo, YesNoSilent} from "./enums";

type ErrorState<T> = {
	[property in keyof Omit<Partial<T>, "errorState">]: string;
}

interface IErrorState<T> {
	errorState?: ErrorState<Partial<T>>
}

const createErrorState = <T extends object>(value: Partial<Record<keyof T, string>>) => Object.fromEntries(Object.entries(value).filter(([,v]) => !!v)) as ErrorState<Partial<T>>;

const isEmpty = (value: object | []) => !value || !Object.entries(value).filter(([k, v]) => k !== "validated").some(([k, v]) => v !== undefined);

const hasError = (value: any) => !!value?.errorState && !isEmpty(value.errorState);

const isNumeric = (value: string | number | undefined) => Number.isFinite(parseInt(`${value}`));
const parseNumber = (value: string) => isNumeric(value) ? Number(value) : undefined;

const sumNumber = (value: number): number => {
	return [...`${value}`]
		.map(c => parseInt(c))
		.reduce((x, y) => x + y);
};

const isValidSin = (value: string): boolean => {
	if (!value?.match(/^[0-9 ]*$/))
		return false;

	const numbersOnly = [...value]
		.map(c => parseInt(c))
		.filter(v => isFinite(v));

	if (numbersOnly.length !== 9)
		return false;

	return !(numbersOnly
		.map((v, i) => {
			const product = v * (i % 2 + 1);
			return product < 10 ? product : sumNumber(product);
		})
		.reduce((x, y) => x+y) % 10);
};

const toYesNo = (value: YesNoSilent) => {
	switch (value) {
		case YesNoSilent.Yes:
			return YesNo.Yes;
		case YesNoSilent.No:
			return YesNo.No;
		default:
			return undefined;
	}
}

const fromYesNo = (value: YesNo) => {
	switch (value) {
		case YesNo.Yes:
			return YesNoSilent.Yes;
		case YesNo.No:
			return YesNoSilent.No;
		default:
			return YesNoSilent.PreferNotToSay;
	}
}

const firstOfNextMonth = () => {
	const today = new Date();
	const nextMonth = today.getMonth() + 1;
	const year = today.getFullYear() + (nextMonth === 12 ? 1 : 0);

	return new Date(year, nextMonth % 12, 1);
};

const spliceArray = (currentValue: any[], value: any, index: number) => {
	const newValue = [...currentValue];
	newValue.splice(index < 0 ? currentValue.length : index, 1, value);
	return newValue;
}

const openFileBlob = (fileBlob: Blob) => window.open(URL.createObjectURL(fileBlob));

const downloadFileBlob = (fileBlob: Blob, fileName: string) => {
	//There's gotta be a better way then this.  Look into this further
	const downloadLink = document.createElement("a");
	downloadLink.href = URL.createObjectURL(fileBlob);;
	downloadLink.download = fileName;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
}

const defaultFilterOption = { page: 0, filter: { items: [] } };

const removeLineBreaks = (stringValue: string) => stringValue?.replace(/\r?\n|\r/g, "{{nl}}")

const distinctBy = <T extends object>(arr: T[], key: keyof T) => [...new Map(arr?.map(i => ([i[key], i]))).values()];

type AppContent = {
	listHeading: string;
	heading: string;
	applicant: string;
	programs: string;
	path: string;
};

const ApplicationContent: Record<ApplicationType, AppContent> = {
	[ApplicationType.Standard]: {
		listHeading: "Applications",
		heading: "Application",
		applicant: "Applicant",
		programs: "Applying for",
		path: "applications"
	},
	[ApplicationType.AnnualReview]: {
		listHeading: "Annual Reviews",
		heading: "Annual Review",
		applicant: "Recipient",
		programs: "Annual Review for",
		path: "annual-reviews"
	},
	[ApplicationType.InterimReview]: {
		listHeading: "Interim Reviews",
		heading: "Interim Review",
		applicant: "Recipient",
		programs: "Interim Review for",
		path: "interim-reviews"
	}
};

const eligibleBedrooms = Object.values(BedroomCount).filter(k => k !== BedroomCount.NotSpecified);

export {
	isNumeric,
	parseNumber,
	isValidSin,
	createErrorState,
	isEmpty,
	hasError,
	toYesNo,
	fromYesNo,
	firstOfNextMonth,
	spliceArray,
	openFileBlob,
	downloadFileBlob,
	removeLineBreaks,
	distinctBy,
	defaultFilterOption,
	ApplicationContent,
	eligibleBedrooms
};
export type {
	ErrorState, IErrorState
};