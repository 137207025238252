import styles from "styles/address.module.scss";

import {Address} from "library";
import {FC} from "react";

const AddressDisplay: FC<{value: Address | undefined, singleLine?: boolean}> = ({value, singleLine}) => {
	if (!value) 
		return <></>;
	
	if (singleLine)
	{
		const addressDisplay = new Address(value).toString();
		return <span title={addressDisplay}>{addressDisplay}</span>;
	}
		
	return (
		<div>
			<div className={styles.addressLine1}>
				{value.unit ? `${value.unit}${value.streetName || value.streetName ? " - " : ""} ` : ""}{value.streetNumber} {value.streetName}
			</div>
			<div className={styles.cityLine}>
				{value.city ? `${value.city}${value.province ? ", " : ""} ` : ""}{value.province}
			</div>
			<div className={styles.countryLine}>
				{value.country} {value.postalCode}
			</div>
		</div>
	)
}

export {
	AddressDisplay
};