class Identity {
	public readonly id?: string;
	public readonly name?: string;
	
	constructor(from?: Partial<Identity>) {
		this.id = from?.id;
		this.name = from?.name;
	}
}

export {
	Identity
};