class DisbursementAmount {
    readonly amount: number;
    readonly calculated: boolean;

    constructor(from?: Partial<DisbursementAmount> | number) {
        this.amount = typeof (from) === "number" ? from : from?.amount ?? 0;
        this.calculated = (from as Partial<DisbursementAmount>)?.calculated ?? true;
    }
}

class DisbursementAmounts {
    [key: string]: DisbursementAmount;

    constructor(from?: Partial<DisbursementAmounts>) {
        Object.entries(from ?? {}).forEach(([k, v]) => this[k] = new DisbursementAmount(v));
    }
}

export {
    DisbursementAmount,
    DisbursementAmounts
};