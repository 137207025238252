import styles from "styles/gridTable.module.scss";

import {Button, Grid} from "@mui/material";
import {DataGrid, GridActionsColDef, GridCallbackDetails, GridColDef, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";
import {TableToolbar} from "components/TableToolbar";
import {ApplicationContent, ApplicationStatus, ApplicationType, fullWidth} from "library";
import {FC, useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "store";
import {getApplications, myApplications, setApplicationFilter, setApplicationPage, setApplicationSort} from "store/slices/applications";
import {AnnualReviewRow} from "./AnnualReview/AnnualReviewRow";
import {ApplicationRow} from "./ApplicationRow";
import {InterimReviewRow} from "./InterimReview";

const ApplicationsList: FC<{ type: ApplicationType, status?: ApplicationStatus, mine?: boolean, key: string }> = ({type, status, mine, key}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {loading, items: applications, count, page, pageSize, sort} = useSelector(state => state.applications);
	
	const staticFilter = useMemo(() => ({
		items: [
			{columnField: "type", value: type, operatorValue: "equals"},
			...(!!status ? [{columnField: ApplicationRow.Columns.status.field, value: status, operatorValue: "equals"}] : []),
			...(mine ? [{columnField: myApplications, value: mine, operatorValue: "equals"}] : [])
		]
	}), [type, status, mine]);

	useEffect(() => {
		dispatch(getApplications({
			filter: staticFilter,
			sort: undefined,
			page: 0,
			pageSize: 15
		}));
		// eslint-disable-next-line 
	}, [type, status, mine]);

	const rows = useMemo(() => {
		switch (type) {
			case ApplicationType.Standard:
				return applications.map(ApplicationRow.From);
			case ApplicationType.AnnualReview:
				return applications.map(AnnualReviewRow.From);
			case ApplicationType.InterimReview:
				return applications.map(InterimReviewRow.From);
		}
	}, [applications, type]);

	const rowCount = useMemo(() => applications.every(a => a.type === type) ? count : 0, [applications, count, type]);

	const onPage = useCallback((newPage: number) => {
		dispatch(setApplicationPage(newPage));
	}, [dispatch]);

	const onSort = useCallback((model: GridSortModel) => {
		dispatch(setApplicationSort(model.shift()));
	}, [dispatch]);

	const onFilter = useCallback((model: GridFilterModel, details: GridCallbackDetails) => {
		dispatch(setApplicationFilter({items: [...staticFilter.items, ...model.items]}))
	}, [dispatch, staticFilter]);

	const viewRowItem = (id: string) => navigate(`/pages/${ApplicationContent[type].path}/${id}`);

	const applicationTypeColumns: Record<ApplicationType, Record<string, GridColDef>> = {
		[ApplicationType.Standard]: ApplicationRow.Columns,
		[ApplicationType.AnnualReview]: AnnualReviewRow.Columns,
		[ApplicationType.InterimReview]: InterimReviewRow.Columns,
	};

	const columns: GridColDef[] = [
		...Object.values(applicationTypeColumns[type]),
		{
			field: "actions", type: "actions", width: 200, align: "right", sortable: false, headerClassName: styles.columnHeader, getActions: params => [
				<Button onClick={() => viewRowItem(`${params.id}`)}>View</Button>
			]
		} as GridActionsColDef<ApplicationRow | AnnualReviewRow>
	];

	const initialTableState: GridInitialStateCommunity = {
		columns: {
			columnVisibilityModel: {
				id: false,
				dob: false,
				email: false,
				phone: false
			}
		},
		pagination: {page: 0, pageSize}
	};

	return (
		<Grid container columnSpacing={4}>
			<Grid item {...fullWidth}>
				<DataGrid autoHeight
						  key={key}
						  initialState={initialTableState}
						  columns={columns}
						  rows={rows.filter(r => !!r)} rowCount={rowCount}
						  loading={loading}
						  getRowClassName={() => styles.row}
						  paginationMode={"server"} onPageChange={onPage} page={page}
						  sortingMode={"server"} onSortModelChange={onSort} sortModel={sort ? [sort] : []}
						  filterMode={"server"} onFilterModelChange={onFilter} 
						  components={{Toolbar: TableToolbar}}
						  onRowDoubleClick={row => viewRowItem(`${row.id}`)}
				/>
			</Grid>
		</Grid>
	);
};

export {
	ApplicationsList
};
