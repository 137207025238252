import {IPerson, Person} from "./applicantInformation";
import {IncomeType} from "../incomeTypes";
import {isNumeric} from "../common";

interface IHousehold {
	adults: IPerson[],
	dependants: IPerson[]
}

class Household implements IHousehold {
	readonly adults: Person[];
	readonly dependants: Person[];
	readonly dependants18OrYounger: Person[];

	constructor(from?: Partial<IHousehold>, incomeTypes?: IncomeType[]) {
		const members = !!from && ("members" in from) ? from.members as IPerson[] : undefined;

		this.adults = (members?.filter(m => !m.isDependant) ?? from?.adults ?? []).map(a => new Person(a, incomeTypes));
		this.dependants = (members?.filter(m => m.isDependant) ?? from?.dependants ?? []).map(a => new Person(a, incomeTypes));
		this.dependants18OrYounger = this.dependants.filter(d => isNumeric(d.age) && d.age! <= 18);
	}
	
	get count() {
		return this.adults.length + this.dependants.length + 1; //The +1 is to include the applicant themselves
	}

	get incomeTotal() {
		return this.incomeEligiblePersons.reduce((a, c) => a + (c.income?.totalAnnual ?? 0), 0);
	}

	get incomeEligiblePersons() {
		return this.adults.filter(a => a.isIncomeEligible);
	}

	toJSON() {
		return {members: [...this.adults, ...this.dependants]};
	}
}

export type {IHousehold};
export {Household};