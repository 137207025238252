import {createSlice} from "@reduxjs/toolkit";
import {RateRule, Rates} from "library/rates";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "../../api/agent";

const loadRates = createAppAsyncThunk("rates/load", async (_: void, {getState}) => {
    const {rates: {loading, rates}} = getState();
    if (!loading) return rates;

    return agent.Rates.list();
});

const saveRate = createAppAsyncThunk("rates/save", async (rate: RateRule) => {
    return agent.Rates.save(rate);
});

const initialState = {
    loading: true,
    rates: new Rates()
};

const rates = createSlice({
    initialState,
    name: "rates",
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadRates.pending, (state) => ({...state, loading: true}));
        builder.addCase(loadRates.fulfilled, (state, {payload}) => ({rates: payload, loading: false}));
        builder.addCase(saveRate.pending, (state) => ({...state, loading: true}));
        builder.addCase(saveRate.fulfilled, (state, {payload}) => ({...state, ...payload, loading: false}));
    }
});

export default rates.reducer;
export {loadRates, saveRate};