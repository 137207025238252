export function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function currencyFormat(amount: number) {
  return '$' + (amount / 100).toFixed(2);
}

export function formatDate(date?: Date | string): string {
  if (typeof date === "undefined") {
    return "";
  }

  const dateToFormat = (typeof date === "string") ? new Date(date) : date;

  return dateToFormat.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }).replace(/(\d{1,2})(st|nd|rd|th)/, "$1");
}
