import {ProgramBudgetStatus} from "./enums";

class ProgramBudget {
  readonly id?: string;
  readonly program: string;
  readonly year: number;
  readonly effectiveStart: Date;
  readonly effectiveEnd: Date;
  readonly amountIncurredExternally: number;
  readonly budget: number;
  readonly status: ProgramBudgetStatus;
  readonly notes?: string;

  constructor(from?: Partial<ProgramBudget>) {
    this.id = from?.id ?? window.crypto.randomUUID();
    this.program = from?.program ?? "";
    this.year = from?.year ?? new Date().getFullYear();
    this.effectiveStart = from?.effectiveStart
      ? new Date(from.effectiveStart)
      : new Date();
    this.effectiveEnd = from?.effectiveEnd
      ? new Date(from.effectiveEnd)
      : new Date();
    this.amountIncurredExternally = Math.round((from?.amountIncurredExternally ?? 0) * 100) / 100;
    this.budget = from?.budget ?? 0;
    this.status = from?.status ?? ProgramBudgetStatus.Inactive;
    this.notes = from?.notes;
  }
}

export { ProgramBudget };
