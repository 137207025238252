import dayjs, {Dayjs} from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const getDateFrom = (from: Date | Dayjs | undefined, defaultValue = new Date()) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("America/Edmonton");

    return from instanceof Date
        ? from
        : from ? parseDate(from.toString()) : defaultValue;
};

const parseDate = (value: string) => {
    const [century, year, month, day] = value.match(/\d\d/g) ?? [];
    return new Date(Number(`${century}${year}`), Number(month) - 1, day ? Number(day) : 1);
};

const isLeapYear = (year: number) => (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));

const getDaysInMonth = (year: number, month: number) => [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];

const addMonths = (date: Date, months: number) => {
    if (!isValidDate(date)) return undefined;

    const asDate = new Date(date);
    const newMonth = new Date(asDate.setMonth(asDate.getMonth() + months, 1));

    return new Date(newMonth.setDate(Math.min(asDate.getDate(), getDaysInMonth(newMonth.getFullYear(), newMonth.getMonth()))));
};

const addDays = (date: Date, days: number) => {
    if (!isValidDate(date)) return undefined;

    const asDate = new Date(date);

    return new Date(asDate.getTime() + (days * 24 * 60 * 60 * 1000));
};

const isValidDate = (date: Date | Dayjs | undefined): boolean => !date ? false : !isNaN(new Date(date?.toString()).getTime());

const daysDifference = (d1: Date, d2: Date) => {
    const diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
};

export {getDateFrom, parseDate, addMonths, isValidDate, daysDifference, addDays};