import styles from "styles/client.module.scss";
import gridStyles from "styles/gridTable.module.scss";

import React, {useEffect, useMemo, useState} from "react";
import {Application, ApplicationContent, ApplicationType, DateDisplay, getDateFrom} from "library";
import {Button, Typography} from "@mui/material";
import {DataGrid, GridActionsColDef, GridColDef} from "@mui/x-data-grid";
import {useDispatch, useSelector} from "../../store";
import {generateAnnualReview, generateInterimReview, getApplications} from "../../store/slices";
import {useNavigate} from "react-router-dom";

class ApplicationRow {
    public static Columns: Record<string, GridColDef> = {
        id: {field: "id", headerName: "ID", flex: 1, headerClassName: styles.columnHeader},
        programs: {field: "programs", headerName: "Applying For", flex: 1, headerClassName: styles.columnHeader},
        score: {field: "score", headerName: "Score", flex: 1, headerClassName: styles.columnHeader},
        submitted: {field: "submitted", headerName: "Submitted", flex: 1, headerClassName: styles.columnHeader, valueGetter: v => DateDisplay.Long(v.value)},
        status: {field: "status", headerName: "Status", flex: 1, headerClassName: styles.columnHeader}
    };

    constructor(readonly id: string, readonly programs: string[], readonly score: number, readonly submitted: Date, readonly status: string) {
    }

    static From(value: Application): ApplicationRow {
        return new ApplicationRow(
            value.code,
            value.programs,
            value.pointScore.total,
            value.createdOn,
            `${value.status}${value.approvedProgram ? ` - ${value.approvedProgram.name}` : ""}`
        );
    }
}

class AnnualReviewRow {
    public static Columns: Record<string, GridColDef> = {
        ...ApplicationRow.Columns,
        dueDate: {field: "dueDate", headerName: "Due Date", flex: 1, headerClassName: styles.columnHeader, valueGetter: v => DateDisplay.Long(v.value)}
    };

    constructor(readonly id: string, readonly programs: string[], readonly score: number, readonly submitted: Date, readonly status: string, readonly dueDate: Date | undefined) {
    }

    static From(value: Application): AnnualReviewRow {
        return {
            ...ApplicationRow.From(value),
            dueDate: getDateFrom(value.dueDate)
        };
    }
}

type ClientApplicationsProps = {
    clientCode: string | undefined;
    applicationType: ApplicationType;
}

const ClientApplications = ({clientCode, applicationType}: ClientApplicationsProps) => {
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const [applications, setApplications] = useState<Application[]>([]);
    const {loading} = useSelector(s => s.applications);
    const {item: client} = useSelector(s => s.client);
    const isAnnualReview = [ApplicationType.AnnualReview, ApplicationType.InterimReview].includes(applicationType);
    
    const columns = useMemo(() => [
        ...Object.values(isAnnualReview ? AnnualReviewRow.Columns : ApplicationRow.Columns),
        {
            field: "actions", type: "actions", flex: 1, headerClassName: styles.columnHeader, getActions: p => [
                <Button onClick={() => navigate(`/pages/${ApplicationContent[applicationType].path}/${p.id}`)}>View {ApplicationContent[applicationType]!.heading}</Button>
            ]
        } as GridActionsColDef
    ], [applicationType, navigate, isAnnualReview]);

    useEffect(() => {
        setApplications([]);
        dispatch(getApplications({
            filter: {
                items: [
                    {columnField: "type", value: applicationType, operatorValue: "equals"},
                    {columnField: "clientCode", value: clientCode, operatorValue: "equals"}
                ]
            },
            pageSize: 0
        }))
            .unwrap()
            .then(({items}) => setApplications(items));
    }, [dispatch, clientCode, applicationType]);
    
    const generate = () => {
        if (!clientCode) return;
        const applicationTypeAction: Record<ApplicationType, any> = {
            [ApplicationType.AnnualReview]: generateAnnualReview(clientCode),
            [ApplicationType.InterimReview]: generateInterimReview(clientCode),
            [ApplicationType.Standard]: undefined,
        };
        applicationTypeAction[applicationType] && dispatch(applicationTypeAction[applicationType]).unwrap().then(
            (application: Application) => !!application?.id && navigate(`/pages/${ApplicationContent[applicationType].path}/${application.id}`)
        );
    };

    const canGenerate = applicationType !== ApplicationType.InterimReview || client?.programs.some(p => p.isActive);
    const fromMap = isAnnualReview ? AnnualReviewRow.From : ApplicationRow.From;

    return (
        <div className={styles.contentBg}>
            {isAnnualReview
                ? <div className={styles.title}>
                    <Typography variant={"h3"}>{ApplicationContent[applicationType].listHeading}</Typography>
                    {canGenerate && <Button variant={"contained"} color={"primary"} disabled={loading} onClick={() => generate()}>GENERATE {ApplicationContent[applicationType].heading.toUpperCase()}</Button>}
                </div>
                : <Typography variant={"h3"}>{ApplicationContent[applicationType].listHeading}</Typography>}
            <DataGrid className={styles.topPadding} autoHeight hideFooterPagination
                      initialState={{sorting: {sortModel: [{field: "period", sort: "asc"}]}}}
                      columns={columns}
                      rows={applications.map(fromMap) ?? []}
                      loading={loading}
                      getRowId={row => row.id}
                      getRowClassName={() => gridStyles.row}
            />
        </div>
    );
};

export {
    ClientApplications
}