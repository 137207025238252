import styles from "styles/application.module.scss";

import React, {Fragment, ReactNode, useEffect, useMemo, useState} from "react";
import {BooleanDisplay, fullWidth, IApplicationContacts, IContact, isEmpty, oneThirdWidth, quarterWidth, twoThirdWidth, YesNo} from "library";
import {Grid, TextField, Typography} from "@mui/material";
import {FormValueDisplay, PhoneInput, YesNoButton} from "components";
import {Actions} from "./Actions";

interface IContactDetailProps {
	value: IApplicationContacts;
	readonly?: boolean;
	onChange?: (value: IApplicationContacts) => Promise<void>;
}

const ContactDetail: (props: IContactDetailProps) => ReactNode = ({value, readonly, onChange}) => {
	const [processing, setProcessing] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editValue, setEditValue] = useState(value);

	useEffect(() => {
		setEditValue(value);
	}, [value]);
	
	const showOtherContacts = useMemo(() => (editMode && editValue.requireFollowup)
		|| (!editMode && (value.requireFollowup || value.contacts.some(c => Object.values(c).some(v => !isEmpty(v))))), [editMode, value, editValue]);
	const set = (value: Partial<IContact>, index: number) => 
		setEditValue({...editValue, contacts: editValue.contacts.map((c, i) => i === index ? {...c, ...value} : c)});

	const onCancel = () => {
		setEditValue(value);
		setEditMode(false);
	};

	const onSave = async () => {
		if (!onChange) return;
		
		setProcessing(true);
		onChange(editValue)
			.then(() => setEditMode(false))
			.finally(() => setProcessing(false));
	};

	return (
		<Grid container className={styles.contactContainer}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"} className={styles.contactLabel}>
					Other Contact Consent {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onCancel={onCancel} onSave={onSave}/>}
				</Typography>
			</Grid>

			<Grid item {...fullWidth}>
				<Grid item {...oneThirdWidth} className={styles.formDetail}>
					<FormValueDisplay {...oneThirdWidth} label={"Authorized Contact(s)"}
									  value={!editMode
										  ? BooleanDisplay(value.requireFollowup)
										  : <YesNoButton disabled={processing}
														 value={editValue.requireFollowup ? YesNo.Yes : YesNo.No}
														 onChange={v => setEditValue({...editValue, requireFollowup: v === YesNo.Yes})}/>}
					/>
				</Grid>
			</Grid>

			{showOtherContacts && value.contacts.map((c, i) => (
				<Fragment key={i}>
					<Grid item {...quarterWidth} className={styles.formDetail}>
						<FormValueDisplay label={"Name"} value={!editMode ? c?.name : <TextField variant={"standard"} value={editValue.contacts[i]?.name} onChange={v => set({name: v.target.value}, i)} />} />
						<FormValueDisplay label={"Organization"} value={!editMode ? c?.organization : <TextField variant={"standard"} value={editValue.contacts[i]?.organization} onChange={v => set({organization: v.target.value}, i)} />} />
					</Grid>
					<Grid item {...quarterWidth} className={styles.formDetail}>
						<FormValueDisplay label={"Email"} labelWidth={oneThirdWidth} valueWidth={twoThirdWidth} value={!editMode 
							? !!c?.email?.address ? <a href={`mailto: ${c?.email?.address}`}>{c?.email?.address}</a> : ""
							: <TextField variant={"standard"} value={editValue.contacts[i]?.email?.address} fullWidth onChange={v => set({email: {address: v.target.value}}, i)}/>
						} />
						<FormValueDisplay label={"Phone"} labelWidth={oneThirdWidth} valueWidth={twoThirdWidth} value={!editMode 
							? c?.phone?.number
							: <PhoneInput value={editValue.contacts[i]?.phone ?? {}} error={""} onChange={v => set({phone: {...(editValue.contacts[i]?.phone ?? {}), ...v}}, i)} />
						} />
					</Grid>
				</Fragment>
			))}
		</Grid>
	);
};

export {
	ContactDetail
};