import styles from "styles/gridTable.module.scss";

import {GridColDef} from "@mui/x-data-grid";
import {Application, ApplicationStatus, ApplicationType, DateDisplay} from "library";

class AnnualReviewRow {
    public static SimpleColumns: Partial<Record<keyof AnnualReviewRow, GridColDef>> = {
        code: {field: "code", headerName: "Annual Review #", minWidth: 120, headerClassName: styles.columnHeader, sortable: false},
        client: {field: "client", headerName: "Recipient #", minWidth: 100, headerClassName: styles.columnHeader, sortable: false},
        clientName: {field: "clientName", headerName: "Recipient Name", minWidth: 120, flex: 1, headerClassName: styles.columnHeader, sortable: false},
        status: {field: "status", headerName: "Status", minWidth: 100, headerClassName: styles.columnHeader, sortable: false},
        primaryAssignee: {field: "primaryAssignee", headerName: "P. Assignee", minWidth: 100, headerClassName: styles.columnHeader, sortable: false},
        secondaryAssignee: {field: "secondaryAssignee", headerName: "S. Assignee", minWidth: 100, headerClassName: styles.columnHeader, sortable: false},
        dueDate: {field: "dueDate", headerName: "Due Date", minWidth: 120, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader, sortable: false},
    };

    public static Columns: Partial<Record<keyof AnnualReviewRow, GridColDef>> = {
        code: {field: "code", headerName: "Annual Review #", minWidth: 120, headerClassName: styles.columnHeader},
        client: {field: "client", headerName: "Recipient #", minWidth: 120, headerClassName: styles.columnHeader},
        clientName: {field: "clientName", headerName: "Recipient Name", flex: 1, headerClassName: styles.columnHeader},
        status: {field: "status", headerName: "Status", minWidth: 120, headerClassName: styles.columnHeader},
        primaryAssignee: {field: "primaryAssignee", headerName: "P. Assignee", minWidth: 100, headerClassName: styles.columnHeader},
        secondaryAssignee: {field: "secondaryAssignee", headerName: "S. Assignee", minWidth: 100, headerClassName: styles.columnHeader},
        modifiedOn: {field: "modifiedOn", headerName: "Modified On", minWidth: 120, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        dueDate: {field: "dueDate", headerName: "Due Date", minWidth: 120, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        submittedDate: {
            field: "submittedDate",
            headerName: "Date Submitted",
            minWidth: 200,
            headerClassName: styles.columnHeader,
            valueGetter: params => params.row.status === ApplicationStatus.Submitted ? DateDisplay.Long(params.value) : ""
        }
    };

    constructor(readonly id: string,
                readonly type: ApplicationType,
                readonly code: string,
                readonly client: string,
                readonly clientName: string,
                readonly status: ApplicationStatus,
                readonly primaryAssignee: string,
                readonly secondaryAssignee: string,
                readonly modifiedOn: Date,
                readonly dueDate?: Date,
                readonly submittedDate?: Date) {
    }

    static From(application: Application): AnnualReviewRow | undefined {
        return application.type === ApplicationType.AnnualReview
            ? new AnnualReviewRow(
                application.id,
                application.type,
                application.code,
                application.clientCode!,
                application.applicant.displayName,
                application.status,
                application.assignments.primary?.name ?? "",
                application.assignments.secondary?.name ?? "",
                application.modifiedOn,
                application.dueDate,
                application.lastStatusUpdateOn)
            : undefined;
    }
}

export {AnnualReviewRow};