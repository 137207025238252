import {lazy} from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import {Forbidden} from "../views/maintenance/Forbidden";

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/maintenance/Error')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/maintenance/UnderConstruction')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '*',
    element: <MinimalLayout />,
    children: [
        {
            path: 'pages/error',
            element: <MaintenanceError />
        },
        {
            path: 'pages/under-construction',
            element: <MaintenanceUnderConstruction />
        },
        {
            path: "pages/forbidden",
            element: <Forbidden/>
        }
    ]
};

export default AuthenticationRoutes;