import {createAppAsyncThunk} from "./asyncThunk";
import agent, {FilterOptions} from "api/agent";
import {DeactivationReason, spliceArray} from "library";
import {GridSortItem} from "@mui/x-data-grid";
import {createSlice} from "@reduxjs/toolkit";

const getDeactivationReasons = createAppAsyncThunk("deactivationReasons/load", async () => {
    return await agent.DeactivationReasons.list();
});

const saveDeactivationReason = createAppAsyncThunk("deactivationReasons/save", async (reason: DeactivationReason) => {
    return await agent.DeactivationReasons.save(reason);
});

const deleteDeactivationReason = createAppAsyncThunk("deactivationReasons/delete", async (reasonId: string, {getState}) => {
    const {deactivationReasons: {items: reasons}} = getState();

    await agent.DeactivationReasons.delete(reasonId);
    return reasons.filter(r => r.id !== reasonId);
});

const initialState: FilterOptions & { loading: boolean, items: DeactivationReason[], count: number } = {
    loading: true,
    sort: {field: "name", sort: "asc"} as GridSortItem,
    items: [],
    pageSize: 15,
    count: 0
};

const incomeTypes = createSlice({
    name: "incomeTypes",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDeactivationReasons.pending, (state) => ({...state, loading: true, items: []}));
        builder.addCase(getDeactivationReasons.fulfilled, (state, {payload}) => ({...state, loading: false, items: payload}));
        builder.addCase(saveDeactivationReason.pending, (state) => ({...state, loading: true}));
        builder.addCase(saveDeactivationReason.fulfilled, (state, {payload}) => ({
            ...state,
            loading: false,
            items: spliceArray(state.items, payload, state.items.findIndex(v => v.id === payload.id))
        }));
        builder.addCase(deleteDeactivationReason.pending, (state) => ({...state, loading: true}));
        builder.addCase(deleteDeactivationReason.fulfilled, (state, {payload}) => ({...state, loading: false, items: payload}));
    }
});

export default incomeTypes.reducer;

export {getDeactivationReasons, saveDeactivationReason, deleteDeactivationReason};