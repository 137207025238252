import styles from "styles/auditDetails.module.scss";
import React, {ReactNode} from "react";
import {Card, Grid} from "@mui/material";
import {DateDisplay, halfWidthMinusOne, oneThirdWidth} from "library";
import {FormValueDisplay} from "./FormValueDisplay";
import image from "assets/images/ppulus_icon_S.png";

type AuditDetailsProps = {
    value: IAuditDetail;
}

const AuditDetails: (props: AuditDetailsProps) => ReactNode = ({value}) => {
    return (
        <Card className={styles.container}>
            <Grid container>
                <Grid item {...halfWidthMinusOne}>
                    <FormValueDisplay className={styles.value} label={"Created On"} value={DateDisplay.StandardTime(value.createdOn)} labelWidth={oneThirdWidth}/>
                    <FormValueDisplay className={styles.value} label={"Created By"} value={value.createdBy} labelWidth={oneThirdWidth}/>
                </Grid>

                <Grid item {...halfWidthMinusOne}>
                    <FormValueDisplay className={styles.value} label={"Modified On"} value={DateDisplay.StandardTime(value.modifiedOn)} labelWidth={oneThirdWidth}/>
                    <FormValueDisplay className={styles.value} label={"Modified By"} value={value.modifiedBy} labelWidth={oneThirdWidth}/>
                </Grid>

                <Grid item className={styles.logo}>
                    <img src={image} alt={"Ppulus"} height={50} width={50}/>
                </Grid>
            </Grid>
        </Card>
    );
};

export {
    AuditDetails
}