const PpulusRoutes = {
    Dashboard: "/",
    AccountSettings: "/pages/under-construction",
    UserProfile: "/pages/under-construction",
    Applications: "/pages/applications",
    AllApplications: "/pages/applications/all",
    MyApplications: "/pages/applications/my-applications",
    Clients: "/pages/clients",
    MyClients: "/pages/clients/my-clients",
    InterimReviews: "/pages/interim-reviews",
    AnnualReviews: "/pages/annual-reviews",
    Disbursements: "/pages/disbursements",
    Financials: {
        ProgramBudgets: "/pages/financials/program-budgets",
    }
} as const;

export {
    PpulusRoutes
}