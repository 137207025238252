import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import { PpulusRoutes } from 'library';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={PpulusRoutes.Dashboard}>
        <Logo />
    </Link>
);

export default LogoSection;