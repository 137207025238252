import styles from "styles/application.module.scss";

import React, {ReactNode, useCallback, useState} from "react";
import {ApplicantInformation, ApplicationDocuments, ApplicationType, CitizenStatus, DocumentRequest, DocumentTypeMap, EmailTemplate, halfWidth, IDocuments} from "library";
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {PpulusLoader} from "components";
import {IconBrandTelegram, IconCircleX} from "@tabler/icons";
import {EmailPreview} from "components/EmailPreview";

interface IRequestDocumentsProps {
    applicationType: ApplicationType;
    applicant: ApplicantInformation;
    onCancel: () => void;
    onApprove: (value: Partial<IDocuments>, specialMessage: string, sendEmail: boolean) => void;
}

const RequestDocuments: (props: IRequestDocumentsProps) => ReactNode = ({applicationType, applicant, onCancel, onApprove}) => {
    const [selections, setSelections] = useState<ApplicationDocuments>(new ApplicationDocuments({
        rentProof: new DocumentRequest({required: true}),
        eftDetails: new DocumentRequest({required: true}),
        incomeDocuments: new DocumentRequest({required: applicant.income.hasIncome}),
        statusInCanada: new DocumentRequest({required: [CitizenStatus.PermanentResident, CitizenStatus.Immigrant, CitizenStatus.Refugee, CitizenStatus.Indigenous].includes(applicant.citizenship)})
    }));
    const [state, setState] = useState({sendEmail: true, specialMessage: ""});
    const [processing, setProcessing] = useState(false);
    const isForStandard = applicationType === ApplicationType.Standard;
    
    const set = useCallback((value: Partial<IDocuments>) => setSelections(new ApplicationDocuments({...selections, ...value})), [selections, setSelections]);

    const submit = () => {
        setProcessing(true);
        onApprove(selections, state.specialMessage, state.sendEmail);
    } 

    return (
        <Grid container className={styles.modalContainer}>
            <div className={styles.modalText}>
                <Typography variant={"h3"}>Request Supporting Documents</Typography>
                <br/>
                An email will be sent out to <a href={`mailto: ${applicant.email?.address}`}>{applicant.email?.address ?? ""}</a> requesting that the {isForStandard ? "following" : ""} supporting documents be uploaded.
            </div>
            {isForStandard && <Grid container className={styles.modalText}>
                {Object.entries(DocumentTypeMap).map(([k, v]) => (
                    <Grid key={k} item {...halfWidth}>
                        <FormControlLabel control={<Checkbox value={k} onChange={(_, v) => set({[k]: {required: v}})}/>} label={v} checked={(selections[k]).required}/>
                    </Grid>
                ))}
            </Grid>}

            <EmailPreview emailKey={EmailTemplate.RequestDocuments} disabled={processing} onNoteChange={v => setState({...state, specialMessage: v})} onSendEmailChanged={v => setState({...state, sendEmail: v})}/>

            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                {processing 
                    ? <div className={styles.loader}><PpulusLoader /></div>
                    :<Button variant={"contained"} disabled={processing} startIcon={<IconBrandTelegram/>} onClick={submit}>Request Documents</Button>}
            </div>
        </Grid>
    );
};

export {
    RequestDocuments
};