const DateDisplay = {
	Standard: (value: Date | undefined) => value ? new Intl.DateTimeFormat("en-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(new Date(value)) : "",
	StandardTime: (value: Date | undefined) => value ? new Intl.DateTimeFormat("en-CA", {year: "numeric", month: "short", day: "2-digit", hour: "numeric", minute: "2-digit", hour12: true}).format(new Date(value)) : "",
	Long: (value: Date | undefined) => value ? new Intl.DateTimeFormat("en-CA", {year: "numeric", month: "long", day: "numeric"}).format(new Date(value)) : "",
	LongTime: (value: Date | undefined) => value ? new Intl.DateTimeFormat("en-CA", {year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "2-digit"}).format(new Date(value)) : "",
	Period: (value: Date | undefined) => value ? new Intl.DateTimeFormat("en-CA", {year: "numeric", month: "short"}).format(new Date(value)) : "",
	PeriodNumeric: (value: Date | undefined) => value ? new Intl.DateTimeFormat("en-CA", {year: "numeric", month: "2-digit"}).format(new Date(value)) : "",
	Custom: (value: Date | undefined, formatOptions: Intl.DateTimeFormatOptions) => value ? Intl.DateTimeFormat("en-CA", formatOptions).format(new Date(value)) : ""
};

const SinDisplay = (value: string | undefined) => value ? value.replace(/(.{3})(.{3})(.{3})/, "$1 $2 $3") : "";

const CurrencyDisplay = (value: number | undefined) => value !== undefined ? new Intl.NumberFormat("en-CA", {style: "currency", currency: "CAD"}).format(value) : "";

const BooleanDisplay = (value: boolean) => value ? "Yes" : "No";

const FileSizeDisplay = (value: number) => {
	const KILOBYTE = 1024;
	const MEGABYTE = KILOBYTE * KILOBYTE;
	const GIGABYTE = MEGABYTE * KILOBYTE;

	if (value > GIGABYTE)
		return `${(value / GIGABYTE).toFixed(2)} GB`;

	if (value > MEGABYTE)
		return `${(value / MEGABYTE).toFixed(2)} MB`;

	return `${(value / KILOBYTE).toFixed(2)} KB`;
}

export {
	DateDisplay,
	SinDisplay,
	CurrencyDisplay,
	BooleanDisplay,
	FileSizeDisplay
};