import {Address, CitizenStatus, Gender, IPerson, LivingSituation, RelationToApplicant, YesNo} from "library";

interface IScreeningInformation {
	citizenship: CitizenStatus;
	assetsAboveThreshold: YesNo;
	householdIncome: number;
	livingSituation: LivingSituation;
	signedLease: YesNo;
}

interface IApiScreeningResult extends IAuditDetail {
	id: string;
	approved: boolean;
	basicInformation: IPerson;
	screeningInformation: IScreeningInformation;
}

class ScreeningResult implements IPerson, IScreeningInformation {
	[key: string]: unknown;

	readonly id: string = "";
	readonly applicationCode: string = "";
	readonly displayName: string = "";
	readonly firstName: string = "";
	readonly middleName: string = "";
	readonly lastName: string = "";
	readonly preferredName: string = "";
	readonly dateOfBirth: Date = new Date();
	readonly gender: Gender = Gender.NonBinary;
	readonly address: Address = new Address();
	readonly citizenship: CitizenStatus = CitizenStatus.Other;
	readonly assetsAboveThreshold: YesNo = YesNo.No;
	readonly householdIncome: number = 0;
	readonly livingSituation: LivingSituation = LivingSituation.Temporary;
	readonly signedLease: YesNo = YesNo.Yes;
	readonly approved: boolean = false;
	readonly isDependant: boolean = false;
	readonly relationToApplicant: RelationToApplicant = RelationToApplicant.other;
	readonly createdOn: Date;
	readonly createdBy: string;
	readonly modifiedOn: Date;
	readonly modifiedBy: string;
	readonly isIncomeEligible = true;

	constructor(from: IApiScreeningResult) {
		Object.entries(from).forEach(([k, v]) => this[k] = v);
		Object.entries(from.basicInformation).forEach(([k, v]) => this[k] = v);
		Object.entries(from.screeningInformation).forEach(([k, v]) => this[k] = v);
		this.createdOn = new Date(from?.createdOn ?? new Date());
		this.createdBy = from?.createdBy ?? "";
		this.modifiedOn = new Date(from?.modifiedOn ?? new Date());
		this.modifiedBy = from?.modifiedBy ?? "";
	}
}

export { ScreeningResult };
export type {IScreeningInformation, IApiScreeningResult};