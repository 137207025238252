interface IFileInfo {
    name: string;
    type: string;
    size: number;
    lastModified: Date;
}

class FileInfo implements IFileInfo {
    readonly name: string;
    readonly type: string;
    readonly size: number;
    readonly lastModified: Date;
    readonly file?: File;
    readonly processing: boolean;

    constructor(from?: Partial<FileInfo> | File, processing = false) {
        this.name = from?.name ?? "";
        this.type = from?.type ?? "";
        this.size = from?.size ?? 0;
        this.file = from instanceof File ? from : undefined;
        this.processing = processing;

        this.lastModified = !from?.lastModified
            ? new Date()
            : from.lastModified instanceof Date ? from?.lastModified : new Date(from.lastModified);
    }

    equivalentTo(file: IFileInfo) {
        return this.name === file.name && this.size === file.size;
    }

    get sizeString(): string {
        const byteSize = 1024;
        let byteIndex = 0;
        let value = this.size;

        while (value >= byteSize || -value >= byteSize) {
            value /= byteSize;
            byteIndex++;
        }
        return `${(byteIndex ? value.toFixed(1) + ' ' : value)} ${' KMGTPEZY'[byteIndex]}B`;
    }
}

export {FileInfo};
export type {IFileInfo};