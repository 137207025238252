import styles from "styles/client.module.scss";
import emailStyles from "styles/emailPreview.module.scss";

import {Button, Card, Grid, Modal} from "@mui/material";
import {DataGrid, GridActionsColDef, GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {DateDisplay, SentEmail} from "library";
import {useDispatch, useSelector} from "store";
import {getEmails} from "store/slices";
import {PpulusLoader} from "components";

type ClientEmailsProps = {
	clientCode: string | undefined;
}

const ClientEmails = ({clientCode}: ClientEmailsProps) => {
	const dispatch = useDispatch();
	const [emailPreview, setEmailPreview] = useState<SentEmail>();
	const {loading, items} = useSelector(s => s.clientEmails);

	useEffect(() => {
		if (!clientCode) return;

		dispatch(getEmails(clientCode));
	}, [clientCode, dispatch]);

	const columns: GridColDef[] = [
		{field: "createdOn", headerName: "Sent", width: 180, headerClassName: styles.columnHeader, valueFormatter: v => DateDisplay.StandardTime(v.value)},
		{field: "subject", headerName: "Subject", flex: 1, headerClassName: styles.columnHeader},
		{
			field: "actions", type: "actions", width: 200, align: "right", sortable: false, headerClassName: styles.columnHeader, getActions: params => [
				<Button onClick={() => setEmailPreview(params.row)}>View</Button>
			]
		} as GridActionsColDef<SentEmail>
	];

	if (loading || !clientCode)
		return <PpulusLoader/>;

	return (
		<Card className={`${styles.content} ${styles.formDetail}`}>
			<Grid container>
				<DataGrid autoHeight
						  initialState={{columns: {columnVisibilityModel: {id: false}}, sorting: {sortModel: [{field: "createdOn", sort: "desc"}]}}}
						  columns={columns}
						  paginationMode={"client"} hideFooterPagination
						  sortingMode={"client"}
						  rows={items}
						  getRowId={row => row.id}
						  onRowDoubleClick={row => setEmailPreview(row.row)}
						  components={{NoRowsOverlay: () => <div className={styles.emptyGrid}>None</div>}}
				/>
			</Grid>
			<Modal open={!!emailPreview} onClose={() => setEmailPreview(undefined)}>
				<Grid container className={`${styles.modalContainer} ${emailStyles.enhancedBackground} ${emailStyles.roundedCorners}`}>
					<div>
						<strong>Subject:</strong> {emailPreview?.subject}
					</div>
					<p dangerouslySetInnerHTML={{__html: emailPreview?.message ?? ""}}/>
				</Grid>
			</Modal>
		</Card>
	);
};

export {
	ClientEmails
};