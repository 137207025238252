import styles from "styles/counts.module.scss";

import {CSSProperties, ReactNode, useMemo} from "react";

type DisbursementsTotalProps = {
    value: string;
    label: string;
    backgroundColor?: string;
};

const CountDisplay: (props: DisbursementsTotalProps) => ReactNode = ({value, label, backgroundColor}) => {
    const style = useMemo<CSSProperties>(() => backgroundColor ? {backgroundColor: backgroundColor!} : {}, [backgroundColor]);
    return (
        <div className={styles.totalContainer} style={style}>
            <span className={styles.value}>{value}</span>
            <span className={styles.label}>{label}</span>
        </div>
    );
};

export {
	CountDisplay
}