import {Application} from "library";
import {RabRateCalculation, TrabRateCalculation} from ".";

class RateCalculation {
    static readonly RAB = (application: Application) => <RabRateCalculation application={application} />;
    static readonly TRAB = (application: Application) => <TrabRateCalculation application={application}/>;

    static get(program: string, application: Application) {
        const [, value] = Object.entries(this).find(([k, v]) => k.localeCompare(program, undefined, {sensitivity: "variant"}) === 0) ?? [undefined, () => <>not found</>];
        return value(application);
    }
};

export {
    RateCalculation
}