import styles from "styles/application.module.scss";

import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {Autocomplete, Button, FormControlLabel, Grid, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {ApplicantInformation, Application, CurrencyDisplay, fullWidth, Household, IncomeAssets, IncomeFrequency, NoticeOfAssessmentDetail, oneThirdWidth, Person, spliceArray, StandardIncomeDetail, YesNo, YesNoUnsure} from "library";
import {Actions} from "./Actions";
import {FormValueDisplay} from "../FormValueDisplay";
import {YesNoButton} from "../YesNoButton";
import {dispatch, useSelector} from "store";
import {getIncomeTypes} from "store/slices/incomeTypes";

type IncomeDetailsProps = {
    applicant: ApplicantInformation;
    household: Household;
    readonly?: boolean;
    onChange: (value: Partial<Application>) => Promise<void>;
}

const IncomeDetails: (props: IncomeDetailsProps) => ReactNode = ({applicant, household, readonly = false, onChange}) => {
    return (<>
        <Grid container>
            <div className={styles.householdTitle}>
                <Typography variant={"h3"} className={styles.title}>Income</Typography>
                <IncomeTotal income={applicant.income.totalAnnual + household.incomeTotal}/>
            </div>
        </Grid>

        <Grid container>
            <IncomeDetail member={applicant} readonly={readonly} onChange={income => onChange({applicant: new ApplicantInformation({...applicant, income})})}/>
            {household.adults.map((a, i) =>
                <IncomeDetail key={i} member={a} readonly={readonly} onChange={income => onChange({household: new Household({...household, adults: spliceArray(household.adults, {...a, income}, i)})})}/>
            )}
        </Grid>
    </>);
};

type IncomeTotalProps = {
    income: number;
}

const IncomeTotal: (props: IncomeTotalProps) => ReactNode = ({income}) => {
    return (
        <Stack direction={"row"} spacing={5}>
            <FormControlLabel labelPlacement={"start"} control={<span className={`${styles.bold} ${styles.column}`}>{CurrencyDisplay(income)}</span>} label={"Household Annual Income"}/>
            <FormControlLabel labelPlacement={"start"} control={<span className={`${styles.bold} ${styles.column}`}>{CurrencyDisplay(income / 12)}</span>} label={"Household Monthly Income"}/>
        </Stack>
    );
};

type IncomeDetailProps = {
    member: Person;
    readonly: boolean;
    onChange: (income: IncomeAssets) => Promise<void>;
}

const IncomeDetail: (props: IncomeDetailProps) => ReactNode = ({member, readonly = false, onChange}) => {
    const [editMode, setEditMode] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [editValue, setEditValue] = useState(member.income);

    const onInnerCancel = () => {
        setEditValue(member.income);
        setEditMode(false);
    };

    const onSave = useCallback(async () => {
        if (!onChange) return;

        setProcessing(true);
        onChange(editValue)
            .then(() => setEditMode(false))
            .finally(() => setProcessing(false));
    }, [onChange, editValue]);

    const createNewIncomeRow = () => editValue.noticeOfAssessment === YesNoUnsure.Yes
        ? set({noticeOfAssessmentIncome: [...editValue.noticeOfAssessmentIncome, new NoticeOfAssessmentDetail()]})
        : set({otherIncome: [...editValue.otherIncome, new StandardIncomeDetail()]});
    const set = (value: Partial<IncomeAssets>) => setEditValue(new IncomeAssets({...editValue, ...value}));

    return (
        <div className={`${styles.card} ${styles.fullWidth} ${styles.memberDetailContainer} ${!member.isIncomeEligible ? styles.ineligible : ""}`}>
            <Grid item {...fullWidth} className={styles.row}>
                <Typography variant={"h3"} className={styles.memberLabel}>
                    {member.displayName} {!member.isIncomeEligible && <div className={styles.small}>(EXCLUDED)</div>}
                    {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onCancel={onInnerCancel} onSave={onSave}/>}
                </Typography>
                <IncomeTotal income={editMode ? editValue.totalAnnual : member.income.totalAnnual}/>
            </Grid>

            {editMode
                ? <FormControlLabel label={<Typography variant={"h5"} width={"300px"}>Notice of Assessment?</Typography>} labelPlacement={"start"} className={`${styles.maxContent} ${styles.row}`}
                                    control={<YesNoButton value={editValue.noticeOfAssessment === YesNoUnsure.Yes ? YesNo.Yes : YesNo.No} onChange={v => set({noticeOfAssessment: v === YesNo.Yes ? YesNoUnsure.Yes : YesNoUnsure.No})}/>}/>
                : <Typography variant={"h4"}>
                    {member.income!.noticeOfAssessment === YesNoUnsure.Yes
                        ? member.income.noticeOfAssessmentIncome.length ? "Notice of Assessments" : "No income specified"
                        : member.income.otherIncome.length ? "Other Income" : "No income specified"
                    }
                </Typography>
            }

            {(editMode ? editValue.noticeOfAssessment : member.income!.noticeOfAssessment) === YesNoUnsure.Yes
                ? (editMode ? editValue.noticeOfAssessmentIncome : member.income.noticeOfAssessmentIncome).map((noa, i) =>
                    <NoticeOfAssessment key={i} value={noa} editing={editMode} processing={processing}
                                        onChange={v => set({noticeOfAssessmentIncome: spliceArray(editValue.noticeOfAssessmentIncome, v, i)})}
                                        onDelete={() => set({noticeOfAssessmentIncome: editValue.noticeOfAssessmentIncome.filter((_, index) => index !== i)})}/>
                )
                : (editMode ? editValue.otherIncome : member.income.otherIncome).map((other, i) =>
                    <OtherIncome key={i} value={other} editing={editMode} processing={processing}
                                 onChange={v => set({otherIncome: spliceArray(editValue.otherIncome, v, i)})}
                                 onDelete={() => set({otherIncome: editValue.otherIncome.filter((_, index) => index !== i)})}/>
                )
            }

            {editMode &&
                <div>
                    <Button variant={"text"} onClick={createNewIncomeRow}>Add New</Button>
                </div>
            }
        </div>
    );
};

type NoAProps = {
    editing?: boolean;
    processing?: boolean;
    value: NoticeOfAssessmentDetail;
    onChange: (value: NoticeOfAssessmentDetail) => void;
    onDelete: () => void;
}

const NoticeOfAssessment: (props: NoAProps) => ReactNode = ({value, onChange, onDelete, editing = false, processing = false}) => {
    const [editValue, setEditValue] = useState(value);
    const {items: incomeTypes} = useSelector(s => s.incomeTypes);

    useEffect(() => {
        dispatch(getIncomeTypes());
    }, []);
    
    useEffect(() => {
        setEditValue(value);
    }, [value, editing]);

    const set = (newValue: Partial<NoticeOfAssessmentDetail>) => {
        const updatedValue = new NoticeOfAssessmentDetail({...editValue, ...newValue});
        setEditValue(updatedValue);
        onChange(updatedValue);
    };

    return (
        <Stack direction={"row"}>
            <FormValueDisplay label={"Year"}
                              labelWidth={oneThirdWidth}
                              value={!editing
                                  ? `${value.year}`
                                  : <Select value={editValue.year} disabled={processing} fullWidth onChange={v => set({year: `${v.target.value}`})}>
                                      {[...Array(2)]
                                          .map((_, index) => new Date().getFullYear() - index - 1)
                                          .map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                                  </Select>}
            />

            <FormValueDisplay label={"Income"}
                              labelWidth={oneThirdWidth}
                              value={!editing
                                  ? CurrencyDisplay(value.annualAmount)
                                  : <TextField type={"number"} value={editValue.amount ?? 0} disabled={processing} onChange={v => (set({amount: Number(v.target.value)}))}/>}
            />

            <FormValueDisplay label={"Highest Source of Income"} className={styles.fullWidth}
                              labelWidth={oneThirdWidth}
                              value={!editing
                                  ? value.source?.name
                                  : <Autocomplete value={editValue.source} disabled={processing} fullWidth
                                                  renderInput={p => <TextField {...p} />}
                                                  getOptionLabel={p => p.name}
                                                  options={incomeTypes} onChange={(_, v) => set({source: v ?? value.source})}/>}
            />
            {editing && <Button onClick={onDelete}>Delete</Button>}
        </Stack>
    );
};

type OtherIncomeProps = {
    editing?: boolean;
    processing?: boolean;
    value: StandardIncomeDetail;
    onChange: (value: StandardIncomeDetail) => void;
    onDelete: () => void;
}

const OtherIncome: (props: OtherIncomeProps) => ReactNode = ({value, onChange, onDelete, editing = false, processing = false}) => {
    const [editValue, setEditValue] = useState(value);
    const {items: incomeTypes} = useSelector(s => s.incomeTypes)

    useEffect(() => {
        dispatch(getIncomeTypes());
    }, []);

    useEffect(() => {
        setEditValue(value);
    }, [value, editing]);

    const set = (newValue: Partial<StandardIncomeDetail>) => {
        const updatedValue = new StandardIncomeDetail({...editValue, ...newValue});
        setEditValue(updatedValue);
        onChange(updatedValue);
    };

    return (
        <Stack direction={"row"}>
            <FormValueDisplay label={"Income"}
                              labelWidth={oneThirdWidth}
                              value={!editing
                                  ? CurrencyDisplay(value.amount)
                                  : <TextField type={"number"} value={editValue.amount ?? 0} disabled={processing} onChange={v => (set({amount: Number(v.target.value)}))}/>}
            />

            <FormValueDisplay label={"Frequency"}
                              labelWidth={oneThirdWidth}
                              value={!editing
                                  ? value.frequency
                                  : <Select value={editValue.frequency} fullWidth disabled={processing} onChange={v => set({frequency: v.target.value as IncomeFrequency})}>
                                      {Object.entries(IncomeFrequency)
                                          .map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
                                  </Select>}
            />

            <FormValueDisplay label={"Income Type"} className={styles.fullWidth}
                              value={!editing
                                  ? value.source?.name
                                  : <Autocomplete value={editValue.source} disabled={processing} fullWidth
                                                  renderInput={p => <TextField {...p} />}
                                                  getOptionLabel={p => p.name}
                                                  options={incomeTypes} onChange={(_, v) => set({source: v ?? value.source})}/>}
            />
            <FormValueDisplay className={`${styles.bold} ${styles.error}`} label={""} value={value.source?.excluded ? "EXCLUDED" : ""}/>
            {editing && <Button onClick={onDelete}>Delete</Button>}
        </Stack>
    );
};

export {
    IncomeDetails
}