import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./asyncThunk";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import agent, {FilterOptions} from "api/agent";
import {RootState} from "../index";
import {BankInfo, DateDisplay, downloadFileBlob, isEmpty, Payment, PaymentStatus, Period} from "library";
import {Program} from "./programs";
import {DisbursementRow} from "views";

const getCurrentOptions = (getState: () => RootState) => {
    const {disbursements: {filter, page, pageSize, sort}} = getState();
    return {filter, page, pageSize, sort};
};

const setDisbursementsPage = createAppAsyncThunk("disbursements/setPage", async (page: number, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), page};
    await dispatch(getDisbursements(options));
    return options;
});

const setDisbursementsSort = createAppAsyncThunk("disbursements/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), sort};
    await dispatch(getDisbursements(options));
    return options;
});

const setDisbursementsFilter = createAppAsyncThunk("disbursements/filter", async (filter: GridFilterModel, {dispatch, getState}) => {
    const options = {...getCurrentOptions(getState), filter: isEmpty(filter?.items) ? initialState.filter : filter};
    await dispatch(getDisbursements(options));
    return options;
});

const getDisbursements = createAppAsyncThunk("disbursements/load", async (options: FilterOptions, {getState}) => {
    const listOptions = {...getCurrentOptions(getState), ...options};
    const fromApi = await agent.Payments.list(listOptions);
    return {...listOptions, items: fromApi.payments, count: fromApi.count};
});

const exportPayFile = createAppAsyncThunk("disbursements/export", async ({period, program, values}: { period: Period, program: Program, values: DisbursementRow[] }) => {
    const createHeader = () => Object.keys(DisbursementRow.Columns).map(r => (r as keyof (DisbursementRow)) === "bankDetails" ? ["institution", "transit", "account"].join(",") : r).join(",");
    const createRow = (value: DisbursementRow) => Object.values(value).slice(1).map(r => r instanceof BankInfo ? [r.institution, r.transit, r.account].join(",") : r).join(",");

    const fileBlob = window.URL.createObjectURL(new Blob([[createHeader(), ...values.map(createRow)].join("\r\n")], {type: 'text/csv;charset=utf-8;'}));

    //There's gotta be a better way then this.  Look into this further
    const downloadLink = document.createElement("a");
    downloadLink.href = fileBlob;
    downloadLink.download = `payments-${program.shortName}_${period}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
});

const generatePayFile = createAppAsyncThunk("disbursements/generate", async ({period, program}: { period: Period, program: Program }) => {
    const file = await agent.Payments.generate(period, program.shortName);
    downloadFileBlob(file.fileBlob, file.fileName);
});

const payCheque = createAppAsyncThunk("disbursements/payCheque", async (cheque: Payment, {getState}) => {
    const {disbursements: {items: existing}} = getState();
    const updatedPayment = await agent.Payments.update({...cheque, status: PaymentStatus.Paid});

    return existing.map(e => e.id === updatedPayment.id ? updatedPayment : e);
});

const initialFilter = {
    items: [
        {columnField: "period", operatorValue: "onOrAfter", value: DateDisplay.PeriodNumeric(new Date())}
    ]
};

const initialState: FilterOptions & { loading: boolean, items: Payment[], count: number } = {
    loading: true,
    page: 0,
    pageSize: 0,
    sort: {field: "period", sort: "asc"},
    filter: initialFilter,
    items: [],
    count: 0
};

const disbursements = createSlice({
    name: "disbursements",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDisbursements.pending, (state) => ({...state, loading: true, items: []}));
        builder.addCase(getDisbursements.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
        builder.addCase(setDisbursementsPage.fulfilled, (state, {payload}) => ({...state, ...payload}));
        builder.addCase(setDisbursementsSort.fulfilled, (state, {payload}) => ({...state, ...payload}));
        builder.addCase(setDisbursementsFilter.fulfilled, (state, {payload}) => ({...state, ...payload}));
        builder.addCase(payCheque.pending, (state) => ({...state, loading: true}));
        builder.addCase(payCheque.fulfilled, (state, {payload}) => ({...state, loading: false, items: payload}))
    }
});

export default disbursements.reducer;
export {initialFilter, setDisbursementsSort, setDisbursementsPage, setDisbursementsFilter, getDisbursements, exportPayFile, generatePayFile, payCheque};