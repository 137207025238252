import styles from "styles/client.module.scss";

import {ReactNode} from "react";
import {Note, openFileBlob} from "library";
import {Card, Grid} from "@mui/material";
import {NotesList} from "components";
import {viewOrDownloadClientDocument} from "store/slices";
import {useDispatch} from "store";

type ClientNotesProps = {
    value: Note[];
    onChange: (value: Note[]) => Promise<void>;
}

const ClientNotes: (props: ClientNotesProps) => ReactNode = ({value, onChange}) => {
    const dispatch = useDispatch();

    return (
        <Card className={`${styles.content} ${styles.formDetail}`}>
            <Grid container>
                <NotesList notes={value} onChange={onChange} onDownload={async (fileName) => await dispatch(viewOrDownloadClientDocument({fileName, documentType: "notes"})).unwrap()
                  .then(r => { openFileBlob(r.fileBlob) })}/>
            </Grid>
        </Card>
    );
};

export {
    ClientNotes
}