import {Identity} from "./identity";

interface IAssignments {
	primary?: Identity;
	secondary?: Identity;
}

class Assignments implements IAssignments {
	public readonly primary?: Identity;
	public readonly secondary?: Identity;

	constructor(from?: Partial<IAssignments>) {
		this.primary = from?.primary;
		this.secondary = from?.secondary;
	}
}

export {
	Assignments
};