import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";
import {createSlice} from "@reduxjs/toolkit";
import {Client} from "library";
import {GridLinkOperator} from "@mui/x-data-grid";

const getMatchingClients = createAppAsyncThunk("matchingClients/get", async (_, {getState}) => {
    const {application: {item: application}, matchingClients: {forApplication, items}} = getState();
    if (!application)
        return {forApplication: "", items: []};

    if (forApplication === application.code)
        return {forApplication, items};

    const result = await agent.Clients.list({
        pageSize: 4,
        filter: {
            items: [
                {columnField: "primaryContact.displayName", value: application.applicant.displayName, operatorValue: "contains"},
                {columnField: "primaryContact.email.address", value: application.applicant.email.address, operatorValue: "contains"},
                {columnField: "primaryContact.socialInsuranceNumber", value: application.applicant.socialInsuranceNumber, operatorValue: "equals"}
            ],
            linkOperator: GridLinkOperator.Or,
        }
    });

    return {forApplication, items: result.items.slice(0, 4)};
});

const initialState: { loading: boolean, forApplication?: string, items: Partial<Client>[] } = {
    loading: false,
    items: []
};

const matchingClients = createSlice({
    name: "matchingClients",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getMatchingClients.pending, (state) => ({...state, loading: true}));
        builder.addCase(getMatchingClients.fulfilled, (state, {payload}) => ({loading: false, ...payload}));
    }
});

export default matchingClients.reducer;
export {getMatchingClients};