import styles from "../styles/emailPreview.module.scss";

import {EmailTemplate} from "../library";
import {PpulusLoader} from "./household";
import {useDispatch, useSelector} from "../store";
import {useEffect, useMemo, useState} from "react";
import {EmailAndApplication, getEmail, isEmailAndApplication} from "../store/slices";
import {TextArea} from "./TextArea";
import {Switch} from "@mui/material";

type EmailPreviewProps = {
    emailKey: EmailTemplate | EmailAndApplication;
    disabled: boolean;
    dependencies?: any[];
    onNoteChange: (value: string) => void;
    onSendEmailChanged: (value: boolean) => void;
}

const EmailPreview = ({emailKey, disabled, dependencies, onNoteChange, onSendEmailChanged}: EmailPreviewProps) => {
    const template = useMemo(() => isEmailAndApplication(emailKey) ? emailKey.email : emailKey, [emailKey]);

    const dispatch = useDispatch();
    const {loading: emailLoading, items: {[template]: emailPreview}} = useSelector(s => s.emails);
    const [emailNote, setEmailNote] = useState("");
    const [sendEmail, setSendEmail] = useState(true);

    useEffect(() => {
        const getEmailText = dispatch(getEmail(emailKey));

        return () => getEmailText.abort();
        //eslint-disable-next-line
    }, [dispatch, template, ...(dependencies ?? [])]);

    const updateNoteText = (noteValue: string) => {
        setEmailNote(noteValue);
        onNoteChange(noteValue);
    }

    const updateSendEmail = () => {
        setSendEmail(!sendEmail);
        onSendEmailChanged(!sendEmail);
    }

    const templateStyle = useMemo(() => !sendEmail ? styles.omitted : "", [sendEmail]);

    return (<>
        <div className={`${styles.enhancedBackground} ${styles.roundedCorners}`}>
            <div className={styles.emailHeader}>
                Email Template Preview
                <div className={styles.end}>
                    DO NOT SEND <Switch disabled={disabled} onChange={updateSendEmail}/>
                </div>
            </div>
            <div className={templateStyle}>
                {emailLoading
                    ? <PpulusLoader/>
                    : !emailPreview?.value
                        ? <>Template has not been setup. No email will be sent</>
                        : <>
                            <div>
                                <strong>Subject:</strong> {emailPreview?.value.subject}
                            </div>
                            <p dangerouslySetInnerHTML={{__html: emailPreview?.value.body ?? ""}}/>
                            <TextArea disabled={!sendEmail || disabled} label={"Special Note"} value={emailNote} onChange={updateNoteText} rows={5}/>
                        </>}
            </div>
        </div>
    </>);
};

export {
    EmailPreview
}