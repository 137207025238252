import styles from "styles/disbursements.module.scss";
import countStyles from "styles/counts.module.scss";

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Dialog, DialogActions, DialogTitle, Grid} from "@mui/material";
import {CurrencyDisplay, PaymentStatus, Period} from "library";
import {DisbursementOptionForm, DisbursementOptions} from "./Disbursements.Options";
import {useDispatch, useSelector} from "store";
import {DataGrid, GridActionsColDef, GridColDef} from "@mui/x-data-grid";
import {DisbursementRow} from "./Disbursement.Row";
import {exportPayFile, generatePayFile, getDisbursements, payCheque} from "store/slices/disbursements";
import {PpulusLoader} from "components";
import {useTheme} from "@mui/material/styles";
import {ChequeRow} from "./ChequeRow";
import {CountDisplay} from "components/CountDisplay";

type DisbursementsGenerateProps = {
    chequesOnly?: boolean;
};

const DisbursementsGenerate = ({chequesOnly}: DisbursementsGenerateProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const {defaultProgram} = useSelector(s => s.programs);
    const {loading, items: disbursements, pageSize} = useSelector(s => s.disbursements);
    const [state, setState] = useState<DisbursementOptions>({program: defaultProgram, period: Period.Create(new Date())});
    const [exporting, setExporting] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [dialogOpen, setDialog] = useState(false);
    const [periodPage, setPeriodPage] = useState(0);

    const set = (value: Partial<DisbursementOptions>) => setState(current => ({...current, ...value}));

    useEffect(() => {
        setPeriodPage(0);
        const updateDisbursements = setTimeout(() => {
            dispatch(getDisbursements({
                page: 0, pageSize: 0, filter: {
                    items: [
                        {columnField: "bankDetails.chequePayments", value: `${!!chequesOnly}`, operatorValue: "equals"},
                        {columnField: DisbursementRow.Columns.period.field, value: state.period.toString(), operatorValue: "startsWith"},
                        {columnField: DisbursementRow.Columns.program.field, value: state.program.shortName, operatorValue: "equals"},
                        {columnField: DisbursementRow.Columns.status.field, value: `${PaymentStatus.Upcoming},${PaymentStatus.Failed},${PaymentStatus.OnHold}`, operatorValue: "isAnyOf"}
                    ]
                }
            }));
        }, 500);

        return () => clearTimeout(updateDisbursements);
    }, [state, dispatch, chequesOnly]);

    const rows = useMemo(() => !chequesOnly
        ? disbursements.map(DisbursementRow.From)
        : disbursements.map(ChequeRow.From), [chequesOnly, disbursements]);

    const totals = useMemo(() => ({
        clients: disbursements.length ? new Set(disbursements.filter(d => d.status !== PaymentStatus.OnHold).map(d => d.clientCode)).size : "-",
        organizations: disbursements.length
            ? new Set(disbursements
                .filter(d => d.status !== PaymentStatus.OnHold)
                .filter(d => d.bankDetails.isOrganization)
                .map(d => `${d.bankDetails.isOrganization}-${d.bankDetails.transit}-${d.bankDetails.account}`)).size
            : "-",
        disbursements: disbursements.length ? disbursements.filter(d => d.status !== PaymentStatus.OnHold).length : "-",
        amount: disbursements.filter(d => d.status !== PaymentStatus.OnHold).reduce((a, c) => a + c.amount, 0),
        onHold: disbursements.filter(d => d.status === PaymentStatus.OnHold).reduce((a, c) => a + c.amount, 0)
    }), [disbursements]);

    const exportAsFile = useCallback(() => {
        setExporting(true);
        dispatch(exportPayFile({...state, values: rows.filter(r => r.status !== PaymentStatus.OnHold) as DisbursementRow[]}))
            .unwrap()
            .finally(() => setExporting(false));
    }, [state, dispatch, setExporting, rows]);
    
    const downloadPayFile = useCallback(() => {
        if (disbursements.some(d => [PaymentStatus.Pending].includes(d.status))) {
            setDialog(true);
            return;
        }
                
        setDownloading(true);
        dispatch(generatePayFile(state))
            .unwrap()
            .finally(() => {
                dispatch(getDisbursements({}));
                setDownloading(false)
            });
    }, [state, dispatch, disbursements, setDownloading]);

    const markChequeAsPaid = useCallback((row: ChequeRow) => {
        dispatch(payCheque(row.payment));
    }, [dispatch]);

    const closeDialog = () => setDialog(false);

    const columns: GridColDef[] = useMemo(() => !chequesOnly
        ? Object.values(DisbursementRow.Columns).filter(c => !["period", "cheque", "paymentDateDisplay"].includes(c.field))
        : [
            ...Object.values(ChequeRow.Columns),
            {
                field: "actions", type: "actions", width: 140, align: "right", sortable: false, headerClassName: styles.columnHeader, getActions: params => [
                    ...(params.row.status !== PaymentStatus.Paid ? [<Button onClick={() => markChequeAsPaid(params.row)}>Update to Paid</Button>] : [])
                ]
            } as GridActionsColDef<ChequeRow>
        ], [chequesOnly, markChequeAsPaid]);

    return (
        <Grid container width={"100%"} className={styles.content}>
            <DisbursementOptionForm value={state} onChange={set}/>

            {!chequesOnly &&
            <div className={styles.paymentHeader}>
                Preview

                <div className={styles.right}>
                    <Button color={"secondary"} disabled={exporting} className={styles.button} variant={"contained"} onClick={exportAsFile}>
                        {!exporting ? "Export" : <PpulusLoader noPad/>}
                    </Button>

                    <Button disabled={downloading} className={styles.button} variant={"contained"} onClick={downloadPayFile}>
                        {!downloading ? "Generate Payment File" : <PpulusLoader noPad/>}
                    </Button>
                </div>
            </div>}
            
            <div className={styles.section}>
                <div className={countStyles.totals}>
                    <CountDisplay value={`${totals.clients}`} label={"Recipients"}/>
                    <CountDisplay value={`${totals.organizations}`} label={"Organizations"}/>
                    <CountDisplay value={`${totals.disbursements}`} label={"Disbursements"}/>
                    <CountDisplay value={CurrencyDisplay(totals.amount)} label={"Total Payment"}/>
                    <CountDisplay value={CurrencyDisplay(totals.onHold)} label={"On Hold"} backgroundColor={theme.palette.grey["500"]}/>
                </div>

                <DataGrid autoHeight
                          initialState={{filter: {filterModel: {items: [{columnField: "period", value: state.period, operatorValue: "startsWith"}]}}}}
                          columns={columns}
                          rows={rows}
                          loading={loading}
                          getRowClassName={() => styles.row}
                          pageSize={pageSize || 15} onPageChange={setPeriodPage} page={periodPage}
                          paginationMode={"client"}
                          sortingMode={"client"}
                />
            </div>

            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>{"Payment File generation is not possible while there are still files pending."}</DialogTitle>
                <DialogActions>
                    <Button onClick={closeDialog}>Ok</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export {
    DisbursementsGenerate
}
