interface IScoreValue<T> {
	score: number;
	value: T | undefined;
}

class ScoreValue<T> implements IScoreValue<T> {
	readonly score: number;
	readonly value: T | undefined;
	
	public constructor(from?: Partial<IScoreValue<T>>) {
		this.score = from?.score ?? 0;
		this.value = from?.value;
	}
} 

interface IPointScore {
	numberOfDependants: ScoreValue<number>;
	bedroomCount: ScoreValue<number>;
	rentToIncome: ScoreValue<number>;
	utilities: ScoreValue<number>;
	healthAndSafety: ScoreValue<boolean>;
	personalWellBeing: ScoreValue<boolean>;
	targetPopulations: ScoreValue<string[]>;
	discretionaryPoints: ScoreValue<string>;
	total: number;
}

class PointScore implements IPointScore {
	public readonly numberOfDependants: ScoreValue<number>;
	public readonly bedroomCount: ScoreValue<number>;
	public readonly rentToIncome: ScoreValue<number>;
	public readonly utilities: ScoreValue<number>;
	public readonly healthAndSafety: ScoreValue<boolean>;
	public readonly personalWellBeing: ScoreValue<boolean>;
	public readonly targetPopulations: ScoreValue<string[]>;
	public readonly discretionaryPoints: ScoreValue<string>;
	
	constructor(from?: Partial<IPointScore>) {
		this.numberOfDependants = new ScoreValue<number>(from?.numberOfDependants);
		this.bedroomCount = new ScoreValue<number>(from?.bedroomCount);
		this.rentToIncome = new ScoreValue<number>({
			score: from?.rentToIncome?.score ?? 0,
			value: from?.rentToIncome?.value
				? Math.round(from.rentToIncome.value * 10000) / 10000
				: undefined
		});
		this.utilities = new ScoreValue<number>(from?.utilities);
		this.healthAndSafety = new ScoreValue<boolean>(from?.healthAndSafety);
		this.personalWellBeing = new ScoreValue<boolean>(from?.personalWellBeing);
		this.targetPopulations = new ScoreValue<string[]>(from?.targetPopulations);
		this.discretionaryPoints = new ScoreValue<string>(from?.discretionaryPoints);
	}
	
	get total(): number {
		return this.numberOfDependants.score + this.rentToIncome.score + this.utilities.score + this.healthAndSafety.score + this.personalWellBeing.score + this.targetPopulations.score + this.discretionaryPoints.score;
	}
	
	get utilitiesScore(): ScoreValue<UtilityScore[]> {
		const value = [
			new UtilityScore({label: "Power", value: (this.utilities.value ?? 0) & 1 ? "Yes" : "No"}),
			new UtilityScore({label: "Heat", value: (this.utilities.value ?? 0) & 2 ? "Yes" : "No"}),
			new UtilityScore({label: "Water", value: (this.utilities.value ?? 0) & 4 ? "Yes" : "No"})
		];
		
		return new ScoreValue<UtilityScore[]>({
			score: this.utilities.score,
			value: value
		});
	}
}

class UtilityScore {
	readonly label: string;
	readonly value: string;

	constructor(from: {label: string, value: string}) {
		this.label = from.label;
		this.value = from.value;
	}
}

export type {IScoreValue, IPointScore};
export {ScoreValue, PointScore, UtilityScore};