import styles from "styles/RateForm.module.scss";

import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "store";
import {loadRates} from "store/slices/rates";
import {Application, CoreNeedsThresholdRate, CoreShelterRateRule, CurrencyDisplay, fullWidth, RabRateCalculator, RabRateRule, RateCalculator} from "library";
import {FormControlLabel, Grid, Typography} from "@mui/material";
import {IconCircleCheck, IconCircleX, IconEye, IconEyeOff} from "@tabler/icons";

type RabRateCalculationProps = {
    application: Application;
}

const noRateFound = "No rate could be found for this application";

const RabRateCalculation: (props: RabRateCalculationProps) => ReactNode = ({application}) => {
    const dispatch = useDispatch();
    const {rates} = useSelector(s => s.rates);

    const rateCalculator = useMemo(() => RateCalculator.for(rates, "RAB") as RabRateCalculator, [rates]);
    const coreShelterRate = useMemo(() => application.includesIncomeSupport
        ? rateCalculator.getCoreShelterRateFor(application.household)
        : undefined, [application.includesIncomeSupport, rateCalculator, application.household]);
    const baseAmount = useMemo(() => rateCalculator.getBaseAmount(application), [rateCalculator, application]);
    
    useEffect(() => {
        dispatch(loadRates());
    }, [dispatch]);

    return (
        <Grid container>
            <CoreNeedsRate annualIncome={application.incomeTotal} monthlyIncome={application.incomeTotal / 12} bedrooms={application.bedrooms}/>
            <hr style={{width: "100%"}}/>
            {application.includesIncomeSupport && coreShelterRate
                ? <CoreShelterRate adultCount={application.household.adults.length + 1} childCount={application.household.dependants18OrYounger.length} {...coreShelterRate}/>
                : <BaseRate baseAmount={baseAmount.total} monthlyIncome={application.incomeTotal / 12} parentDeduction={baseAmount.workingParentAmount}/>
            }
            <hr style={{width: "100%"}}/>
            <RabAmount monthlyRent={application.housing.monthlyRent} rateAmount={coreShelterRate?.total ?? baseAmount.total} rateLabel={coreShelterRate ? "Core Shelter Amount" : "Base RGI"}/>
            <hr style={{width: "100%"}}/>
            <RangeCheck rabRate={rateCalculator.amountFor(application, false)} monthlyRent={application.housing.monthlyRent} bedrooms={application.bedrooms}/>
        </Grid>
    );
};

const CoreNeedsRate: (props: { annualIncome: number, monthlyIncome: number, bedrooms: number }) => ReactNode = ({annualIncome, monthlyIncome, bedrooms}) => {
    const {rates: {CNITS: rates}} = useSelector(s => s.rates);

    const rate = useMemo(() => (rates as CoreNeedsThresholdRate[])
        ?.filter(r => (r.bedrooms ?? 0) <= bedrooms)
        .sort((a, b) => (a.bedrooms ?? 0) - (b.bedrooms ?? 0))
        .at(-1), [bedrooms, rates]);

    const validatedValue = (value: number, constraint: number = 0) => (
        <div className={`${styles.keyValue} ${styles.primary}`}>
            {CurrencyDisplay(value)} {value <= constraint ? <IconCircleCheck fill={"green"} color={"white"}/> : <IconCircleX fill={"red"} color={"white"}/>}
        </div>
    );

    return (
        <>
            <Grid item {...fullWidth}>
                <Typography variant={"h4"}>HILS (CNITS) Validation</Typography>
            </Grid>

            <div className={styles.rateCalculation}>
                {rate
                    ? <>
                        <FormControlLabel labelPlacement={"top"} control={validatedValue(annualIncome, rate.maximumAnnualIncome)} label={"Total Annual Income"}/>
                        <FormControlLabel labelPlacement={"top"} control={validatedValue(monthlyIncome, rate.maximumMonthlyIncome)} label={"Total Monthly Income"}/>
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{bedrooms}</div>} label={"Bedrooms"}/>
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.maximumAnnualIncome)}</div>} label={"Max Annual Income"}/>
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.maximumMonthlyIncome)}</div>} label={"Max Monthly Income"}/>
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay((rate.maximumAnnualIncome ?? 0) * 1.2)}</div>} label={"Max Annual + 20%"}/>
                    </>
                    : <>{noRateFound}</>
                }
            </div>
        </>
    );
}

const BaseRate: (props: { baseAmount: number, monthlyIncome: number, parentDeduction: number }) => ReactNode = ({baseAmount, monthlyIncome, parentDeduction}) => {
    return (
        <>
            <Grid item {...fullWidth}>
                <Typography variant={"h4"}>Base RGI</Typography>
            </Grid>

            <div className={`${styles.rateCalculation} ${styles.compressed}`}>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(baseAmount)}</div>} label={"Monthly Base RGI"}/>
                <FormControlLabel labelPlacement={"top"} control={<div>= (equals)</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div>{"("}</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(monthlyIncome)}</div>} label={"Total Monthly Income"}/>
                <FormControlLabel labelPlacement={"top"} control={<div>- (minus)</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(parentDeduction)}</div>} label={"Working Parent Deduction"}/>
                <FormControlLabel labelPlacement={"top"} control={<div>{")"}</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div>X (multiply)</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>30%</div>} label={""}/>
            </div>
        </>
    );
}

const CoreShelterRate: (props: { rate: CoreShelterRateRule | undefined, adultCount: number, childCount: number, adultAmount: number, extraChildAmount: number }) => ReactNode = ({rate, adultCount, adultAmount, childCount, extraChildAmount}) => {
    return (
        <>
            <Grid item {...fullWidth}>
                <Typography variant={"h4"}>Core Shelter</Typography>
            </Grid>

            <div className={styles.rateCalculation}>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(adultAmount + extraChildAmount)}</div>} label={"Core Shelter Amount"}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{childCount}</div>} label={"# of Children"}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{rate?.childCount}</div>} label={"# of Children"}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(adultAmount)}</div>} label={`${adultCount} Adults`}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(extraChildAmount)}</div>} label={"Additional Child(s)"}/>
            </div>
        </>
    );
}

const RabAmount: (props: { monthlyRent: number, rateLabel: string, rateAmount: number }) => ReactNode = ({monthlyRent, rateLabel, rateAmount}) => {
    return (
        <>
            <Grid item {...fullWidth}>
                <Typography variant={"h4"}>RAB Subsidy Amount</Typography>
            </Grid>

            <div className={`${styles.rateCalculation} ${styles.compressed}`}>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(monthlyRent - rateAmount)}</div>} label={"RAB Subsidy Amount"}/>
                <FormControlLabel labelPlacement={"top"} control={<div>= (equals)</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(monthlyRent)}</div>} label={"Monthly Rent (Market)"}/>
                <FormControlLabel labelPlacement={"top"} control={<div>- (minus)</div>} label={""}/>
                <FormControlLabel labelPlacement={"top"} control={<div className={`${styles.keyValue} ${styles.primary}`}>{CurrencyDisplay(rateAmount)}</div>} label={rateLabel}/>
            </div>
        </>
    );
}

const RangeCheck: (props: { bedrooms: number, rabRate: number, monthlyRent: number }) => ReactNode = ({bedrooms, rabRate, monthlyRent}) => {
    const {rates: {RAB: rates}} = useSelector(s => s.rates);
    const [showMarketRate, setShowMarketRate] = useState(false);

    const toggleShow = () => setShowMarketRate(!showMarketRate);
    
    const rate = useMemo(() => (rates as RabRateRule[])
        ?.filter(r => (r.bedrooms ?? 0) <= bedrooms)
        .sort((a, b) => (a.bedrooms ?? 0) - (b.bedrooms ?? 0))
        .at(-1), [bedrooms, rates]);

    const validatedValue = (value: number, max: number = 0, min: number = 0) => (
        <div className={`${styles.keyValue} ${styles.primary}`}>
            {CurrencyDisplay(value)} {value <= max && value >= min ? <IconCircleCheck fill={"green"} color={"white"}/> : <IconCircleX fill={"red"} color={"white"}/>}
        </div>
    );

    return (
        <>
            <Grid item {...fullWidth}>
                <Typography variant={"h4"}>
                    RAB Range Check
                    <div className={styles.marketRateToggle}>Market Rates {showMarketRate ? <IconEye className={styles.edit} onClick={toggleShow}/> : <IconEyeOff className={styles.edit} onClick={toggleShow}/>}</div>
                </Typography>
                
            </Grid>


            <div className={styles.rateCalculation}>
                {rate
                    ? <>
                        <FormControlLabel labelPlacement={"top"} control={validatedValue(rabRate, rate.maximumSubsidy, rate.minimumSubsidy)} label={"Rab Subsidy Amount"}/>
                        {showMarketRate && <FormControlLabel labelPlacement={"top"} control={validatedValue(monthlyRent, rate.maximumMarketRent)} label={"Monthly Rent (Market)"}/>}
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{bedrooms}</div>} label={"Bedrooms"}/>
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.minimumSubsidy)}</div>} label={"Min Subsidy"}/>
                        <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.maximumSubsidy)}</div>} label={"Max Subsidy"}/>
                        {showMarketRate && <FormControlLabel labelPlacement={"top"} control={<div className={styles.keyValue}>{CurrencyDisplay(rate.maximumMarketRent)}</div>} label={"Max Market Rent"}/>}
                    </>
                    : <>{noRateFound}</>
                }
            </div>
        </>
    );
}

export {
    RabRateCalculation
}