import styles from "styles/application.module.scss";

import {ThreeDots} from "react-loader-spinner";
import {useTheme} from "@mui/material/styles";

const PpulusLoader = ({noPad = false, small = false}: { noPad?: boolean, small?: boolean }) => {
    const theme = useTheme();
    const size = !small ? "50px" : "20px";
    
    return (
        <div className={`${styles.buttonGroup} ${styles.center} ${noPad ? styles.noPadding : ""}`}><ThreeDots width={size} height={size} color={theme.palette.primary.main}/></div>
    );
};

export {
    PpulusLoader
}