import styles from "styles/applicationChanges.module.scss";

import {ApplicationDocuments, BankInfo, Housing, IApplicantInformation, PropertyChange} from "library";
import {useMemo} from "react";

type ApplicationChangesProps = {
	changes: PropertyChange[];
}

const ApplicationChanges = ({changes}: ApplicationChangesProps) => {
	return (
		<div className={styles.changesContainer}>
			<h2>
				Recipient Changes
			</h2>
			<p className={styles.subText}>The change items listed below are data values that differ from the recipient's file</p>

			<ApplicantChanges changes={changes}/>
			<HouseholdChanges changes={changes}/>
			<HousingChanges changes={changes}/>
			<BankChanges changes={changes}/>
			<DocumentChanges changes={changes}/>
		</div>);
};

const IncomeMappings = {
	"income.amount": "Income Amount",
	"income.noticeOfAssessment": "Notice of Assessment?"
};

const ChangeDisplay = ({change, mapping, prefix}: { prefix?: string, mapping?: Record<string, string | undefined>, change: PropertyChange }) => {
	const propertyPattern = /(?<fixedText>\(Applicant #\d*\)\s)(?<property>.*)/;
	const mappingMatch = mapping?.[propertyPattern.exec(change.propertyName)?.groups?.["property"] ?? change.propertyName] ?? change.propertyName;

	return (
		<li>
			<span className={styles.property}>{prefix && `${prefix} `}{propertyPattern.test(change.propertyName) ? change.propertyName.replace(propertyPattern, `$1 ${mappingMatch ?? change.propertyName}`) : mappingMatch}:</span>
			<span className={styles.oldValue}>{`${change.change.oldVersion}`}</span> {"->"}
			<span className={styles.newValue}>{`${change.change.newVersion}`}</span>
		</li>
	);
};

const PersonMappings: Partial<Record<keyof IApplicantInformation, string>> = {
	address: "Address",
	email: "Email",
	citizenship: "Citizenship",
	gender: "Gender",
	dateOfBirth: "Birthdate",
	firstName: "First Name",
	middleName: "Middle Name",
	lastName: "Last Name",
	preferredName: "Preferred Name",
	mailingAddress: "Mailing Address",
	socialInsuranceNumber: "SIN",
	primaryPhone: "Primary Phone",
	secondaryPhone: "Secondary Phone",
	studentStatus: "Student Status",
	...IncomeMappings
};

const ApplicantChanges = ({changes}: ApplicationChangesProps) => {
	const applicantChanges = useMemo(() => changes.filter(c => c.propertyName.startsWith("applicant.")), [changes]);

	return !applicantChanges.length ? <></> : (<>
		<h3>Applicant</h3>
		<ul className={styles.list}>
			{applicantChanges.map(({change, propertyName}) =>
				<ChangeDisplay key={propertyName} mapping={PersonMappings} change={{change, propertyName: `${propertyName}`.replace("applicant.", "")}}/>)}
		</ul>
	</>);
};

const householdMemberPattern = /^householdMembers\[(?<index>.*?)\]\.(?<propertyName>.*)/;
const memberPropertyDisplay = (propertyName: string) => {
	const matches = householdMemberPattern.exec(propertyName);
	if (!matches) return {propertyName};

	const memberIndex = `(${matches.groups?.["index"]}) `;

	return {prefix: memberIndex, propertyName: matches.groups?.["propertyName"] ?? ""};
};

const HouseholdChanges = ({changes}: ApplicationChangesProps) => {
	const householdChanges = useMemo(() => changes.filter(c => c.propertyName.startsWith("householdMembers")), [changes]);
	const addedOrRemoved = useMemo(() => householdChanges.filter(c => !householdMemberPattern.test(c.propertyName)), [householdChanges]);
	const memberChanges = useMemo(() => householdChanges.filter(c => householdMemberPattern.test(c.propertyName)), [householdChanges]);

	return !householdChanges.length ? <></> : (<>
		<h3>Household</h3>
		<ul className={styles.list}>
			{addedOrRemoved.map(({change}, index) => <li key={index}>{`${change.oldVersion ?? ""}${change.newVersion ?? ""}`}</li>)}
			{memberChanges
				.map(({change, propertyName}) => ({change, ...memberPropertyDisplay(propertyName)}))
				.map(({change, prefix, propertyName}) =>
					<ChangeDisplay key={propertyName} mapping={PersonMappings} prefix={prefix} change={{change, propertyName}}/>)}
		</ul>
	</>);
};

const HousingChanges = ({changes}: ApplicationChangesProps) => {
	const housingChanges = useMemo(() => changes.filter(c => c.propertyName.startsWith("housing.")), [changes]);

	const mapping: Partial<Record<keyof Housing, string>> = {
		bedrooms: "Bedrooms",
		electricity: "Pay for Electricity?",
		heating: "Pay for Heating?",
		water: "Pay for Water?",
		monthlyUtilities: "Monthly Utilities",
		monthlyRent: "Monthly Rent",
		inProviderProperty: "Provider Property?",
		leaseExpiry: "Lease Expiry",
		liveInAide: "Live in Aide",
		onAMonthToMonthLease: "Month to Month Lease"
	};

	return !housingChanges.length ? <></> : (<>
		<h3>Housing Information</h3>
		<ul className={styles.list}>
			{housingChanges.map(({change, propertyName}) =>
				<ChangeDisplay key={propertyName} mapping={mapping} change={{change, propertyName: `${propertyName}`.replace("housing.", "")}}/>)}
		</ul>
	</>);
};

const isBankingField = (field: PropertyChange) => {
	const fieldName = field.propertyName.substring(field.propertyName.lastIndexOf(".") + 1);

	return Object.keys(new BankInfo())
		.includes(fieldName);
};

const BankChanges = ({changes}: ApplicationChangesProps) => {
	const bankChanges = useMemo(() => changes.filter(c => c.propertyName.startsWith("documents."))
		.filter(isBankingField), [changes]);

	const mapping: Partial<Record<keyof BankInfo, string>> = {
		chequePayments: "Cheque Payments",
		account: "Account",
		transit: "Transit",
		institution: "Institution",
		holderName: "Holder Name",
		isOrganization: "Is Organization?"
	};

	return !bankChanges.length ? <></> : (<>
		<h3>Banking Information</h3>
		<ul className={styles.list}>
			{bankChanges.map(({change, propertyName}) =>
				<ChangeDisplay key={propertyName} mapping={mapping} change={{change, propertyName: `${propertyName}`.replace("documents.", "")}}/>)}
		</ul>
	</>);
};

const DocumentChanges = ({changes}: ApplicationChangesProps) => {
	const documentChanges = useMemo(() => changes.filter(c => c.propertyName.startsWith("documents."))
		.filter(c => !isBankingField(c)), [changes]);

	const mapping: Partial<Record<keyof ApplicationDocuments, string>> = {
		other: "Other Documents",
		eftDetails: "Eft Details",
		incomeDocuments: "Income Documents",
		rentProof: "Rent Proof",
		statusInCanada: "Status in Canada"
	};

	return !documentChanges.length ? <></> : (<>
		<h3>Documentation</h3>
		<ul className={styles.list}>
			{documentChanges.map(({change, propertyName}) =>
				<ChangeDisplay key={propertyName} mapping={mapping} change={{change, propertyName: `${propertyName}`.replace("documents.", "")}}/>)}
		</ul>
	</>);
};

export {
	ApplicationChanges
};