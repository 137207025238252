import {Application} from "library";
import {HouseholdMembers, IncomeDetails} from "components";

type ApplicationHouseholdProps = {
	value: Application | undefined;
	readonly: boolean;
	onSave: (value: Partial<Application>) => Promise<void>;
}

const ApplicationHousehold = ({value, readonly, onSave}: ApplicationHouseholdProps) => {
	return !value ? <></> : (
		<>
			<HouseholdMembers value={value.household} readonly={readonly} onChange={v => onSave({household: v})}/>
			<IncomeDetails applicant={value.applicant} readonly={readonly} household={value.household} onChange={onSave}/>
		</>
	);
};

export {
	ApplicationHousehold
};