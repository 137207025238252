import styles from "styles/application.module.scss";

import {IconBrandTelegram, IconCircleX} from "@tabler/icons";
import React, {useMemo, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import {ApplicationContent, ApplicationStatus, ApplicationType, ClientStatus, EmailTemplate} from "library";
import {PpulusLoader, TextArea} from "components";
import {EmailPreview} from "./EmailPreview";

type StatusTypes = ApplicationStatus | ClientStatus | "Extend" | "On Notice";

interface IStatusDialogProps<T extends StatusTypes> {
    status: T;
    description: string | JSX.Element;
    content: string | JSX.Element;
    applicationType?: ApplicationType;
    onCancel: () => void;
    onProceed: (status: T, specialMessage: string, notes: string, sendEmail: boolean) => void;
}

interface IEmailTemplate {
    template: EmailTemplate;
    buttonText: string;
}

const EmailMapping: Partial<Record<StatusTypes, IEmailTemplate>> = {
    [ApplicationStatus.Submitted]: {template: EmailTemplate.SubmitApplication, buttonText: "Submit"},
    [ApplicationStatus.Rejected]: {template: EmailTemplate.RejectApplication, buttonText: "Reject"},
    [ApplicationStatus.Cancelled]: {template: EmailTemplate.CancelApplication, buttonText: "Cancel"},
    [ApplicationStatus.OnHold]: {template: EmailTemplate.SuspendApplication, buttonText: "Suspend"},
    [ApplicationStatus.Eligible]: {template: EmailTemplate.EligibleApplication, buttonText: "Eligible"},
    [ClientStatus.Inactive]: {template: EmailTemplate.DeactivateClient, buttonText: "Deactivate"},
    [ClientStatus.Suspended]: {template: EmailTemplate.SuspendClient, buttonText: "Suspend"},
    [ClientStatus.Active]: {template: EmailTemplate.ActivateClient, buttonText: "Activate"},
    // eslint-disable-next-line
    ["Extend"]: {template: EmailTemplate.ExtendClient, buttonText: "Extend (+1 month)"},
    // eslint-disable-next-line
    ["On Notice"]: {template: EmailTemplate.OnNoticeClient, buttonText: "On Notice (Shorten Program)"},
};

const EmailTemplateOverride = (status: StatusTypes, type: ApplicationType | undefined, template: IEmailTemplate) => {
    if (status === ApplicationStatus.Cancelled && type === ApplicationType.InterimReview)
        return {...template, template: EmailTemplate.CancelInterimReview};
    return template;
};

const StatusDialog = <T extends StatusTypes>({status, description, content, applicationType, onCancel, onProceed}: IStatusDialogProps<T>) => {
    const emailTemplate = useMemo(() => EmailTemplateOverride(status, applicationType, EmailMapping[status]!), [status, applicationType]);
    const [state, setState] = useState({specialMessage: "", notes: "", sendEmail: true});
    const [processing, setProcessing] = useState(false);

    const buttonText = !!applicationType && [ApplicationStatus.Rejected, ApplicationStatus.Cancelled, ApplicationStatus.OnHold].some(s => s === status)
        ? `${emailTemplate.buttonText} ${ApplicationContent[applicationType]!.heading}` : emailTemplate.buttonText;

    const set = (newValue: Partial<{ specialMessage: string, notes: string, sendEmail: boolean }>) => setState(s => ({...s, ...newValue}));

    const proceed = () => {
        setProcessing(true);
        onProceed(status, state.specialMessage, state.notes, state.sendEmail);
    };

    return (
        <Grid container className={styles.modalContainer}>
            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <Typography variant={"h3"}>{buttonText}</Typography>
                <br/>
                {description}
            </div>

            <div className={styles.fullWidth}>
                {content}
            </div>

            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <EmailPreview emailKey={emailTemplate.template} disabled={processing} onNoteChange={v => set({specialMessage: v})} onSendEmailChanged={v => set({sendEmail: v})}/>
            </div>

            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <TextArea label={"Notes"} value={state.notes} disabled={processing} onChange={v => set({notes: v})} rows={5}/>
            </div>

            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                {processing
                    ? <div className={styles.loader}><PpulusLoader/></div>
                    : <Button variant={"contained"} disabled={processing} startIcon={<IconBrandTelegram/>} onClick={proceed}>{buttonText}</Button>}
            </div>
        </Grid>
    );
};

export {
    StatusDialog
};