import styles from "styles/gridTable.module.scss";

import {GridColDef} from "@mui/x-data-grid";
import {BankInfo, CurrencyDisplay, DateDisplay, Payment, PaymentStatus} from "library";
import {createElement, Fragment} from "react";
import {IconCheck} from "@tabler/icons";

class ChequeRow {
    public static Columns: Record<string, GridColDef<ChequeRow>> = {
        clientCode: {field: "clientCode", headerName: "Recipient #", width: 130, headerClassName: styles.columnHeader},
        clientName: {field: "clientName", headerName: "Recipient", width: 200, headerClassName: styles.columnHeader, valueGetter: params => `${params.value}${params.row.bankDetails.isOrganization ? " (Org)" : ""}`},
        address: {field: "address", headerName: "Address", flex: 1, minWidth: 250, headerClassName: styles.columnHeader},
        program: {field: "program", headerName: "Program", width: 100, headerClassName: styles.columnHeader},
        amount: {field: "amount", headerName: "Amount", width: 140, headerClassName: styles.columnHeader, valueGetter: v => CurrencyDisplay(v.value)},
        status: {field: "status", headerName: "Status", width: 140, headerClassName: styles.columnHeader},
        paymentDateDisplay: {field: "paymentDateDisplay", headerName: "Payment Date", width: 150, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        adjustmentMade: {
            field: "adjustmentMade",
            headerName: "Adjusted",
            width: 100,
            headerClassName: styles.columnHeader,
            renderCell: v => !!v.value ? createElement(IconCheck, {color: "green"}) : createElement(Fragment)
        }
    };

    constructor(readonly id: string,
                readonly clientCode: string,
                readonly clientName: string,
                readonly bankDetails: BankInfo,
                readonly address: string,
                readonly program: string,
                readonly amount: number,
                readonly status: string,
                readonly paymentDateDisplay: Date | undefined,
                readonly adjustmentMade: boolean,
                readonly payment: Payment) {
    }

    static From(payment: Payment): ChequeRow {
        return new ChequeRow(
            payment.id ?? "",
            payment.clientCode,
            payment.clientName,
            payment.bankDetails,
            payment.address,
            payment.program,
            payment.amount,
            payment.status,
            payment.status === PaymentStatus.Paid ? payment.scheduledDate : undefined,
            payment.adjustmentMade,
            payment
        );
    }
}

export {ChequeRow};