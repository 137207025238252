import {YesNoSilent} from "library/enums";

interface ISituation {
	accommodationForDisability: YesNoSilent;
	armedForceMember: YesNoSilent,
	currentlyEmployed: YesNoSilent;
	emergencySituation: YesNoSilent;
	facingViolence: YesNoSilent,
	hasDisability: YesNoSilent,
	hasMentalConcerns: YesNoSilent,
	homeless: YesNoSilent,
	indigenous: YesNoSilent,
	immigrant: YesNoSilent,
	isMinority: YesNoSilent,
	lgbtq: YesNoSilent,
	overcrowded: YesNoSilent;
	receivingOtherBenefits: YesNoSilent;
	sharedAccommodation: YesNoSilent;
	threatToPhysicalWellBeing: YesNoSilent;
	training: YesNoSilent;
	transitioningOutOfCare: YesNoSilent;
}

const SituationCategories = ["Housing Information", "Target Population", "Financial", "Health and Safety"] as const;
type SituationCategory = typeof SituationCategories[number];

class Situation implements ISituation {
	[key: string]: YesNoSilent;

	readonly accommodationForDisability: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly armedForceMember: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly currentlyEmployed: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly emergencySituation: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly facingViolence: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly hasDisability: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly hasMentalConcerns: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly homeless: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly lgbtq: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly indigenous: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly immigrant: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly isMinority: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly overcrowded: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly receivingOtherBenefits: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly sharedAccommodation: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly threatToPhysicalWellBeing: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly training: YesNoSilent = YesNoSilent.PreferNotToSay;
	readonly transitioningOutOfCare: YesNoSilent = YesNoSilent.PreferNotToSay;
	
	constructor(from?: Partial<ISituation>) {
		const valueOrDefault = (value?: YesNoSilent) => value ?? YesNoSilent.PreferNotToSay;

		this.accommodationForDisability = valueOrDefault(from?.accommodationForDisability);
		this.armedForceMember = valueOrDefault(from?.armedForceMember);
		this.currentlyEmployed = valueOrDefault(from?.currentlyEmployed);
		this.emergencySituation = valueOrDefault(from?.emergencySituation);
		this.facingViolence = valueOrDefault(from?.facingViolence);
		this.hasDisability = valueOrDefault(from?.hasDisability);
		this.hasMentalConcerns = valueOrDefault(from?.hasMentalConcerns);
		this.homeless = valueOrDefault(from?.homeless);
		this.indigenous = valueOrDefault(from?.indigenous);
		this.immigrant = valueOrDefault(from?.immigrant);
		this.isMinority = valueOrDefault(from?.isMinority);
		this.lgbtq = valueOrDefault(from?.lgbtq);
		this.overcrowded = valueOrDefault(from?.overcrowded);
		this.sharedAccommodation = valueOrDefault(from?.sharedAccommodation);
		this.receivingOtherBenefits = valueOrDefault(from?.receivingOtherBenefits);
		this.threatToPhysicalWellBeing = valueOrDefault(from?.threatToPhysicalWellBeing);
		this.training = valueOrDefault(from?.training);
		this.transitioningOutOfCare = valueOrDefault(from?.transitioningOutOfCare);
	}

	static map(k: keyof ISituation): { label: string, description: string, category: SituationCategory } {
		switch (k) {
			case "armedForceMember":
				return {
					label: "Veteran?",
					category: "Target Population",
					description: "Are you or a member of your household a former member of the Canadian Armed Forces"
				};
			case "facingViolence":
				return {
					label: "Facing Violence?",
					category: "Health and Safety",
					description: "Are you or a member of your household fleeing violence? This may include domestic violence, family violence, or human trafficking"
				};
			case "immigrant":
				return {
					label: "Recent Immigrant?",
					category: "Target Population",
					description: "Are you or a member of your household a recent immigrant or refugee who has moved to Canada from another country in the last 5 years?"
				};
			case "lgbtq":
				return {
					label: "LGBTQ2S+?",
					category: "Target Population",
					description: "Do you or a member of your household identify as a member of the 2SLGBTQ+ community?"
				};
			case "isMinority":
				return {
					label: "Racialized?",
					category: "Target Population",
					description: "Do you, or a member of your household, identify as racialized - Black, Indigenous, or Person of Colour (BIPOC) or visible minority (persons who are non-Caucasian in race or non-white in colour)?"
				};
			case "threatToPhysicalWellBeing":
				return {
					label: "Threat to Physical Well Being?",
					category: "Health and Safety",
					description: "Are you currently living in a situation that is unsafe to you and your family's physical safety and wellbeing (e.g. housing contributing to a worsening health condition, family violence, neighbourhood gang activity, racially motivated crimes, etc..)?"
				};
			case "currentlyEmployed":
				return {
					label: "Currently Employed?",
					category: "Financial",
					description: "Are you currently employed on a consistent basis, working 10 or more hours a week?"
				};
			case "accommodationForDisability":
				return {
					label: "Accommodation for Disability?",
					category: "Housing Information",
					description: "Do you, or anyone in your household require physical accommodations for a disability that is not being met in your current housing?"
				};
			case "hasDisability":
				return {
					label: "Has Disability?",
					category: "Target Population",
					description: "Do you or a member of your household have a physical or mental disability?"
				};
			case "homeless":
				return {
					label: "Homeless?",
					category: "Housing Information",
					description: "Are you at risk of, or currently experiencing or transitioning out of homelessness? Risk of homelessness may include if your current housing does not meet the adequacy or suitability standards, or if you have an eviction notice and must move out of your housing through no fault of your own."
				};
			case "indigenous":
				return {
					label: "Indigenous?",
					category: "Target Population",
					description: "Do you, or a member of your household, identify as an Indigenous person in Canada such as First Nations, Inuit, or Metis?"
				};
			case "hasMentalConcerns":
				return {
					label: "Mental Health Challenges?",
					category: "Health and Safety",
					description: "Are you or a member of your household currently dealing with mental health concerns or addictions?"
				};
			case "transitioningOutOfCare":
				return {
					label: "Transitioning out of care?",
					category: "Target Population",
					description: "Are you or a member of your household under the age of 24 and transitioning out of government care (e.g. foster home)?"
				};
			case "overcrowded":
				return {
					label: "Overcrowded?",
					category: "Housing Information",
					description: "Is your current housing overcrowded?"
				};
			case "sharedAccommodation":
				return {
					label: "Shared Accommodation?",
					category: "Housing Information",
					description: "Are you currently in a shared accommodation living with another family?"
				};
			case "receivingOtherBenefits":
				return {
					label: "Receiving other benefits?",
					category: "Financial",
					description: "Are you or a member of your household receiving social assistance, including Income Support, Learner Income Support, Assured Income for the Severely Handicapped, Alberta Seniors Benefit, Guaranteed Income Supplement, Old Age Security?"
				};
			case "emergencySituation":
				return {
					label: "Emergency Situation?",
					category: "Housing Information",
					description: "Are you losing your current accommodation due to an emergency situation?"
				};
			case "training":
				return {
					label: "Training?",
					category: "Financial",
					description: "Are you currently attending, or enrolled to attend within the next three months, any type of educational training (e.g. Rent Smart, university, college, trades, etc.)?"
				}
		}
	}  
}

export type {ISituation, SituationCategory};
export {Situation, SituationCategories};