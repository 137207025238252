import styles from "styles/gridTable.module.scss";

import {GridColDef} from "@mui/x-data-grid";
import {Application, ApplicationStatus, DateDisplay} from "library";

class InterimReviewRow {
    public static Columns: Record<string, GridColDef<InterimReviewRow>> = {
        code: {field: "code", headerName: "Interim Review ID", minWidth: 140, headerClassName: styles.columnHeader},
        client: {field: "client", headerName: "Recipient ID", minWidth: 120, headerClassName: styles.columnHeader},
        clientName: {field: "clientName", headerName: "Recipient Name", flex: 1, headerClassName: styles.columnHeader},
        status: {field: "status", headerName: "Status", minWidth: 120, headerClassName: styles.columnHeader},
        primaryAssignee: {field: "primaryAssignee", headerName: "P. Assignee", minWidth: 100, headerClassName: styles.columnHeader},
        secondaryAssignee: {field: "secondaryAssignee", headerName: "S. Assignee", minWidth: 100, headerClassName: styles.columnHeader},
        modifiedOn: {field: "modifiedOn", headerName: "Modified On", minWidth: 120, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        dueDate: {field: "dueDate", headerName: "Due Date", minWidth: 120, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        submittedDate: {
            field: "submittedDate",
            headerName: "Date Submitted",
            minWidth: 200,
            headerClassName: styles.columnHeader,
            valueGetter: params => params.row.status === ApplicationStatus.Submitted ? DateDisplay.Long(params.value) : ""
        }
    };

    constructor(
        readonly id: string,
        readonly code: string,
        readonly client: string,
        readonly clientName: string,
        readonly status: ApplicationStatus,
        readonly primaryAssignee: string | undefined,
        readonly secondaryAssignee: string | undefined,
        readonly modifiedOn: Date,
        readonly dueDate?: Date,
        readonly submittedDate?: Date
    ) {
    }

    static From(application: Application): InterimReviewRow | undefined {
        return new InterimReviewRow(
            application.id,
            application.code,
            application.clientCode!,
            application.applicant.displayName,
            application.status,
            application.assignments.primary?.name,
            application.assignments.secondary?.name,
            application.modifiedOn,
            application.dueDate,
            application.lastStatusUpdateOn
        );
    }
}

export { InterimReviewRow };
