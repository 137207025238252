import styles from "styles/textArea.module.scss";

import {ReactNode, useEffect, useRef, useState} from "react";


type TextAreaProps = {
    value?: string;
    label?: string;
    error?: boolean;
    rows?: number;
    disabled?: boolean;
    className?: string;
    onChange?: (value: string) => void;
}

/*
MUI multiline Textfield component throws Resize loop exceptions when the page is resized.  Created as a workaround
*/
const TextArea: (props: TextAreaProps) => ReactNode = ({value, label, error = false, rows, disabled = false, className, onChange}) => {
    const containerElement = useRef<any>(null);
    const [active, setActive] = useState(false);

    useEffect(() => {
        const handleIsActive = (event: MouseEvent) =>
            setActive(!!containerElement.current && containerElement.current!.contains(event.target));

        document.addEventListener("mousedown", handleIsActive);

        return () => document.removeEventListener("mousedown", handleIsActive);
    }, []);

    return (
        <div ref={containerElement} className={styles.container}>
            <fieldset className={`${styles.fieldset} ${active ? styles.active : ""} ${error ? styles.error : ""}`}>
                <textarea value={value} rows={rows} disabled={disabled} className={`${styles.textarea} ${className ?? ""}`} onChange={(e) => onChange?.(e.target.value)}/>
                <legend><span className={`${active ? styles.active : ""}`}>{label}</span></legend>
            </fieldset>
        </div>
    );
};

export {
    TextArea
}