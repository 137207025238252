import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {YesNo} from "library";
import React, {ReactNode} from "react";

const YesNoButton: (props: { value: YesNo | undefined, disabled?: boolean, onChange: (value: YesNo) => void }) => ReactNode = ({value, disabled, onChange}) => {
	return (
		<ToggleButtonGroup color={"primary"} disabled={disabled} fullWidth>
			<ToggleButton value={YesNo.Yes} fullWidth selected={value === YesNo.Yes} onClick={() => onChange(YesNo.Yes)}>{YesNo.Yes}</ToggleButton>
			<ToggleButton value={YesNo.No} fullWidth selected={value === YesNo.No} onClick={() => onChange(YesNo.No)}>{YesNo.No}</ToggleButton>
		</ToggleButtonGroup>
	);
};

export {
	YesNoButton
};