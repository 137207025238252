import {useCallback, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "store";
import {Address} from "library";
import {setAddress, setCssLoaded, setInitialized, setScriptLoaded} from "store/slices/canadaPost";

const CP_JS_URL = "//ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js";
const CP_CSS_URL = "//ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css";

declare let pca: any;

interface ICanadaPostAddress {
	BuildingNumber: string,
	Street: string,
	SubBuilding : string,
	City: string,
	Province: string,
	PostalCode: string,
	CountryName: string,
}

const fieldNameMapper = [
	{ formFieldChars: /.*streetNumber/i, cpField: "BuildingNumber"},
	{ formFieldChars: /.*streetName/i, cpField: "Street"},
	{ formFieldChars: /.*lookup/i, cpField: ""},
];

const useCanadaPost = (idField: string) => {
	const {initialized, scriptLoaded, cssLoaded} = useSelector(s => s.canadaPost);
	const dispatch = useDispatch();
	
	const handleLoadError = useCallback((error: ErrorEvent) => {
		console.error("Error loading Canada Post scripts", error);
	}, []);

	const field = useMemo(() => {
		if (!scriptLoaded) return {};

		const element = idField;
		const mode = pca.fieldMode.DEFAULT;
		const field = fieldNameMapper.find(e => idField.match(e.formFieldChars))?.cpField;

		if (typeof field === "undefined") {
			return {};
		}

		return { element, field, mode };
		// eslint-disable-next-line 
	}, [scriptLoaded]);
	
	useEffect(() => {
		if (initialized) return;
		
		dispatch(setInitialized());
		const script = document.createElement("script");
		script.addEventListener("load", () => dispatch(setScriptLoaded()));
		script.addEventListener("error", handleLoadError);
		script.src = `${CP_JS_URL}?key=${process.env.REACT_APP_CP_KEY}`;
		script.async = true;

		const css = document.createElement("link");
		css.addEventListener("load", () => dispatch(setCssLoaded()));
		css.addEventListener("error", handleLoadError);
		css.rel = "stylesheet";
		css.type = "text/css";
		css.href = `${CP_CSS_URL}?key=${process.env.REACT_APP_CP_KEY}`;

		document.body.appendChild(script);
		document.body.appendChild(css);
	}, [initialized, dispatch,  handleLoadError]);
	
	useEffect(() => {
		if (!scriptLoaded || !cssLoaded) return;

		new pca.Address([field], {key: `${process.env.REACT_APP_CP_KEY}`}).listen("populate", (a: ICanadaPostAddress) => {
			dispatch(setAddress({
				lookup: field.element,
				address: new Address({
					streetNumber: a.BuildingNumber,
					streetName: a.Street,
					unit: a.SubBuilding,
					city: a.City,
					province: a.Province,
					postalCode: a.PostalCode,
					country: a.CountryName,
				})
			}));
		});
	}, [scriptLoaded, cssLoaded, dispatch, field]);
};

export {
	useCanadaPost
};