import styles from "styles/gridTable.module.scss";

import {Button, Grid} from "@mui/material";
import {DataGrid, GridActionsColDef, GridColDef, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";
import {TableToolbar} from "components";
import {Client, ClientStatus, PpulusRoutes} from "library";
import {FC, useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "store";
import {getClients, myClients, setClientFilter, setClientPage, setClientSort} from "store/slices/client";
import {formatDate} from "utils/util";

class ClientRow {
  public static Columns: Partial<Record<keyof ClientRow, GridColDef>> = {
    code: {field: "code", headerName: "Code", width: 115, headerClassName: styles.columnHeader},
    firstName: {field: "firstName", headerName: "First Name", width: 160, headerClassName: styles.columnHeader},
    lastName: {field: "lastName", headerName: "Last Name", width: 160, headerClassName: styles.columnHeader},
    email: {field: "email", headerName: "Email", width: 200, headerClassName: styles.columnHeader},
    phone: {field: "phone", headerName: "Phone", flex: 1, headerClassName: styles.columnHeader},
    dob: {field: "dob", headerName: "Date of Birth", width: 120, valueGetter: v => formatDate(v.value), headerClassName: styles.columnHeader},
    programs: {field: "programs", headerName: "Programs", flex: 1, headerClassName: styles.columnHeader},
    status: {field: "status", headerName: "Status", flex: 1, headerClassName: styles.columnHeader},
    createdOn: {field: "createdOn", headerName: "Created On", width: 120, valueGetter: v => formatDate(v.value), headerClassName: styles.columnHeader},
    modifiedOn: {field: "modifiedOn", headerName: "Modified On", width: 120, valueGetter: v => formatDate(v.value), headerClassName: styles.columnHeader}
  };

  constructor(readonly id: string,
              readonly code: string,
              readonly firstName: string,
              readonly lastName: string,
              readonly dob: Date | undefined,
              readonly email: string | undefined,
              readonly phone: string | undefined,
              readonly programs: string[],
              readonly status: ClientStatus,
              readonly createdOn: Date,
              readonly modifiedOn: Date) {
  }

  static From(client: Client): ClientRow {
    return {
      id: client.id,
      code: client.code,
      firstName: client.primaryContact.firstName,
      lastName: client.primaryContact.lastName,
      dob: client.primaryContact.dateOfBirth,
      email: client.primaryContact.email?.address,
      phone: client.primaryContact.primaryPhone?.number,
      programs: client.activeProgramNames,
      status: client.status,
      createdOn: client.createdOn,
      modifiedOn: client.modifiedOn
    }
  };
}

const ClientList: FC<{ key: string, mine?: boolean }> = ({key, mine}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, items: clients, page, pageSize, count, sort} = useSelector(state => state.clients);

  const staticFilter = useMemo(() => ({
    items: [
      ...(mine ? [{ columnField: myClients, value: mine, operatorValue: "equals" }] : [])
    ]
  }), [mine]);

  useEffect(() => {
    dispatch(getClients({filter: staticFilter, page: 0, pageSize: 15}));
    // eslint-disable-next-line 
  }, [dispatch, mine]);

  const onPage = useCallback((page: number) => {
    dispatch(setClientPage(page));
  }, [dispatch]);

  const onSort = useCallback((model: GridSortModel) => {
    dispatch(setClientSort(model.shift()));
  }, [dispatch]);

  const onFilter = useCallback((model: GridFilterModel) => {
    dispatch(setClientFilter({items: [...staticFilter.items, ...model.items]}));
  }, [dispatch, staticFilter]);

  const viewRowItem = (id: string) => navigate(`${PpulusRoutes.Clients}/${id}`);

  const columns: GridColDef<ClientRow>[] = [
    ...Object.values(ClientRow.Columns),
    {
      field: "actions", type: "actions", width: 200, align: "right", sortable: false, headerClassName: styles.columnHeader, getActions: params => [
        <Button onClick={() => viewRowItem(`${params.id}`)}>View</Button>
      ]
    } as GridActionsColDef<ClientRow>
  ];

  const initialTableState: GridInitialStateCommunity = {
    columns: {
      columnVisibilityModel: {
        id: false,
        createdOn: false
      }
    },
    sorting: {
      sortModel: [{field: "lastName", sort: "asc"}]
    },
    pagination: {page: 0, pageSize}
  }

  return (
      <Grid container columnSpacing={4}>
        <Grid item width={"100%"}>
          <DataGrid autoHeight
                    key={key}
                    initialState={initialTableState}
                    columns={columns}
                    rows={clients.map(ClientRow.From)}
                    onRowDoubleClick={row => viewRowItem(`${row.id}`)}
                    rowCount={count}
                    loading={loading}
                    getRowClassName={() => styles.row}
                    getRowId={r => r.id}
                    paginationMode={"server"} onPageChange={onPage} page={page}
                    sortingMode={"server"} onSortModelChange={onSort} sortModel={[sort ?? {field: "lastName", sort: "asc"}]}
                    filterMode={"server"} onFilterModelChange={onFilter}
                    components={{Toolbar: TableToolbar}} />
        </Grid>
      </Grid>
  );
};

export {
  ClientList
};
