import {IncomeFrequency, YesNoUnsure} from "library/enums";
import {IncomeType} from "../incomeTypes";

class IncomeAssets {
	[key: string]: any;
	
	readonly noticeOfAssessment: YesNoUnsure;
	readonly noticeOfAssessmentIncome: NoticeOfAssessmentDetail[] = [];
	readonly otherIncome: StandardIncomeDetail[] = [];

	constructor(from?: Partial<IncomeAssets>, incomeTypes?: IncomeType[]) {
		const withTrueSource = (income: IncomeDetail) => ({...income, source: incomeTypes?.find(i => i.code === income.source?.code) ?? income.source});
		
		this.noticeOfAssessment = from?.noticeOfAssessment ?? YesNoUnsure.Unsure;
		if (this.noticeOfAssessment === YesNoUnsure.Yes)
			this.noticeOfAssessmentIncome = from?.noticeOfAssessmentIncome?.map(i => new NoticeOfAssessmentDetail(withTrueSource(i))) ?? [];
		else
			this.otherIncome = from?.otherIncome?.map(i => new StandardIncomeDetail(withTrueSource(i))) ?? [];
	}

	get totalAnnual(): number {
		return [[...this.noticeOfAssessmentIncome].sort((a, b) => Number(b.year) - Number(a.year)).shift()]
			.concat(this.otherIncome)
			.reduce((aggregate, current) => aggregate + (current?.annualAmount ?? 0), 0);
	}

	get includesIncomeSupport(): boolean {
		return [...this.noticeOfAssessmentIncome, ...this.otherIncome].some(i => !!i.source?.incomeSupport);
	}

	get hasIncome(): boolean {
		return !![...this.noticeOfAssessmentIncome, ...this.otherIncome].length;
	}
}

abstract class IncomeDetail {
	protected constructor(readonly amount: number, readonly source?: IncomeType) {
	}

	abstract get annualAmount(): number;
}

class NoticeOfAssessmentDetail extends IncomeDetail {
	readonly year?: string;

	constructor(from?: Partial<NoticeOfAssessmentDetail>) {
		super(!!from?.source?.fixedIncomeRate?.monthlyIncome ? from.source.fixedIncomeRate.monthlyIncome * 12 : from?.amount ?? 0, from?.source);

		this.year = from?.year;
	}

	get annualAmount() {
		return this.amount;
	}
}

class StandardIncomeDetail extends IncomeDetail {
	readonly frequency?: IncomeFrequency;

	constructor(from?: Partial<StandardIncomeDetail>) {
		super(!!from?.source?.fixedIncomeRate?.monthlyIncome ? from.source.fixedIncomeRate.monthlyIncome : from?.amount ?? 0, from?.source);

		this.frequency = !!from?.source?.fixedIncomeRate ? IncomeFrequency.Monthly : from?.frequency;
	}

	get annualAmount() {
		if (this.source?.excluded) return 0;
		
		switch (this.frequency) {
			case IncomeFrequency.Weekly:
				return this.amount * 52;
			case IncomeFrequency.BiWeekly:
				return this.amount * 26;
			case IncomeFrequency.SemiMonthly:
				return this.amount * 24;
			case IncomeFrequency.Monthly:
				return this.amount * 12;
			case IncomeFrequency.Quarterly:
				return this.amount * 4;
			default:
				return this.amount;
		}
	}
}

export {IncomeAssets, StandardIncomeDetail, NoticeOfAssessmentDetail};