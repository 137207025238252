import clientStyles from "styles/client.module.scss";
import styles from "styles/gridTable.module.scss";

import {Button, Card, FormControl, FormHelperText, Grid, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {DataGrid, getGridDateOperators, GridActionsColDef, GridColDef} from "@mui/x-data-grid";
import {IconCheck, IconPencil} from "@tabler/icons";
import {AuditDetails, DateInput, FormValueDisplay, PpulusLoader, TableToolbar, TextArea} from "components";
import {BankInfo, Client, ClientStatus, CurrencyDisplay, DateDisplay, fullWidth, halfWidth, hasError, IPayment, isEmpty, parseNumber, Payment, PaymentStatus, PaymentType} from "library";
import {createElement, Fragment, ReactNode, useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "store";
import {createPayment, deletePayment, updatePayment} from "store/slices";
import {useEntitlements} from "@frontegg/react";

class DisbursementRow {
    public static Columns: Record<string, GridColDef> = {
        date: {field: "period", headerName: "Period", width: 100, headerClassName: styles.columnHeader, valueGetter: v => DateDisplay.PeriodNumeric(v.value)},
        program: {field: "program", headerName: "Program", flex: 1, headerClassName: styles.columnHeader},
        amount: {
            field: "amount",
            headerName: "Amount",
            width: 120,
            headerClassName: styles.columnHeader,
            valueGetter: v => CurrencyDisplay(v.value),
        },
        period: {field: "type", headerName: "Type", flex: 1, filterOperators: getGridDateOperators(), headerClassName: styles.columnHeader},
        paymentDate: {field: "paymentDate", headerName: "Payment Date", width: 140, filterOperators: getGridDateOperators(), headerClassName: styles.columnHeader},
        status: {field: "status", headerName: "Status", width: 140, headerClassName: styles.columnHeader},
        adjusted: {field: "adjusted", headerName: "Adjusted", width: 100, headerClassName: styles.columnHeader, renderCell: v => !!v.value ? createElement(IconCheck, {color: "green"}) : createElement(Fragment)},
        notes: {field: "notes", headerName: "Notes", flex: 1, headerClassName: styles.columnHeader}
    };

    constructor(readonly id: string,
                readonly period: string,
                readonly program: string,
                readonly amount: number,
                readonly status: string,
                readonly paymentDate: Date | undefined,
                readonly type: string,
                readonly adjusted: boolean,
                readonly notes: string) {
    }

    static From(payment: Payment, amount?: number): DisbursementRow {

        return new DisbursementRow(
            payment.id ?? "",
            DateDisplay.Long(payment.scheduledDate),
            payment.program,
            amount ?? payment.amount,
            payment.status,
            payment.paymentDate,
            payment.type,
            payment.adjustmentMade,
            payment.note
        );
    }
}

const ClientDisbursements: (props: {value: Client | undefined}) => ReactNode = () => {
    const dispatch = useDispatch();
    const {item: client, loading} = useSelector(s => s.client);
    const [editId, setEditId] = useState<string>();
    const [newDisbursement, setNewDisbursement] = useState<Payment>();

    const readonly = useMemo(() => client?.status === ClientStatus.Inactive, [client?.status]);
    
    const columns = useMemo(() => [
        ...Object.values(DisbursementRow.Columns),
        ...(readonly
            ? []
            : [{
            field: "actions", type: "actions", flex: 1, headerClassName: styles.columnHeader, getActions: p => [
                    <Button onClick={() => setEditId(`${p.id}`)}><IconPencil/></Button>
                ]
            } as GridActionsColDef])
    ], [setEditId, readonly]);
    
    const rows = useMemo(() => client?.payments
        .map(d => DisbursementRow.From(new Payment(d))) ?? [], [client?.payments]);

    const editingPayment = useMemo(() => client?.payments.find(p => p.id === editId), [client, editId]);

    const set = useCallback((updated: Partial<Payment>) => {
        dispatch(updatePayment(new Payment({...editingPayment!, ...updated})));
        setEditId(undefined);
    }, [dispatch, setEditId, editingPayment]);

    const addDisbursement = () => {
        setNewDisbursement(new Payment({
            clientId: client?.id,
            clientCode: client?.code,
            clientName: client?.primaryContact?.displayName,
            bankDetails: client?.documents.bankInfo,
            address: client?.documents.bankInfo.chequePayments ? client.primaryContact.address?.toString() : undefined,
            program: client?.activeProgram?.name
        }));
    };

    const saveNewDisbursement = (value: Partial<Payment>) => {
        dispatch(createPayment(new Payment({...newDisbursement, ...value})));
        setNewDisbursement(undefined);
    };

    const deleteDisbursement = () => {
        if (!editingPayment?.id) return;
        dispatch(deletePayment(new Payment(editingPayment)));
        setEditId(undefined);
    }

    if (loading)
        return <PpulusLoader/>;

    return !editingPayment
        ? (
            <div className={clientStyles.contentBg}>
                <div className={clientStyles.title}>
                    <Typography variant={"h3"}>Disbursements</Typography>
                    {!newDisbursement && <Button variant={"contained"} color={"primary"} disabled={loading} onClick={() => addDisbursement()}>ADD DISBURSEMENT</Button>}
                </div>
                {newDisbursement &&
                    <Card className={""}>
                        <EditPayment payment={newDisbursement} onChange={saveNewDisbursement} onCancel={() => setNewDisbursement(undefined)}/>
                    </Card>
                }
                <Card>
                    <DataGrid autoHeight
                        initialState={{ sorting: { sortModel: [{ field: "period", sort: "asc" }] } }}
                        columns={columns}
                        rows={rows}
                        getRowId={row => row.id}
                        getRowClassName={() => styles.row}
                        components={{ Toolbar: TableToolbar }}
                        onRowDoubleClick={row => setEditId(`${row.id}`)}
                    />
                </Card>
            </div>
        )
        : <Card>
            <EditPayment payment={editingPayment} onChange={set} onCancel={() => setEditId(undefined)} onDelete={deleteDisbursement}/>
            <Grid container>
                <AuditDetails value={editingPayment}/>
            </Grid>
        </Card>;
};

type EditPaymentProps = {
    payment: IPayment;
    onChange: (value: Partial<Payment>) => void;
    onCancel: () => void;
    onDelete?: () => void;
}

const EditPayment: (props: EditPaymentProps) => ReactNode = ({payment, onChange, onCancel, onDelete}) => {
    const [state, setState] = useState(new Payment({...payment, adjustmentMade: true}));
    const [period, setPeriod] = useState<{ year?: number, month?: number }>({year: state.period.year, month: state.period.month});
    const [periodError, setPeriodError] = useState<{ year?: string, month?: string }>();

    const set = (value: Partial<Payment>) => {
        setState(new Payment({...state, ...value}));
    }

    const setYearMonth = (value: Partial<{ year?: number, month?: number }>) => {
        const updatedPeriod = {...period, ...value};
        setPeriod(updatedPeriod);
        set(updatedPeriod?.year && updatedPeriod?.month ? {scheduledDate: new Date(updatedPeriod.year, updatedPeriod.month - 1, 1)} : {});
        setPeriodError(undefined);
    }

    const isNew = !payment.id;
    const canEditPaymentDate = useEntitlements(({permissionKey: "disbursement.payment.editDate"})).isEntitled;
    const canEditPaymentStatus = useEntitlements({permissionKey: "disbursement.payment.editStatus"}).isEntitled;
    const isUpcoming = payment.status === PaymentStatus.Upcoming;
    const canEditPaymentPeriod = useEntitlements({permissionKey: "disbursement.payment.editPeriod"}).isEntitled && isUpcoming;
    const canDeletePayment = useEntitlements({permissionKey: "disbursement.payment.delete"}).isEntitled && isUpcoming;

    const onSave = () => {
        const validated = state.validate();
        setState(validated);

        const errorsInPeriod = {
            year: !period?.year || Math.abs(period.year - new Date().getFullYear()) > 2 ? "Year must be within next 2 years" : undefined,
            month: !period?.month || period.month < 1 || period.month > 12 ? "Invalid month value" : undefined
        };
        setPeriodError(errorsInPeriod);

        if (hasError(validated) || !isEmpty(errorsInPeriod) || !onChange) return;

        onChange(validated);
    };

    return (
        <>
            <Typography variant={"h3"} className={clientStyles.tabTitle}>{isNew ? "New Payment" : `Payment for ${DateDisplay.Period(payment.scheduledDate)}`}</Typography>
            <Grid container className={clientStyles.paymentContainer} {...fullWidth}>
                <Stack spacing={2} {...halfWidth}>
                    <FormValueDisplay label={"Program"} value={isNew && !payment.program ?
                        <FormControl fullWidth>
                            <Select
                                variant={"standard"}
                                fullWidth
                                value={state.program}
                                onChange={(e) => set({ program: e.target.value })}>
                                {["RAB", "TRAB", "PLRS"].map(p => <MenuItem value={p} key={p}>{p}</MenuItem>)}
                            </Select>
                        </FormControl> :
                        (payment.program)} />
                    <FormValueDisplay label={"Period"}
                                      value={isNew || canEditPaymentPeriod ? <>
                                          <TextField type={"number"}
                                                     fullWidth
                                                     placeholder={"Year"}
                                                     variant={"standard"}
                                                     value={period?.year ?? ""}
                                                     error={!!periodError?.year}
                                                     helperText={periodError?.year}
                                                     onChange={e => setYearMonth({year: Number(e.target.value.slice(0, 4))})}/>
                                          <Select
                                              variant={"standard"}
                                              fullWidth
                                              value={period?.month}
                                              error={!!periodError?.month}
                                              onChange={e => setYearMonth({ month: Number(e.target.value) })}>
                                              {[...Array(12).keys()].map(p => <MenuItem value={p + 1} key={p}>{p + 1}</MenuItem>)}
                                          </Select>
                                      </> : (payment.period.toString())}/>
                    <FormValueDisplay label={"Type"} value={isNew ?
                        <FormControl fullWidth>
                            <Select
                                variant={"standard"}
                                fullWidth
                                value={state.type}
                                onChange={(e) => set({ bankDetails: new BankInfo({ ...state.bankDetails, chequePayments: (e.target.value as PaymentType) === PaymentType.Cheque }) })}>
                                {Object.entries(PaymentType).map(([k, v]) => <MenuItem value={v} key={k}>{v}</MenuItem>)}
                            </Select>
                        </FormControl> :
                        (state.type)} />
                    <FormValueDisplay label={"Status"} value={canEditPaymentStatus || isNew ?
                        <FormControl fullWidth>
                            <Select
                                variant={"standard"}
                                fullWidth
                                value={state.status}
                                onChange={(e) => set({ status: e.target.value as PaymentStatus })}>
                                {Object.entries(PaymentStatus).map(([k, v]) => <MenuItem value={v} key={k}>{v}</MenuItem>)}
                            </Select>
                        </FormControl> :
                        (payment.status)
                    }
                    />
                    {state.status === PaymentStatus.Paid && <FormValueDisplay label={"Payment Date"} value={canEditPaymentDate || isNew ?
                        <DateInput value={state.paymentDate ?? new Date()} onChange={v => set({ paymentDate: v })} />
                        : DateDisplay.Standard(state.paymentDate)
                    }
                    />}
                    <FormValueDisplay label={"Amount"} value={<TextField type={"number"}
                        value={state.amount ?? ""}
                        error={!!state.errorState.amount}
                        helperText={state.errorState.amount}
                        disabled={![PaymentStatus.Upcoming, PaymentStatus.OnHold].includes(state.status)}
                        onChange={e => set({ amount: parseNumber(e.target.value) })} />} />
                </Stack>
                <Grid item {...fullWidth} >
                    <TextArea label={"Notes / Reason"} rows={5} value={state.note}
                        error={!!state.errorState.note}
                        onChange={v => set({ note: v })} />
                    <FormHelperText error={!!state.errorState.note}>{state.errorState.note}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container {...fullWidth}>
                <Grid item className={clientStyles.buttonGroup}>
                    {canDeletePayment && onDelete && <Button className={clientStyles.button} variant={"outlined"} onClick={() => window.confirm("Are you sure you wish to delete this disbursement?") && onDelete()}>Delete</Button>}
                    <Button className={clientStyles.button} variant={"outlined"} onClick={onCancel}>Cancel</Button>
                    <Button className={clientStyles.button} variant={"contained"} onClick={onSave}>Save</Button>
                </Grid>
            </Grid>
        </>
    );
};

export {
    ClientDisbursements
};
