import {createAppAsyncThunk} from "./asyncThunk";
import agent, {FilterOptions} from "api/agent";
import {createSlice} from "@reduxjs/toolkit";
import {IncomeType, spliceArray} from "library";
import {GridSortItem} from "@mui/x-data-grid";

const getIncomeTypes = createAppAsyncThunk("incomeTypes/load", async (_: void) => {
    return await agent.IncomeTypes.list();
});

const saveIncomeType = createAppAsyncThunk("incomeTypes/save", async (value: IncomeType) => {
    return await agent.IncomeTypes.save(value);
});

const initialState: FilterOptions & { loading: boolean, items: IncomeType[], count: number } = {
    loading: true,
    sort: {field: "name", sort: "asc"} as GridSortItem,
    items: [],
    pageSize: 15,
    count: 0
};

const incomeTypes = createSlice({
    name: "incomeTypes",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getIncomeTypes.pending, (state) => ({...state, loading: true, items: []}));
        builder.addCase(getIncomeTypes.fulfilled, (state, {payload}) => ({...state, loading: false, items: payload}));
        builder.addCase(saveIncomeType.pending, (state) => ({...state, loading: true}));
        builder.addCase(saveIncomeType.fulfilled, (state, {payload}) => ({
            ...state,
            loading: false,
            items: spliceArray(state.items, payload, state.items.findIndex(v => v.id === payload.id))
        }));
    }
});

export default incomeTypes.reducer;
export {getIncomeTypes, saveIncomeType};