export type GridWidth = {
	xs: number;
	sm: number;
	md: number;
}

export const quarterWidth: GridWidth  = {
	xs: 12,
	sm: 5,
	md: 3
};

export const oneThirdWidth: GridWidth = {
	xs: 12,
	sm: 6,
	md: 4
};

export const halfWidthMinusOne: GridWidth = {
	xs: 12,
	sm: 12,
	md: 5
};

export const halfWidth: GridWidth = {
	xs: 12,
	sm: 6,
	md: 6
};

export const twoThirdWidthMinusOne: GridWidth = {
	xs: 12,
	sm: 12,
	md: 7
};

export const twoThirdWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 8
};

export const fullWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 12
};