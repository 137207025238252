import {useEntitlements} from "@frontegg/react";
import NoAccess from "../views/misc/NoAccess";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

interface Props {
  permissionLabel: string;
  element: ReactJSXElement;
}

const CheckAuth = (props:Props) => {
  const entitlements = useEntitlements({permissionKey: props.permissionLabel});

  return entitlements.isEntitled ? props.element : <NoAccess/>;
}

export default CheckAuth