import {useEffect} from "react";

// project imports
import useAuth from "hooks/useAuth";
import {GuardProps} from "types";
import agent from "api/agent";
import {useNavigate} from "react-router-dom";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const {user, isLoggedIn, login} = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isLoggedIn) 
            login();
    }, [isLoggedIn, login]);

    useEffect(() => {
        agent.setUser(user);

        if (user?.roles.find(r => !r.name.localeCompare("Client", undefined, {sensitivity: "base"})))
            navigate("/pages/forbidden");
    }, [user, navigate]);

    return isLoggedIn ? children : <></>;
};

export default AuthGuard;