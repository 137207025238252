import React, {FC, HTMLInputTypeAttribute, ReactNode} from "react";
import {Grid, TextField, TextFieldVariants} from "@mui/material";
import {GridWidth, halfWidth, Phone} from "library";
import styles from "styles/screeningResult.module.scss";
import {DateDisplay} from "library/formatters";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {PhoneInput} from "./PhoneInput";

const FormValueDisplay: FC<{ className?: string, label: JSX.Element | string, labelWidth?: GridWidth, value?: string | JSX.Element, valueWidth?: GridWidth }> = ({className, label, labelWidth, value, valueWidth}) => {
	return (
		<Grid container className={styles.formValue}>
			<Grid item {...(labelWidth ?? halfWidth)} className={styles.label} title={typeof (label) === "string" ? label : undefined}>{label}</Grid>
			<Grid item {...(valueWidth ?? halfWidth)} className={`${styles.value} ${className ?? ""} ${label === "string" ? styles.noOverflow : ""}`} title={typeof (value) === "string" ? value : ""}>{value}</Grid>
		</Grid>
	);
};

const Display: <T extends string | object>(props: {value: T | undefined, longDate?: boolean}) => ReactNode = ({value, longDate}) => {
	if (value instanceof Date)
		return longDate ? DateDisplay.Long(value) : DateDisplay.Standard(value);
	
	return value?.toString();
}

type EditProps<T> = {
	value: T | undefined;
	label?: string;
	type?: HTMLInputTypeAttribute;
	variant?: TextFieldVariants;
	disabled?: boolean;
	error?: string;
	onChange?: (value: any) => void,
	onBlur?: (value: any) => void
}

const Edit: <T extends string | number | object>(props: EditProps<T>) => ReactNode = ({value, label = "", type = "text", disabled, variant = "standard", error, onChange, onBlur}) => {
	if (value instanceof Date)
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker value={dayjs(value)}
							label={label}
							onChange={v => onChange?.(v?.format("YYYY-MM-DD"))}
							renderInput={p => <TextField {...p} disabled={disabled} error={!!error} helperText={error} variant={variant}/>}/>
			</LocalizationProvider>
		);
	
	if (value instanceof Phone)
		return <PhoneInput value={value} error={error} onChange={v => onChange?.(v)}/>;

	return <TextField variant={variant} label={label} type={type} fullWidth disabled={disabled} value={value} error={!!error} helperText={error}
					  onChange={e => onChange?.(e.target.value)}
					  onBlur={e => onBlur?.(e.target.value)}
	/>		                   
};

export {
	FormValueDisplay,
	Display,
	Edit
};