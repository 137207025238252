import styles from "styles/application.module.scss";

import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import React, {ReactNode} from "react";
import {BedroomCount, eligibleBedrooms} from "library";

const BedroomCountInput: (props: {value: BedroomCount | undefined, disabled?: boolean, onChange: (value: BedroomCount) => void}) => ReactNode = ({value, disabled, onChange}) => {
	return (
		<ToggleButtonGroup value={value} disabled={disabled} color={"primary"} fullWidth onChange={v => onChange((v.target as any).value)}>
			{eligibleBedrooms.map((k, i) => <ToggleButton key={i} className={styles.toggeleButton} value={k}>{i}</ToggleButton>)}
		</ToggleButtonGroup>
	);
};

export {
	BedroomCountInput
};