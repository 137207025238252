import {
    ApplicantInformation,
    Application,
    ApplicationContacts,
    ApplicationDocuments,
    Assignments,
    ClientStatus,
    getDateFrom,
    Household,
    Housing,
    IApplicantInformation,
    IApplicationContacts,
    IHousehold,
    IHousing,
    IPayment,
    IPointScore,
    ISituation,
    Journey,
    Note,
    Payment,
    PointScore,
    ProgramApproval,
    Situation
} from "library";

interface IClient extends IAuditDetail {
    id: string;
    code: string;
    hasAccount?: boolean;
    assignments: Assignments;
    applications: Application[],
    primaryContact: IApplicantInformation,
    housing?: IHousing,
    household?: IHousehold,
    situation?: ISituation;
    pointScore: IPointScore;
    programs: ProgramApproval[];
    documents: ApplicationDocuments;
    otherContacts: IApplicationContacts;
    status: ClientStatus;
    deactivatedReason?: string;
    payments: IPayment[];
    journey: Journey;
    notes: Note[];
    userId: string;
}

class Client implements IClient {
    readonly id: string;
    readonly code: string;
    readonly hasAccount: boolean;
    readonly assignments: Assignments;
    readonly applications: Application[];
    readonly primaryContact: ApplicantInformation;
    readonly housing: Housing;
    readonly household: Household;
    readonly situation: Situation;
    readonly pointScore: PointScore;
    readonly programs: ProgramApproval[];
    readonly documents: ApplicationDocuments;
    readonly otherContacts: ApplicationContacts;
    readonly status: ClientStatus;
    readonly deactivatedReason?: string;
    readonly payments: IPayment[];
    readonly journey: Journey;
    readonly notes: Note[];
    readonly createdOn: Date;
    readonly createdBy: string;
    readonly modifiedOn: Date;
    readonly modifiedBy: string;
    readonly userId: string;

    constructor(from?: IClient) {
        this.id = from?.id ?? crypto.randomUUID();
        this.code = from?.code ?? "";
        this.hasAccount = from?.hasAccount ?? false;
        this.assignments = new Assignments(from?.assignments);
        this.applications = from?.applications ?? [];
        this.programs = from?.programs ?? [];
        this.primaryContact = new ApplicantInformation(from?.primaryContact);
        this.housing = new Housing(from?.housing);
        this.household = new Household(from?.household);
        this.situation = new Situation(from?.situation);
        this.pointScore = new PointScore(from?.pointScore);
        this.documents = new ApplicationDocuments(from?.documents);
        this.otherContacts = new ApplicationContacts(from?.otherContacts);
        this.status = from?.status ?? ClientStatus.Active;
        this.deactivatedReason = from?.status === ClientStatus.Inactive ? from?.deactivatedReason : undefined;
        this.payments = from?.payments?.map(d => new Payment(d)) ?? [];
        this.journey = from?.journey ?? new Journey();
        this.notes = from?.notes ?? [];
        this.createdOn = new Date(from?.createdOn ?? new Date());
        this.createdBy = from?.createdBy ?? "";
        this.modifiedOn = new Date(from?.modifiedOn ?? new Date());
        this.modifiedBy = from?.modifiedBy ?? "";
        this.userId = from?.userId ?? "";
    }

    get incomeTotal() {
        return (this.primaryContact.income?.totalAnnual ?? 0) + this.household.incomeTotal;
    }

    get activeProgram() {
        const [firstActive] = this.programs.filter(p => !!p.isActive).sort((x, y) => getDateFrom(y.startDate).getDate() - getDateFrom(y.startDate).getDate());
        return firstActive;
    }

    get activeProgramNames() {
        return [...new Set(this.programs.filter(p => p.isActive).map(p => p.name))];
    }

    toJSON() {
        return {
            ...this,
            journey: undefined
        };
    }
}
 
export type {IClient};
export {Client};