import styles from "styles/application.module.scss";

import React, {ReactNode, useState} from "react";
import {PpulusLoader} from "./PpulusLoader";
import {IconCheck, IconCircleX, IconEye, IconEyeOff, IconPencil} from "@tabler/icons";
import {Button} from "@mui/material";

interface ActionProps {
    processing: boolean;
    editMode: boolean;
    showVisibility?: boolean;
    onEdit: () => void;
    onToggleView?: (show: boolean) => void;
    onCancel: () => void;
    onSave: () => void;
    onDelete?: () => void;
}

const Actions: (props: ActionProps) => ReactNode = ({processing, editMode, showVisibility, onEdit, onToggleView, onCancel, onSave, onDelete}) => {
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        onToggleView?.(!show);
        setShow(!show);
    }
    
    if (processing)
        return <PpulusLoader/>;

    if (!editMode)
        return (
            <>
                {showVisibility && (
                    !show ? <IconEye className={styles.edit} onClick={toggleShow}/> : <IconEyeOff className={styles.edit} onClick={toggleShow}/>
                )}
                <IconPencil className={styles.edit} onClick={onEdit}/>
            </>
        );
    
    return (
        <div className={`${styles.buttonGroup} ${styles.end}`}>
            <Button variant={"outlined"} className={styles.button} onClick={onCancel}><IconCircleX className={styles.iconButton}/>Cancel</Button>
            {onDelete && <Button variant={"outlined"} className={styles.button} onClick={onDelete}><IconCircleX className={styles.iconButton}/>Delete</Button>}
            <Button variant={"contained"} className={styles.button} color={"primary"} onClick={onSave}><IconCheck className={styles.iconButton}/>Save</Button>
        </div>
    );
};

const HouseholdMemberActions: (props: { processing: boolean, onAddAdult: () => void, onAddChild: () => void }) => ReactNode = ({processing, onAddAdult, onAddChild}) => {
    if (processing)
        return <PpulusLoader/>;

    return (
        <div className={`${styles.buttonGroup} ${styles.end}`}>
            <Button className={styles.button} variant={"outlined"} onClick={onAddAdult}>Add Adult</Button>
            <Button className={styles.button} variant={"outlined"} onClick={onAddChild}>Add Child</Button>
        </div>
    );
}

const ADULT = "Adult";
const DEPENDANT = "Dependant";

export {
    Actions,
    HouseholdMemberActions,
    ADULT,
    DEPENDANT
}