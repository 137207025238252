import styles from "styles/application.module.scss";

import {ApplicationStatus, ApplicationType, fullWidth} from "library";
import {Button, Grid} from "@mui/material";
import {IconBan, IconCheck, IconCircleX, IconFileCheck} from "@tabler/icons";
import React from "react";

type ApplicationActionsProps = {
    type: ApplicationType;
    status: ApplicationStatus;
    canProcess: boolean;
    onStatusChange: (newStatus: ApplicationStatus) => void;
}

const SpecialActions = (status: ApplicationStatus, onStatusChange: (newStatus: ApplicationStatus) => void): Partial<Record<ApplicationType, JSX.Element>> => ({
    [ApplicationType.Standard]: <StandardActions status={status} onStatusChange={onStatusChange}/>,
    [ApplicationType.InterimReview]: <InterimReviewActions status={status} onStatusChange={onStatusChange}/>,
    [ApplicationType.AnnualReview]: <AnnualReviewActions status={status} onStatusChange={onStatusChange}/>
});

const ApplicationActions = ({type, status, canProcess, onStatusChange}: ApplicationActionsProps) => {
    return (
        <Grid item {...fullWidth} className={`${styles.buttonGroup} ${styles.rowTopPadding}`}>
            <div className={styles.left}>
                <Button color={"error"} variant={"contained"} startIcon={<IconBan/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Rejected)}>Reject</Button>
                <Button color={"warning"} variant={"contained"} startIcon={<IconCircleX/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Cancelled)}>Cancel</Button>
            </div>
            {status !== ApplicationStatus.Approved && canProcess && SpecialActions(status, onStatusChange)![type]}
        </Grid>
    );
};

const StandardActions = ({status, onStatusChange}: { status: ApplicationStatus, onStatusChange: (newStatus: ApplicationStatus) => void }) => {
    return (
        <div className={styles.right}>
            {[ApplicationStatus.Draft].includes(status) &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Submitted)}>
                    Submit
                </Button>
            }
            {[ApplicationStatus.Submitted, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.PendingApproval, ApplicationStatus.Eligible].includes(status) &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconFileCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.WaitingForSupportingDocuments)}>
                    Request Documents
                </Button>}
            {[ApplicationStatus.Submitted, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.PendingApproval].includes(status) &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Eligible)}>
                    Eligible
                </Button>}
            {status === ApplicationStatus.Eligible &&
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck/>} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Approved)}>
                    Approve
                </Button>}
        </div>
    );
}

const InterimReviewActions = ({status, onStatusChange}: { status: ApplicationStatus, onStatusChange: (newStatus: ApplicationStatus) => void }) => {
    const isInactive = [ApplicationStatus.Cancelled, ApplicationStatus.Rejected, ApplicationStatus.Approved].includes(status);
    
    return (
        <div className={styles.right}>
            {!isInactive && <>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.WaitingForSupportingDocuments)}>
                    Information Request
                </Button>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Approved)}>
                    Approve
                </Button>
            </>}
        </div>
    );
}

const AnnualReviewActions = ({status, onStatusChange}: { status: ApplicationStatus, onStatusChange: (newStatus: ApplicationStatus) => void }) => {
    const isInactive = [ApplicationStatus.Cancelled, ApplicationStatus.Rejected, ApplicationStatus.Approved].includes(status);
    
    return (
        <div className={styles.right}>
            {!isInactive && <>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.WaitingForSupportingDocuments)}>
                    Information Request
                </Button>
                <Button color={"primary"} variant={"contained"} startIcon={<IconCheck />} className={styles.button} onClick={() => onStatusChange(ApplicationStatus.Approved)}>
                    Approve
                </Button>
            </>}
        </div>
    );
}

export {
    ApplicationActions
}