import {createSlice} from "@reduxjs/toolkit";
import {SentEmail} from "library";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";

const getEmails = createAppAsyncThunk("clientEmails/get", async (client: string, {getState}) => {
	const {clientEmails: {clientCode, items}} = getState();

	if (clientCode === client)
		return items;

	return await agent.Clients.getEmails(client);
});


const clientEmails = createSlice({
	name: "clientEmails",
	initialState: {loading: false, clientCode: "", items: new Array<SentEmail>()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getEmails.pending, () => ({loading: true, clientCode: "", items: []}));
		builder.addCase(getEmails.fulfilled, (_, {payload, meta: {arg}}) => ({loading: false, clientCode: arg, items: payload}));
	}
});

export default clientEmails.reducer;

export {getEmails};