import {CitizenStatus, Gender, PhoneNumberType, RelationToApplicant, StudentStatus} from "../enums";
import {Address, Email, Phone} from "../types";
import {createErrorState, ErrorState, IErrorState, isValidSin} from "../common";
import {IncomeAssets} from "./incomeAssets";
import {getDateFrom, IncomeType} from "library";

interface IPerson {
	relationToApplicant: RelationToApplicant;
	isDependant: boolean;
	firstName: string;
	middleName: string;
	lastName: string;
	preferredName: string;
	gender?: Gender;
	socialInsuranceNumber?: string;
	citizenship?: CitizenStatus;
	dateOfBirth?: Date;
	address?: Address;
	studentStatus?: StudentStatus;
	income?: IncomeAssets;
	addressSameAsApplicant?: boolean;
	mailingAddressSameAsAddress?: boolean;
	isIncomeEligible: boolean;
}

class Person implements IPerson, IErrorState<Person> {
	[key: string]: any;

	readonly isDependant: boolean = false;
	readonly relationToApplicant: RelationToApplicant;
	readonly firstName: string = "";
	readonly middleName: string = "";
	readonly lastName: string = "";
	readonly preferredName: string = "";
	readonly gender?: Gender;
	readonly socialInsuranceNumber?: string;
	readonly citizenship?: CitizenStatus;
	readonly dateOfBirth?: Date;
	readonly address?: Address;
	readonly studentStatus?: StudentStatus;
	readonly income: IncomeAssets;
	readonly validated: boolean;
	readonly addressSameAsApplicant: boolean;
	readonly mailingAddressSameAsAddress: boolean;
	readonly isIncomeEligible: boolean;

	constructor(from?: Partial<IPerson>, incomeTypes?: IncomeType[]) {
		this.isDependant = from?.isDependant ?? false;
		this.relationToApplicant = from?.relationToApplicant ?? RelationToApplicant.other;
		this.firstName = from?.firstName ?? "";
		this.middleName = from?.middleName ?? "";
		this.lastName = from?.lastName ?? "";
		this.preferredName = from?.preferredName ?? "";
		this.gender = from?.gender;
		this.socialInsuranceNumber = from?.socialInsuranceNumber;
		this.citizenship = from?.citizenship;
		this.dateOfBirth = getDateFrom(from?.dateOfBirth);
		this.address = from?.address ? new Address(from?.address) : undefined;
		this.studentStatus = (from?.studentStatus ?? "") in StudentStatus || Object.values(StudentStatus).some(v => v === from?.studentStatus) ? from?.studentStatus : StudentStatus.notStudent;
		this.income = new IncomeAssets(from?.income, incomeTypes);
		this.addressSameAsApplicant = from?.addressSameAsApplicant ?? false;
		this.mailingAddressSameAsAddress = from?.mailingAddressSameAsAddress ?? false;
		this.isIncomeEligible = from?.isIncomeEligible ?? false;
		this.validated = (from as Person)?.validated ?? false;
	}

	get age() {
		if (!this.dateOfBirth)
			return undefined;
		
		const TICKS_IN_HOUR = 60 * 60 * 1000;
		const TICKS_IN_DAY = TICKS_IN_HOUR * 24;
		const TICKS_IN_YEAR = TICKS_IN_DAY * 365.25; //.25 for leap year

		const timeSinceBirth = Math.abs(Date.now() - this.dateOfBirth.getTime());
		return Math.floor(timeSinceBirth / TICKS_IN_YEAR);
	}
	
	validate() {
		return new Person({...this, validated: true});
	}
	
	get errorState() {
		return this.validated ?
			createErrorState<Person>({
				firstName: !this.firstName ? "First name required." : "",
				lastName: !this.lastName ? "Last name required." : ""
			}) : {};
	}

	get initials() {
		const [first] = this.firstName;
		const [last] = this.lastName;
		
		return `${first}${last}`.toUpperCase();
	}
	
	get displayName() {
		if (!!this.preferredName)
			return `${this.preferredName} (${this.firstName} ${this.lastName})`;
		
		return `${this.firstName} ${this.lastName}`;
	}

	get isComplete() {
		return this.firstName && this.lastName;
	}
}

interface IApplicantInformation extends IPerson {
	address?: Address;
	mailingAddress?: Address;
	citizenship: CitizenStatus;
	socialInsuranceNumber?: string;
	primaryPhone: Phone;
	secondaryPhone?: Phone;
	email: Email;
	income: IncomeAssets;
}

class ApplicantInformation extends Person implements IApplicantInformation {
	[key: string]: any;
	
	readonly address?: Address;
	readonly mailingAddress?: Address;
	readonly citizenship: CitizenStatus;
	readonly socialInsuranceNumber?: string;
	readonly primaryPhone: Phone;
	readonly secondaryPhone?: Phone;
	readonly email: Email;
	readonly income: IncomeAssets;

	constructor(from?: IApplicantInformation, incomeTypes?: IncomeType[]) {
		super(from);
		
		this.address = from?.address;
		this.mailingAddress = from?.mailingAddress ? new Address(from?.mailingAddress) : undefined;
		this.citizenship = from?.citizenship ?? CitizenStatus.Other;
		this.socialInsuranceNumber = from?.socialInsuranceNumber;
		this.email = from?.email ?? {address: ""};
		this.primaryPhone = from?.primaryPhone ?? {type: PhoneNumberType.mobile, number: ""};
		this.secondaryPhone = from?.secondaryPhone ?? {type: PhoneNumberType.home, number: ""};
		this.income = new IncomeAssets(from?.income, incomeTypes);
	}

	private getPhone(type: PhoneNumberType) {
		return this.applicant?.primaryPhone.type === type
			? this.applicant.primaryPhone
			: this.applicant?.secondaryPhone?.type === type
				? this.applicant.secondaryPhone
				: undefined;
	}

	get mobilePhone() {
		return this.getPhone(PhoneNumberType.mobile);
	}

	get homePhone() {
		return this.getPhone(PhoneNumberType.home);
	}

	get mailAddressUnique() {
		return new Address(this.mailingAddress).toString() !== new Address(this.address).toString();
	}

	validate() {
		return new ApplicantInformation({...this, validated: true});
	}

	get errorState(): ErrorState<Partial<ApplicantInformation>> {
		return this.validated ? createErrorState<ApplicantInformation>({
			firstName: !this.firstName ? "First name required." : "",
			lastName: !this.lastName ? "Last name required." : "",
			email: !this.email.address ? "Email required." : "",
			socialInsuranceNumber: !this.socialInsuranceNumber 
				? "SIN# required."
				: !isValidSin(this.socialInsuranceNumber) ? "Invalid SIN#." : ""
		}) : {};
	}
}

export type {IPerson, IApplicantInformation};
export {Person, ApplicantInformation};