import styles from "styles/disbursements.module.scss";

import React, {ReactNode} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {PeriodPicker} from "components";
import {Period} from "library";
import {useSelector} from "store";
import {Program} from "store/slices/programs";

type DisbursementOptions = {
    program: Program;
    period: Period;
}

const DisbursementOptionForm: (props: { value: DisbursementOptions, onChange: (options: Partial<DisbursementOptions>) => void }) => ReactNode = ({value, onChange}) => {
    const programs = useSelector(s => s.programs);

    return (
        <div className={`${styles.section} ${styles.optionsGrid}`}>
            <FormControl>
                <InputLabel id={"program-label"}>Program</InputLabel>
                <Select label={"program-label"} value={value.program.shortName} onChange={v => onChange({program: programs.programs.find(p => p.shortName === v.target.value)})}>
                    {programs.programs.map(program => <MenuItem key={program.shortName} value={program.shortName}>{program.longName}</MenuItem>)}
                </Select>
            </FormControl>
            <PeriodPicker period={value.period} onChange={period => onChange({period})}/>
        </div>
    );
};

export {
    DisbursementOptionForm
}
export type {
    DisbursementOptions
}