import styles from "styles/miscStyles.module.scss";

import {memo} from 'react';

// material-ui
import {styled, useTheme} from '@mui/material/styles';
import {Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, Typography,} from '@mui/material';

// assets
import {HelpCenterOutlined} from "@mui/icons-material";

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

const MenuCard = () => {
    const theme = useTheme();

    return (
        <CardStyle>
            <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
                    <a href="https://ppulustraining.z9.web.core.windows.net/" target="_newtrainingwindow" className={styles.ppulusHelp}>
                        <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                            <ListItemAvatar sx={{ mt: 0 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.largeAvatar,
                                        color: theme.palette.primary.main,
                                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                        borderColor: theme.palette.primary.main,
                                        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : '#fff',
                                        marginRight: '12px'
                                    }}
                                >
                                    <HelpCenterOutlined fontSize="inherit" />

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ mt: 0 }}
                                primary={
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800] }}
                                    >
                                        ppulus Help
                                    </Typography>
                                }
                                secondary={<Typography variant="caption">Open Training / Help</Typography>}
                            />
                        </ListItem>
                    </a>
                </List>
            </CardContent>
        </CardStyle>
    );
};

export default memo(MenuCard);
