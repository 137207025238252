import styles from "styles/application.module.scss";

import { Button, Dialog, DialogActions, DialogTitle, Grid, Typography } from "@mui/material";
import { Display, Edit, FormValueDisplay } from "components/FormValueDisplay";
import { EmergencyContact, IApplicationContacts, Phone, fullWidth, halfWidth, hasError, quarterWidth, twoThirdWidth } from "library";
import { useEffect, useMemo, useState } from "react";
import { Actions } from "./Actions";
import { PpulusLoader } from "./PpulusLoader";

type EmergencyContactsProps = {
    value: IApplicationContacts;
    readonly?: boolean;
    onChange: (value: any) => Promise<void>;
}

const EmergencyContacts = ({ value, readonly, onChange }: EmergencyContactsProps) => {
    const emergencyContacts = useMemo(() => [new EmergencyContact(value.emergencyContactOne), new EmergencyContact(value.emergencyContactTwo)], [value.emergencyContactOne, value.emergencyContactTwo]);
    const [processing, setProcessing] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editValue, setEditValue] = useState(emergencyContacts);
    const [dialog, setDialog] = useState<boolean>();

    useEffect(() => {
        setEditValue(emergencyContacts);
    }, [emergencyContacts]);

    const set = (v: Partial<EmergencyContact>, index: number) => {
        setEditValue(current => current.map((c, i) => i === index ? new EmergencyContact({ ...c, ...v }) : c));
    }

    const onInnerCancel = () => {
        setEditValue(emergencyContacts);
        setEditMode(false);
    };

    const onSave = async () => {
        const validated = editValue.map(e => e.validate());
        setEditValue(validated);
        if (validated.some(v => hasError(v)) || !onChange) return;

        setProcessing(true);
        const updatedContacts = {...value, emergencyContactOne: validated[0], emergencyContactTwo: validated[1]};
        onChange(updatedContacts)
            .then(() => setEditMode(false))
            .finally(() => setProcessing(false));
    };

    const deleteContact = () => {
        setDialog(true);
    };

    const onClose = () => setDialog(false);

	const onAccept = async () => {
		setProcessing(true);
		onChange([])
			.finally(() => {
				setProcessing(false);
                setEditMode(false);
				onClose();
			});
	};

    return (
        <Grid container className={styles.card}>
            <Grid item {...fullWidth} className={styles.title}>
                <Typography variant={"h3"} className={styles.memberLabel}>
                    Emergency Contact
                    {!readonly && <Actions processing={processing}
                        editMode={editMode}
                        onEdit={() => setEditMode(true)}
                        onSave={onSave}
                        onDelete={emergencyContacts.some(e => e.name) ? deleteContact : undefined}
                        onCancel={onInnerCancel} />}
                </Typography>
            </Grid>

            {emergencyContacts.map((contact, index) =>
                <EmergencyContactForm key={index} index={index} value={contact} editValue={editValue[index]} editMode={editMode} processing={processing} onChange={v => set(v, index)} />
            )}

            <Dialog
                open={!!dialog}
                onClose={onClose}>
                <DialogTitle>{"Are you sure you want to remove the emergecy contacts?"} {processing && <PpulusLoader />}</DialogTitle>
                {!processing && <DialogActions>
                    <Button onClick={onClose}>No (Cancel)</Button>
                    <Button onClick={onAccept}>Yes</Button>
                </DialogActions>}
            </Dialog>
        </Grid>
    );
};

type EmergencyContactProps = {
    index: number;
    value: EmergencyContact;
    editValue: EmergencyContact;
    editMode?: boolean;
    processing?: boolean;
    onChange: (value: Partial<EmergencyContact>) => void;
}

const EmergencyContactForm = ({ index, value, editValue, editMode, processing, onChange }: EmergencyContactProps) => {
    return (
        <Grid item {...fullWidth} className={styles.emergencyContact}>
            <Grid item {...fullWidth} className={`${styles.column} ${styles.bold}`}>
                <div>Emergency Contact #{index + 1}</div>
            </Grid>
            <Grid container>
                <Grid item {...quarterWidth} className={styles.column}>
                    <FormValueDisplay {...quarterWidth} label={"Name"}
                        value={!editMode
                            ? <Display value={value.name} />
                            : <Edit value={editValue.name} disabled={processing} error={editValue.errorState.name} onChange={v => onChange({ name: v })} />}
                    />
                </Grid>
                <Grid item {...quarterWidth} className={styles.column}>
                    <FormValueDisplay {...quarterWidth} label={"Relationship"}
                        value={!editMode
                            ? <Display value={value.relationship} />
                            : <Edit value={editValue.relationship} disabled={processing} error={editValue.errorState.relationship} onChange={v => onChange({ relationship: v })} />}
                    />
                </Grid>
                <Grid item {...halfWidth} className={styles.column}>
                    <FormValueDisplay label={"Phone Number"} labelWidth={quarterWidth} valueWidth={twoThirdWidth}
                        value={!editMode
                            ? <Display value={value.phone?.number} />
                            : <Edit value={new Phone(editValue.phone)} disabled={processing} error={editValue.errorState.phone} onChange={v => onChange({ phone: v })} />}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export {
    EmergencyContacts
};
