// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {IconAdjustments, IconBallpen, IconBug, IconFriends, IconHome2, IconKey, IconReportAnalytics} from '@tabler/icons';

import {NavItemType} from 'types';
import {PpulusRoutes} from 'library';

// constant
const icons = {
  IconKey,
  IconBug,
  IconAdjustments,
  IconHome2,
  IconReportAnalytics,
  IconBallpen,
  IconFriends
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const administration: NavItemType = {
  id: 'administration',
  type: 'group',
  children: [
    {
      id: 'administration',
      title: <FormattedMessage id="administration" />,
      type: 'collapse',
      icon: icons.IconAdjustments,
      children: [
        {
          id: 'general-administration',
          title: <FormattedMessage id="general-administration"/>,
          type: 'collapse',
          children: [
            {
              id: "configuration",
              title: <FormattedMessage id={"configuration"}/>,
              type: "item",
              url: "/pages/admin/config",
              target: false
            },
            {
              id: 'announcements',
              title: <FormattedMessage id="announcements" />,
              type: 'item',
              url: '/pages/admin/announcements',
              target: false
            }
          ],
        },
        {
          id: 'rate-tables',
          title: <FormattedMessage id="rate-tables"/>,
          type: 'collapse',
          children: [
            {
              id: 'rab-subsidy-rates',
              title: <FormattedMessage id="rab-subsidy-rates" />,
              type: 'item',
              url: '/pages/rab-rates',
              target: false
            },
            {
              id: 'trab-subsidy-rates',
              title: <FormattedMessage id="trab-subsidy-rates" />,
              type: 'item',
              url: '/pages/trab-rates',
              target: false
            },
            {
              id: 'core-shelter-rates',
              title: <FormattedMessage id={"core-shelter-rates"}/>,
              type: "item",
              url: "/pages/core-shelter-rates",
              target: false
            },
            {
              id: "cnits-rates",
              title: <FormattedMessage id={"cnits-rates"}/>,
              type: "item",
              url: "/pages/cnits-rates",
              target: false
            }
          ]
        },
        {
          id: 'type-tables',
          title: <FormattedMessage id="type-tables"/>,
          type: 'collapse',
          children: [
            {
              id: 'income-types',
              title: <FormattedMessage id="income-types" />,
              type: 'item',
              url: '/pages/incomeTypes',
              target: false
            },
            {
              id: 'deactivation-reasons',
              title: <FormattedMessage id={"deactivation-reasons"}/>,
              type: 'item',
              url: '/pages/admin/deactivationReasons',
              target: false
            }
          ]
        },
        {
          id: 'financials',
          title: <FormattedMessage id="financials"/>,
          type: 'collapse',
          children: [
            {
              id: 'program-budgets',
              title: <FormattedMessage id="program-budgets" />,
              type: 'item',
              url: PpulusRoutes.Financials.ProgramBudgets,
              target: false
            },
            {
              id: 'payfile-exceptions',
              title: <FormattedMessage id="payfile-exceptions" />,
              type: 'item',
              url: '/pages/under-construction',
              target: false
            }
          ]
        }
      ]
    }
  ]
};

export default administration;
