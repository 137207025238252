import {Phone} from "../library";
import React, {ReactNode, useState} from "react";
import {matchIsValidTel, MuiTelInput, MuiTelInputInfo} from "mui-tel-input";
import {TextFieldVariants} from "@mui/material";

type PhoneInputProps = {
	value: Phone, error: string | undefined;
	variant?: TextFieldVariants;
	onChange: (v: Phone | undefined) => void;
}

const PhoneInput: (props: PhoneInputProps) => ReactNode = ({value, error, variant = "standard", onChange}) => {
	const [phone, setPhone] = useState<Partial<MuiTelInputInfo> | undefined>({numberValue: value.number ?? null});
	const [internalError, setError] = useState<string>();
	
	return (
		<MuiTelInput value={phone?.numberValue ?? ""}
		             forceCallingCode
		             preferredCountries={["CA"]}
					 variant={variant}
		             defaultCountry={"CA"}
		             fullWidth
		             error={!!internalError || !!error} helperText={internalError ?? error}
					 onChange={(v, d) => setPhone(d)}
		             onBlur={() => {
						 const newValue = phone?.numberValue ? {...value, number: phone.nationalNumber ?? ""} : undefined;
						 if (!newValue) setPhone(undefined);
						 onChange(newValue);
						 setError(!phone?.nationalNumber || matchIsValidTel(phone.numberValue ?? "", "CA") ? "" : "Invalid phone number");
		             }
		} />	
	);
};

export {
	PhoneInput
};