import {RateRule} from "./rates";
import {RateStatus} from "../enums";
import {getDateFrom} from "../dates";

class CoreNeedsThresholdRate extends RateRule {
    readonly name: string;
    readonly municipality: string;
    readonly effectiveStart: Date;
    readonly effectiveEnd: Date;
    readonly bedrooms?: number;
    readonly maximumAnnualIncome?: number;
    readonly maximumMonthlyIncome?: number;
    readonly status: RateStatus;
    readonly notes: string;

    constructor(from?: Partial<CoreNeedsThresholdRate>) {
        super("CNITS", from?.id);

        this.name = from?.name ?? "";
        this.municipality = from?.municipality ?? "";
        this.effectiveStart = getDateFrom(from?.effectiveStart);
        this.effectiveEnd = getDateFrom(from?.effectiveEnd);
        this.bedrooms = from?.bedrooms;
        this.maximumAnnualIncome = from?.maximumAnnualIncome;
        this.maximumMonthlyIncome = from?.maximumMonthlyIncome;
        this.status = from?.status ?? RateStatus.Inactive;
        this.notes = from?.notes ?? "";
    }
}

export {CoreNeedsThresholdRate};