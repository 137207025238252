import {FileInfo} from ".";

class Note {
	public readonly id?: string;
	public readonly createdOn: Date;
	public readonly createdBy?: string;
	public readonly modifiedOn: Date;
	public readonly modifiedBy?: string;
	public readonly internal: boolean;
	public readonly isPriority: boolean;
	public readonly subject: string;
	public readonly message: string;
	public readonly files: FileInfo[];
	private readonly validated: boolean = false;

	constructor(from?: Partial<Note>) {
		this.id = from?.id;
		this.createdOn = from?.createdOn ? new Date(from.createdOn) : new Date();
		this.createdBy = from?.createdBy;
		this.modifiedOn = from?.modifiedOn ? new Date(from.modifiedOn) : new Date();
		this.modifiedBy = from?.modifiedBy;
		this.internal = from?.internal ?? true;
		this.isPriority = from?.isPriority ?? false;
		this.subject = from?.subject ?? "";
		this.message = from?.message ?? "";
		this.files = from?.files ?? [];

		this.validated = (from as Note)?.validated;
	}
	
	validate() {
		return new Note({...this, validated: true});
	}
	
	get errorState() {
		return this.validated ? {
			subject: !this.subject ? "Enter a subject" : "",
			note: !this.message ? "Enter a note value" : ""
		} : {};
	}
}

export {
	Note
};