import {RateRule} from "./rates";
import {RateStatus} from "../enums";
import {getDateFrom} from "../dates";

class TrabRateRule extends RateRule {
    readonly name: string;
    readonly municipality: string;
    readonly effectiveStart: Date;
    readonly effectiveEnd: Date;
    readonly bedrooms?: number;
    readonly year1Amount?: number;
    readonly year2Amount?: number;
    readonly status: RateStatus;
    readonly notes: string;

    constructor(from?: Partial<TrabRateRule>) {
        super("TRAB", from?.id);

        this.name = from?.name ?? "";
        this.municipality = from?.municipality ?? "";
        this.effectiveStart = getDateFrom(from?.effectiveStart);
        this.effectiveEnd = getDateFrom(from?.effectiveEnd);
        this.bedrooms = from?.bedrooms;
        this.year1Amount = from?.year1Amount;
        this.year2Amount = from?.year2Amount;
        this.status = from?.status ?? RateStatus.Inactive;
        this.notes = from?.notes ?? "";
    }
}

export {TrabRateRule};