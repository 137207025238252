import styles from "styles/gridTable.module.scss";
import countStyles from "styles/counts.module.scss";

import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {addDays, Application, ApplicationContent, ApplicationStatus, ApplicationType, DateDisplay, halfWidth} from "library";
import {DataGrid, GridColDef, GridFilterItem} from "@mui/x-data-grid";
import {AnnualReviewRow} from "./AnnualReview/Index";
import {useDispatch, useSelector} from "store";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import agent from "api/agent";
import {CountDisplay} from "components/CountDisplay";
import {getStaff} from "store/slices";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";

class ApiResults {
	readonly loading: boolean;
	readonly results: Application[];
	readonly total: number;
	readonly rows: (AnnualReviewRow | undefined)[];

	constructor(from?: Partial<ApiResults>) {
		this.loading = from?.loading ?? true;
		this.results = from?.results ?? [];
		this.total = from?.total ?? this.results.length;
		this.rows = this.results.map(AnnualReviewRow.From);
	}
}

type Counts = {
	dueSoon?: number,
	submitted?: number,
	draft?: number,
	generated?: number,
	pastDue?: number
};

const AnnualReviewDashboard = () => {
	const navigate = useNavigate();
	const [assignee, setAssignee] = useState("");
	const [counts, setCounts] = useState<Counts>({});

	const viewRowItem = (id: string) => navigate(`/pages/${ApplicationContent["AnnualReview"].path}/${id}`);

	const countChanged = (value: Partial<Counts>) => setCounts(c => ({...c, ...value}));

	return (<>
		<AnnualReviewStats onAssignmentChange={setAssignee} counts={counts}/>
		<Grid container columnSpacing={4} rowSpacing={4}>
			<HalfTable title={"Past Due"} assignee={assignee}
					   filter={[
						   {columnField: "dueDate", value: DateDisplay.Standard(new Date()), operatorValue: "before"},
						   {
							   columnField: "status",
							   value: [ApplicationStatus.Generated, ApplicationStatus.Draft, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.Submitted, ApplicationStatus.PendingApproval].join(","),
							   operatorValue: "isAnyOf"
						   }
					   ]} viewRowItem={viewRowItem}
					   onCountChanged={pastDue => countChanged({pastDue})}/>
			<HalfTable title={"Due within 30 days"} assignee={assignee}
					   filter={[
						   {columnField: "dueDate", value: DateDisplay.Standard(addDays(new Date(), 30)), operatorValue: "onOrBefore"},
						   {
							   columnField: "status",
							   value: [ApplicationStatus.Generated, ApplicationStatus.Draft, ApplicationStatus.WaitingForSupportingDocuments, ApplicationStatus.Submitted, ApplicationStatus.PendingApproval].join(","),
							   operatorValue: "isAnyOf"
						   }
					   ]}
					   viewRowItem={viewRowItem}
					   onCountChanged={dueSoon => countChanged({dueSoon})}/>
			<HalfTable title={"Submitted"} assignee={assignee} filter={[{columnField: "status", value: ApplicationStatus.Submitted, operatorValue: "equals"}]} viewRowItem={viewRowItem}
					   onCountChanged={submitted => countChanged({submitted})}/>
			<HalfTable title={"Draft"} assignee={assignee} filter={[{columnField: "status", value: ApplicationStatus.Draft, operatorValue: "equals"}]} viewRowItem={viewRowItem}
					   onCountChanged={draft => countChanged({draft})}/>
			<HalfTable title={"Generated"} assignee={assignee} filter={[{columnField: "status", value: ApplicationStatus.Generated, operatorValue: "equals"}]} viewRowItem={viewRowItem}
					   onCountChanged={generated => countChanged({generated})}/>
		</Grid>
	</>);
};

const AnnualReviewStats = ({counts, onAssignmentChange}: { counts: Counts, onAssignmentChange: (assignee: string) => void }) => {
	const dispatch = useDispatch();
	const {items: staff} = useSelector(s => s.staff);
	const [staffMember, setStaffMember] = useState("");

	useEffect(() => {
		if (staff.length) return;
		dispatch(getStaff());
	}, [dispatch, staff.length]);

	useEffect(() => {
		onAssignmentChange(staffMember);
	}, [staffMember, onAssignmentChange]);

	const getDisplay = (value: number | undefined) => value !== undefined ? `${value}` : "-";

	return (
		<div className={`${styles.section} ${styles.optionsGrid}`}>
			<div style={{width: "500px"}}>
				<FormControl fullWidth>
					<InputLabel id={"assignment-label"}>Assigned To</InputLabel>
					<Select fullWidth label={"assignment-label"} value={staffMember} onChange={v => setStaffMember(v.target.value)}>
						{staff.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
					</Select>
				</FormControl>
				{!!staffMember && <Button variant={"text"} onClick={() => setStaffMember("")}>Clear</Button>}
			</div>
			<div className={countStyles.totals}>
				<CountDisplay value={getDisplay(counts.pastDue)} label={"Past Due"}/>
				<CountDisplay value={getDisplay(counts.dueSoon)} label={"Due within 30 Days"}/>
				<CountDisplay value={getDisplay(counts.submitted)} label={"Submitted"}/>
				<CountDisplay value={getDisplay(counts.draft)} label={"Draft"}/>
				<CountDisplay value={getDisplay(counts.generated)} label={"Generated"}/>
			</div>
		</div>
	);
};

const HalfTable = ({title, assignee, filter, onCountChanged, viewRowItem}: { title: string, assignee: string, filter: GridFilterItem[], onCountChanged: (count: number) => void, viewRowItem: (id: string) => void }) => {
	const [values, setValues] = useState(new ApiResults());
	const [currentPage, setCurrentPage] = useState(0);

	const columns: GridColDef[] = [
		...Object.values(AnnualReviewRow.SimpleColumns),
	];

	useEffect(() => {
		const abort = new AbortController();
		setValues(new ApiResults());
		agent.Applications.list({
			page: currentPage,
			pageSize: 5,
			filter: {
				items: [
					{columnField: "type", value: ApplicationType.AnnualReview, operatorValue: "equals"},
					...(assignee ? [{columnField: "assignments", value: assignee, operatorValue: "contains"}] : []),
					...filter
				]
			}
		}, false, abort.signal)
			.then(r => {
				setValues(new ApiResults({results: r.applications, total: r.count, loading: false}));
				onCountChanged(r.count);
			})
			.catch(() => setValues(new ApiResults()));

		return () => abort.abort("Assignee or Page changed");
		// eslint-disable-next-line 
	}, [assignee, currentPage]);

	const initialColumnState: GridInitialStateCommunity = {
		columns: {
			columnVisibilityModel: {
				primaryAssignee: false,
				secondaryAssignee: false
			}
		}
	};

	return (
		<Grid item {...halfWidth}>
			<Typography variant={"h3"} style={{backgroundColor: "white", padding: "1rem"}}>{title}</Typography>
			<DataGrid style={{height: "405px"}}
					  disableColumnFilter disableColumnMenu
					  columns={columns}
					  initialState={initialColumnState}
					  rows={values.rows.filter(r => !!r)} rowCount={values.total}
					  loading={values.loading}
					  getRowClassName={() => styles.row}
					  onRowDoubleClick={row => viewRowItem(`${row.id}`)}
					  paginationMode={"server"} pageSize={5} page={currentPage} onPageChange={setCurrentPage}
					  sortingMode={"server"}
			/>
		</Grid>
	);
};

export {
	AnnualReviewDashboard
};