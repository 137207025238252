// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import {FronteggAuthProvider as AuthProvider} from 'contexts/FronteggContext';
import {store} from "./store";
import {BASE_PATH} from "./config";
import React from "react";
import {ConfigProvider} from "./contexts/ConfigContext";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

// ==============================|| APP ||============================== //
const App = () => (
    <Provider store={store}>
        <ConfigProvider>
            <BrowserRouter basename={BASE_PATH}>
                <ThemeCustomization>
                    <Locales>
                        <NavigationScroll>
                            <AuthProvider>
                                <Routes/>
                                <Snackbar/>
                            </AuthProvider>
                        </NavigationScroll>
                    </Locales>
                </ThemeCustomization>
            </BrowserRouter>
        </ConfigProvider>
    </Provider>
);

export default App;