import styles from "styles/application.module.scss";

import { Grid, Typography } from "@mui/material";
import { ApplicantInformation, Household, Person } from "library";
import { ReactNode, useState } from "react";
import { ADULT, DEPENDANT, HouseholdMemberActions } from "./Actions";
import { MemberDetail } from "./MemberDetail";

interface IHouseholdMembersProps {
    value: Household;
    readonly?: boolean;
    onChange: (value: Household) => Promise<void>;
}

const HouseholdMembers: (props: IHouseholdMembersProps) => ReactNode = ({value, readonly, onChange}) => {
    const [processing, setProcessing] = useState(false);
    const [newMember, setNewMember] = useState<Person>();

    const onAddNew = async (member: Person | ApplicantInformation) => {
        if (!onChange) return;
        
        setProcessing(true);
        await onChange(new Household({
            dependants: member.isDependant ? [...value.dependants, member] : value.dependants,
            adults: !member.isDependant ? [...value.adults, member] : value.adults
        }))
            .finally(() => {
                setNewMember(undefined);
                setProcessing(false);
            });
    };

    const onUpdateMember = async (member: Person | ApplicantInformation, index: number) => {
        if (!onChange) return;
        
        setProcessing(true);
        await onChange(new Household({
            dependants: member.isDependant ? value.dependants.map((d, i) => i !== index ? d : member) : value.dependants,
            adults: !member.isDependant ? value.adults.map((a, i) => i !== index ? a : member) : value.adults
        }))
            .finally(() => setProcessing(false));
    };

    const onDelete = async (member: Person | ApplicantInformation, index: number) => {
        if (!onDelete) return;

        setProcessing(true);

        await onChange(new Household({
            dependants: member.isDependant ? value.dependants.filter((d, i) => i !== index) : value.dependants,
            adults: !member.isDependant ? value.adults.filter((a, i) => i !== index) : value.adults
        }))
            .finally(() => setProcessing(false));
    }

    return (
        <>
            <Grid container>
                <div className={styles.householdTitle}>
                    <Typography variant={"h3"} className={styles.title}>
                        <span className={styles.fullWidth}>Household Members</span>
                        {!readonly && <HouseholdMemberActions processing={processing} onAddAdult={() => setNewMember(new Person({isDependant: false}))} onAddChild={() => setNewMember(new Person({isDependant: true}))}/>}
                    </Typography>
                </div>
            </Grid>

            <Grid container>
                {newMember &&
                    <div className={`${styles.card} ${styles.fullWidth} ${styles.memberDetailContainer}`}>
                        <MemberDetail label={!newMember.isDependant ? ADULT : DEPENDANT} value={newMember} editing onCancel={() => setNewMember(undefined)} onChange={onAddNew}/>
                    </div>
                }

                {(!!value.adults.length || !!value.dependants.length) && <>
                    {value.adults.map((adult, index) => (
                        <div key={index} className={`${styles.card} ${styles.fullWidth} ${styles.memberDetailContainer}`}>
                            <MemberDetail key={index} label={ADULT} readonly={readonly} value={adult} onChange={member => onUpdateMember(member, index)} onDelete={() => onDelete(adult, index)}/>
                        </div>)
                    )}
                    {value.dependants.map((dependant, index) => (
                        <div key={index} className={`${styles.card} ${styles.fullWidth} ${styles.memberDetailContainer}`}>
                            <MemberDetail key={index} label={DEPENDANT} readonly={readonly} value={dependant} onChange={member => onUpdateMember(member, index)} onDelete={() => onDelete(dependant, index)}/>
                        </div>)
                    )}
                </>}
            </Grid>
        </>
    );
};

export {
    HouseholdMembers
};
