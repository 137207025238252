import {createErrorState, ErrorState, FileInfo, FileStatus, getDateFrom, IErrorState, parseDate, Period} from "library";

enum PaymentFileDocType {
    Errors = "errors",
	Register = "register",
}

class PaymentFile implements IErrorState<Partial<PaymentFile>> {
    readonly id: string;
    readonly period: Period;
    readonly program: string;
    readonly name: string;
    readonly size: number;
    readonly createdOn: Date;
    readonly lastModified: Date;
    readonly bankUploadDate?: Date;
    readonly errorFiles: FileInfo[];
    readonly registerFile?: FileInfo;
    readonly notes: string;
    readonly status: FileStatus
    readonly validated: boolean;

    constructor(from: Partial<PaymentFile>) {
        this.id = from?.id ?? crypto.randomUUID();
        this.period = typeof from.period! === "string" ? Period.Create(parseDate(from!.period)) : from.period!;
        this.program = from.program!;
        this.name = from?.name ?? "";
        this.size = from?.size ?? 0;
        this.notes = from?.notes ?? "";
        this.errorFiles = from?.errorFiles?.map(f => new FileInfo(f, f.processing)) ?? [];
        this.registerFile = from?.registerFile;
        this.bankUploadDate = getDateFrom(from.bankUploadDate, undefined);
        this.createdOn = getDateFrom(from!.createdOn);
        this.lastModified = getDateFrom(from!.lastModified);
        this.status = from?.status ?? FileStatus.Generated;
        this.validated = (from as PaymentFile)?.validated ?? false;
    }

    validate(): PaymentFile {
        return new PaymentFile({...this, validated: true});
    }

    get errorState(): ErrorState<Partial<PaymentFile>> {
        return this.validated ? createErrorState<PaymentFile>({
            notes: !this.notes && [FileStatus.Rejected, FileStatus.Cancelled, FileStatus.Closed].includes(this.status) ? "Notes are required." : "",
            registerFile: [FileStatus.Closed].includes(this.status) && !this.registerFile ? "You must upload the payment register file." : ""
        }) : {};
    }

    toJSON() {
        return {
            ...this,
            period: new Period(this.period.year, this.period.month).toJSON()
        };
    }
}

export {
    PaymentFile,
    PaymentFileDocType
};