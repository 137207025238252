import styles from "styles/application.module.scss";
import formStyles from "styles/applicationAssignment.module.scss";

import React, {FC, useEffect, useMemo, useState} from "react";
import {IconCheck, IconCircleX, IconPencil} from "@tabler/icons";
import {Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "store";
import {getStaff} from "store/slices/staff";
import {Assignments} from "library";
import {PpulusLoader} from "components";

const UNASSIGNED = "Unassigned";

const AssignmentsForm: FC<{ value: Assignments, onSave: (assignments: Assignments) => Promise<void> }> = ({value, onSave}) => {
	const dispatch = useDispatch();
	const {loading, items: staff} = useSelector(s => s.staff);
	const [processing, setProcessing] = useState(false);
	const [edit, setEdit] = useState(false);
	const [assignments, setAssignments] = useState<Assignments>(value ?? {primary: {}});

	useEffect(() => {
		if (!edit || staff.length) return;
		dispatch(getStaff());
	}, [edit, dispatch, staff.length]);

	const staffNames = useMemo(() => [UNASSIGNED, ...(staff ?? []).map(s => s?.name ?? "").filter(n => !!n).sort((x, y) => x.localeCompare(y))], [staff]);
	const secondaryStaffNames = useMemo(() => (staffNames ?? []).filter(s => s !== assignments.primary?.name), [assignments, staffNames]);
	
	const set = (value: {primary?: string, secondary?: string}) => {
		const primary = staff.find(s => value.primary === s.name) ?? (value.primary !== UNASSIGNED ? assignments.primary : undefined);
		const secondary = staff.find(s => value.secondary === s.name) ?? (value.secondary !== UNASSIGNED && assignments.secondary?.id !== primary?.id ? assignments.secondary : undefined);
		setAssignments({primary, secondary});	
	};
	
	const onSaveApplication = () => {
		if (!onSave) return;

		setProcessing(true);
		onSave(assignments)
			.then(() => setEdit(false))
			.finally(() => setProcessing(false));
	};
	
	const onCancel = () => {
		setAssignments(value ?? {primary: {}});
		setEdit(false);
	}

	if (loading || processing)
		return <PpulusLoader/>;

	return !edit
		?
			!value?.primary && !value?.secondary
				? <div className={formStyles.selectList}>No Assignments <EditPencil onEdit={() => setEdit(true)} /></div>
				: (
					<div className={formStyles.selectList}>
						<span>Assigned to:</span>
						<span className={`${styles.formLabel} ${formStyles.bold}`}>{value?.primary?.name ?? ""} {(!!value?.primary && !!value?.secondary) && "&"} {value?.secondary?.name ?? ""}</span>
						<EditPencil onEdit={() => setEdit(true)}/>
					</div>
				) 
		: (
			<ButtonGroup className={formStyles.selectList}>
				<FormControl className={formStyles.dropDown}>
					<InputLabel id={"primary-applicant"}>Primary Assignee</InputLabel>
					<Select label={"primary-applicant"} maxRows={10} value={assignments?.primary?.name ?? ""} onChange={v => set({primary: v.target.value})}>
						{staffNames.map((s, i) => <MenuItem key={i} value={s}>{s}</MenuItem>)}
					</Select>
				</FormControl>
				{!!assignments?.primary && <FormControl className={formStyles.dropDown}>
					<InputLabel id={"secondary-applicant"}>Secondary Assignee</InputLabel>
					<Select label={"secondary-applicant"} maxRows={10} value={assignments?.secondary?.name ?? ""} onChange={v => set({secondary: v.target.value})}>
						{secondaryStaffNames.map((s, i) => <MenuItem key={i} value={s}>{s}</MenuItem>)}
					</Select>
				</FormControl>}

				<Button variant={"contained"} className={formStyles.button} onClick={onCancel}><IconCircleX /> Cancel</Button>
				<Button variant={"outlined"} className={formStyles.button} onClick={onSaveApplication}><IconCheck /> Save</Button>
			</ButtonGroup>
		);
};

const EditPencil: FC<{onEdit: () => void}> = ({onEdit}) => {
	return <IconPencil className={styles.edit} onClick={onEdit} />
}

export {
	AssignmentsForm
};