import { IErrorState, createErrorState } from "library/common";
import {Email, Phone } from "library/types";

interface IContact {
	name: string;
	organization?: string;
	phone?: Phone;
	email?: Email;
	relationship?: string;
}

class EmergencyContact implements IContact, IErrorState<EmergencyContact> {
    readonly name: string;
    readonly relationship?: string;
    readonly phone?: Phone;
    readonly validated: boolean;

    constructor(from?: Partial<IContact>) {
        this.name = from?.name ?? "";
        this.relationship = from?.relationship;
        this.phone = from?.phone;

        this.validated = (from as EmergencyContact)?.validated ?? false;
    }

    validate() {
        return new EmergencyContact({ ...this, validated: true });
    }

    get errorState() {
        return this.validated ? createErrorState<EmergencyContact>({}) : {};
    }
}

interface IApplicationContacts {
	contactForResearch: boolean;
	authorizeCommunication: boolean;
	requireFollowup: boolean;

	contacts: IContact[];
	emergencyContactOne?: IContact;
	emergencyContactTwo?: IContact;
}

class ApplicationContacts implements IApplicationContacts {
	readonly contactForResearch: boolean;
	readonly authorizeCommunication: boolean;
	readonly requireFollowup: boolean;
	readonly contacts: IContact[];
	readonly emergencyContactOne?: IContact;
	readonly emergencyContactTwo?: IContact;
	
	constructor(from?: Partial<IApplicationContacts>) {
		this.contactForResearch = from?.contactForResearch ?? false;
		this.authorizeCommunication = from?.authorizeCommunication ?? false;
		this.requireFollowup = from?.requireFollowup ?? false;
		this.contacts = from?.contacts ?? [];
		this.emergencyContactOne =  from?.emergencyContactOne;
		this.emergencyContactTwo =  from?.emergencyContactTwo;
		
		while (this.contacts.length < 2)
			this.contacts.push({name: "", organization: "", phone: {}, email: {}});
	}
}

export type {IContact, IApplicationContacts};
export {ApplicationContacts, EmergencyContact};