import { Alert, AlertTitle, Stack } from "@mui/material";
import { AnnouncementType } from "../library";
import { dispatch, useSelector } from "../store";
import { useEffect } from "react";
import { getAnnouncements } from "../store/slices";

const SiteBanner = () => {
const {items: announcements} = useSelector(state => state.announcements);

  useEffect(() => {
    dispatch(getAnnouncements({}));
  }, []);

  return(
      <Stack spacing={2}>
      {announcements.filter(a => a.type === AnnouncementType.Site)
        .map((announcement) => (
          <Alert
            severity = {"error"}
            variant = {"standard"}
            key = {announcement.message}>
            <AlertTitle>{announcement.title}</AlertTitle>
            {announcement.message}
          </Alert>))}
      </Stack>
  );
};
export default SiteBanner;