import styles from "styles/screeningResult.module.scss";

import React, {FC, useCallback, useEffect, useState} from "react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "store";
import {useNavigate, useParams} from "react-router-dom";
import MuiChip from "@mui/material/Chip";
import {halfWidth, oneThirdWidth, ScreeningResult} from "library";
import {getScreeningResult} from "store/slices/screening";
import {AuditDetails, FormValueDisplay, PpulusLoader} from "components";
import {IconArrowBack} from "@tabler/icons";

const ScreeningResultForm: FC = () => {
	const {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [value, setValue] = useState<ScreeningResult>();
	const {loading} = useSelector(s => s.screening);

	useEffect(() => {
		if (!id) return;
		 
		dispatch(getScreeningResult(id)).unwrap().then(setValue);
	}, [dispatch, id]);

	const navigateToApplication = useCallback(() => navigate(`../../applications/${value!.applicationCode}`, {relative: "path"}), [navigate, value]);
	
	if (loading)
        return <PpulusLoader/>;
	
	if (!value)
		return <div>Screening result could not be found</div>;
	
	return (
		<Grid container className={styles.content}>
			<Grid container width={"100%"}>
				<Grid item flexGrow={"1"}>
					<Typography variant={"h2"} className={styles.header}>
						{value.firstName} {value.lastName} <span className={styles.subtitle}>- Screening Details</span>
						<MuiChip label={`Screened ${value.approved ? "In" : "Out"}`} className={`${styles.chip} ${value.approved ? styles.success : styles.failure}`} />
					</Typography>
				</Grid>
				<Stack justifyContent={"center"} className={styles.header}>
					<Button variant={"outlined"} color={"primary"} startIcon={<IconArrowBack />} size="small" onClick={() => navigate("..", {relative: "path"})}>Return to Screening List</Button>
				</Stack>
			</Grid>
			<Grid container className={styles.formDetailContainer}>
                <Grid item {...oneThirdWidth} className={styles.formDetail}>
                    <FormValueDisplay label={"First Name"} value={value.firstName}/>
                    <FormValueDisplay label={"Middle Name"} value={value.middleName}/>
                    <FormValueDisplay label={"Last Name"} value={value.lastName}/>
                </Grid>
                <Grid item {...oneThirdWidth} className={styles.formDetail}>
                    <FormValueDisplay label={"Preferred Name"} value={value.preferredName}/>
                    <FormValueDisplay label={"Date of Birth"} value={`${value.dateOfBirth}`}/>
                    <FormValueDisplay label={"Gender"} value={value.gender ?? "Unknown"}/>
                </Grid>
				{value.applicationCode &&
                <Grid item {...oneThirdWidth} className={styles.formDetail} justifyContent="flex-end">
                    Proceeded to Application
                    <br/>
					<Button variant={"text"} className={styles.bold} onClick={navigateToApplication}>#{value.applicationCode}</Button>
                </Grid>
				}
			</Grid>

            <Grid container className={styles.screening}>
                <Grid item {...halfWidth}>
                    <FormValueDisplay label={"Desired City"} value={value.address?.city ?? ""}/>
                    <FormValueDisplay label={"Status in Canada"} value={value.citizenship}/>
                </Grid>

                <Grid item {...halfWidth}>
                    <FormValueDisplay label={"Assets or Property above $25,000"} value={value.assetsAboveThreshold}/>
                    <FormValueDisplay label={"Household Income"} value={`${new Intl.NumberFormat("en-CA", {style: "currency", currency: "CAD", maximumFractionDigits: 0}).format(value.householdIncome)}`}/>
                    <FormValueDisplay label={"Lease Agreement"} value={value.signedLease ? "Yes" : "No"}/>
                </Grid>
			</Grid>

            <AuditDetails value={value}/>

            <Grid container className={styles.stretchHeightLarge}/>
		</Grid>
	);
};


export {ScreeningResultForm};