import styles from "styles/gridTable.module.scss";
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton} from "@mui/x-data-grid";

const TableToolbar = () => {
	const doNothing = (e: PointerEvent) => {
	};
	
	return (
		<div className={styles.tableToolbar}>
			<GridToolbarContainer>
				<GridToolbarColumnsButton placeholder={""} className={styles.button} onPointerEnterCapture={doNothing} onPointerLeaveCapture={doNothing}/>
				<GridToolbarFilterButton placeholder={""} className={styles.button} onPointerEnterCapture={doNothing} onPointerLeaveCapture={doNothing}/>
				<GridToolbarDensitySelector placeholder={""} className={styles.button} onPointerEnterCapture={doNothing} onPointerLeaveCapture={doNothing}/>
				<GridToolbarExport className={styles.button} />
			</GridToolbarContainer>
		</div>
	);
};

export {
	TableToolbar
};