import {createSlice} from "@reduxjs/toolkit";
import agent, {FilterOptions} from "api/agent";
import {Announcement, AnnouncementType, IAnnouncement} from "library";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import {RootState} from "store";
import {createAppAsyncThunk} from "./asyncThunk";

const getCurrentOptions = (getState: () => RootState) => {
	const {announcements: {filter, page, pageSize, sort}} = getState();
	return {filter, page, pageSize, sort};
};

const setAnnouncementPage = createAppAsyncThunk("announcements/setPage", async (page: number, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), page};
	await dispatch(getAnnouncements(options));
	return options;
});

const setAnnouncementSort = createAppAsyncThunk("announcements/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), sort};
	await dispatch(getAnnouncements(options));
	return options;
});

const setAnnouncementFilter = createAppAsyncThunk("announcements/filter", async (filter: GridFilterModel, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), filter};
	await dispatch(getAnnouncements(options));
	return options;
});

const getAnnouncement = createAppAsyncThunk("announcements/get", async (id: string, {getState}) => {
	const {announcements: {items: existing}} = getState();

	return existing.find(a => a.id === id) ?? await agent.Announcements.get(id);
});

const saveAnnouncement = createAppAsyncThunk("announcements/save", async (value: Announcement, {dispatch}) => {
	await agent.Announcements.save(value);
	dispatch(getAnnouncements({}));
});

const getAnnouncements = createAppAsyncThunk("announcements/load", async (options: FilterOptions, {getState}) => {
	const fromApi = await agent.Announcements.list({...getCurrentOptions(getState), ...options});
	return {items: fromApi.announcements, count: fromApi.count};
});

const deleteAnnouncement = createAppAsyncThunk("announcements/delete", async (value: { id: string, type: AnnouncementType }, {getState}) => {
	await agent.Announcements.delete(value.id, value.type);
	const { announcements: { items } } = getState();
	return {items: items.filter((i) => i.id !== value.id || i.type !== value.type)};
});

const initialState: FilterOptions & { loading: boolean, items: IAnnouncement[], count: number } = {
	loading: true,
	page: 0,
	pageSize: 15,
	sort: {field: "title", sort: "asc"},
	filter: undefined,
	items: [],
	count: 0
};

const announcements = createSlice({
	name: "allAnnouncements",
	initialState: initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(getAnnouncement.pending, (state) => ({...state, loading: true}));
		builder.addCase(getAnnouncement.fulfilled, (state) => ({...state, loading: false}));
		builder.addCase(saveAnnouncement.pending, (state) => ({...state, loading: true}));
		builder.addCase(saveAnnouncement.fulfilled, (state, {payload}) => ({...state, item: payload, loading: false}));
		builder.addCase(getAnnouncements.pending, (state, {meta}) => ({...state, ...meta.arg, loading: true, items: []}));
		builder.addCase(getAnnouncements.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
		builder.addCase(setAnnouncementPage.fulfilled, (state, {payload}) => ({...state, ...payload}));
		builder.addCase(setAnnouncementSort.fulfilled, (state, {payload}) => ({...state, ...payload}));
		builder.addCase(setAnnouncementFilter.fulfilled, (state, {payload}) => ({...state, ...payload}));
		builder.addCase(deleteAnnouncement.pending, (state) => ({...state, loading: true}));
		builder.addCase(deleteAnnouncement.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
	}
});

export default announcements.reducer;

export {getAnnouncement, getAnnouncements, setAnnouncementPage, setAnnouncementSort, setAnnouncementFilter, saveAnnouncement, deleteAnnouncement};