import styles from "styles/gridTable.module.scss";

import {getGridDateOperators, GridColDef} from "@mui/x-data-grid";
import {BankInfo, CurrencyDisplay, DateDisplay, Payment, PaymentStatus} from "library";
import {createElement, Fragment} from "react";
import {IconCheck} from "@tabler/icons";

class DisbursementRow {
    public static Columns: Record<string, GridColDef<DisbursementRow>> = {
        clientCode: {field: "clientCode", headerName: "Recipient #", width: 130, headerClassName: styles.columnHeader},
        clientName: {field: "clientName", headerName: "Recipient", flex: 1, minWidth: 200, headerClassName: styles.columnHeader, valueGetter: params => `${params.value}${params.row.bankDetails.isOrganization ? " (Org)" : ""}`},
        institution: {field: "institution", headerName: "Institution #", width: 110, headerClassName: styles.columnHeader, valueGetter: params => params.row.bankDetails.institution},
        transit: {field: "transit", headerName: "Transit #", width: 110, headerClassName: styles.columnHeader, valueGetter: params => params.row.bankDetails.transit},
        account: {field: "account", headerName: "Account #", width: 150, headerClassName: styles.columnHeader, valueGetter: params => params.row.bankDetails.account},
        program: {field: "program", headerName: "Program", width: 100, headerClassName: styles.columnHeader},
        period: {field: "period", headerName: "Period", width: 100, filterOperators: getGridDateOperators(), headerClassName: styles.columnHeader},
        amount: {field: "amount", headerName: "Amount", width: 140, headerClassName: styles.columnHeader, valueGetter: v => CurrencyDisplay(v.value)},
        status: {field: "status", headerName: "Status", width: 140, headerClassName: styles.columnHeader},
        paymentDateDisplay: {field: "paymentDateDisplay", headerName: "Payment Date", width: 150, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        cheque: {field: "cheque", headerName: "Cheque", width: 80, headerClassName: styles.columnHeader, renderCell: p => !p.row.bankDetails.chequePayments ? createElement(Fragment) : createElement(IconCheck, {color: "green"})},
        adjustmentMade: {
            field: "adjustmentMade",
            headerName: "Adjusted",
            width: 80,
            headerClassName: styles.columnHeader,
            renderCell: v => !!v.value ? createElement(IconCheck, {color: "green"}) : createElement(Fragment)
        },
        note: {field: "note", headerName: "Notes / Reason", flex: 1, headerClassName: styles.columnHeader}
    };

    constructor(readonly id: string,
                readonly clientCode: string,
                readonly clientName: string,
                readonly bankDetails: BankInfo,
                readonly program: string,
                readonly period: string,
                readonly amount: number,
                readonly status: string,
                readonly paymentDateDisplay: Date | undefined,
                readonly cheque: boolean,
                readonly adjustmentMade: boolean,
                readonly note: string) {
    }

    static From(payment: Payment): DisbursementRow {
        return new DisbursementRow(
            payment.id ?? "",
            payment.clientCode,
            payment.clientName,
            payment.bankDetails,
            payment.program,
            DateDisplay.Period(payment.scheduledDate),
            payment.amount,
            payment.status,
            payment.status === PaymentStatus.Paid ? payment.paymentDate : undefined,
            payment.bankDetails.chequePayments,
            payment.adjustmentMade,
            payment.note
        );
    }
}

export {DisbursementRow};