class SentEmail {
	public readonly createdOn: Date;

	constructor(readonly id: string, readonly subject: string, readonly message: string, createdOn: Date) {
		this.createdOn = new Date(createdOn);
	}
}

export {
	SentEmail
};