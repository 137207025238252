import clientStyles from "styles/client.module.scss";
import styles from "styles/gridTable.module.scss";

import { Button, Card, Typography } from "@mui/material";
import { DataGrid, GridActionsColDef, GridColDef } from "@mui/x-data-grid";
import { IconCheck, IconX } from "@tabler/icons";
import { DateInput, FormValueDisplay } from "components";
import { DateDisplay, getDateFrom, ProgramApproval } from "library";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "store";

class ProgramRow {
    public static Columns: Record<string, GridColDef<ProgramRow>> = {
        name: { field: "name", headerName: "Program", flex: 1, headerClassName: styles.columnHeader },
        startDate: { field: "startDate", headerName: "Start Date", width: 200, headerClassName: styles.columnHeader, valueGetter: v => DateDisplay.Standard(v.value) },
        endDate: { field: "endDate", headerName: "End Date", width: 200, headerClassName: styles.columnHeader, valueGetter: v => DateDisplay.Standard(v.value) },
        active: { field: "active", headerName: "Active", width: 100, headerClassName: styles.columnHeader, renderCell: v => v.value ? <IconCheck color={"green"} /> : <IconX color={"red"} /> }
    };

    constructor(readonly name: string, readonly startDate: Date, readonly endDate: Date, readonly active: boolean) {
    }

    static From(program: ProgramApproval): ProgramRow {
        return new ProgramRow(
            program.name,
            getDateFrom(program.startDate),
            getDateFrom(program.endDate),
            program.isActive ?? false
        );
    }
}

type ClientProgramsProps = {
    programs: ProgramApproval[] | undefined;
    onChange: (programs: ProgramApproval[] | undefined) => void;
}

const ClientPrograms = ({ programs, onChange }: ClientProgramsProps) => {
    const [edittingProgram, setEdittingProgram] = useState<ProgramApproval>();
    const {loading} = useSelector(s => s.client);

    const onDelete = useCallback((program: ProgramApproval) => {
        onChange(programs?.filter(p => p.name !== program.name));
    }, [onChange, programs]);

    const onSave = (program: ProgramApproval) => {
        onChange(programs?.map(p => p.name === program.name ? program : p));
        setEdittingProgram(undefined);
    };

    const columns = useMemo(() => [
        ...Object.values(ProgramRow.Columns),
        ...([{
            field: "actions", type: "actions", flex: 1, headerClassName: styles.columnHeader, getActions: p => [
                <Button onClick={() => setEdittingProgram(p.row)}>Edit</Button>,
                <Button onClick={() => window.confirm("Are you sure you want to delete this program?") && onDelete(p.row)}>Delete</Button>
            ]
        } as GridActionsColDef])
    ], [onDelete]);

    const rows = useMemo(() => programs?.map(ProgramRow.From) ?? [], [programs]);

    return (
        <Card>
            {edittingProgram
                ? <ProgramForm programToEdit={edittingProgram} onChange={onSave} onCancel={() => setEdittingProgram(undefined)}/>
                : <DataGrid autoHeight
                    initialState={{ sorting: { sortModel: [{ field: "name", sort: "asc" }] } }}
                    columns={columns}
                    rows={rows}
                    loading={loading}
                    getRowId={row => `${row.name}-${row.startDate}-${row.endDate}`}
                    getRowClassName={() => styles.row}
                />}
        </Card>
    );
};

type ProgramFormProps = {
    programToEdit: ProgramApproval;
    onChange: (value: ProgramApproval) => void;
    onCancel: () => void;
};

const ProgramForm = ({ programToEdit, onChange, onCancel }: ProgramFormProps) => {
    const [program, setProgram] = useState<ProgramApproval>(programToEdit);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setProgram(programToEdit);
    }, [programToEdit]);

    const set = async (value: Partial<ProgramApproval>) => setProgram({ ...program, ...value });

    const onSave = () => {
        if (program.startDate >= program.endDate) {
            setError("End Date must be greater than Start Date.");
            return;
        }
        onChange(program);
    };

    return (
        <div className={clientStyles.programForm}>
            <Typography variant={"h3"}>Edit Program</Typography>
            <div className={clientStyles.programRow}>
                <FormValueDisplay label={"Program"} value={(program.name)} />
                <DateInput label={"Start Date"} value={program.startDate} onChange={v => set({ startDate: v ?? new Date() })} />
                <DateInput label={"End Date"} value={program.endDate} error={error} onChange={v => set({ endDate: v ?? new Date() })} />
            </div>
            <div className={clientStyles.buttonGroup}>
                <Button className={clientStyles.button} variant={"outlined"} onClick={onCancel}>Cancel</Button>
                <Button className={clientStyles.button} variant={"contained"} onClick={onSave}>Save</Button>
            </div>
        </div>
    );
};

export {
    ClientPrograms
};
