import styles from "styles/application.module.scss";

import {ReactNode} from "react";
import {Application, fullWidth} from "library";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {RateCalculation} from ".";

type SubsidyCalculationProps = {
    application: Application;
}

const SubsidyCalculation: (props: SubsidyCalculationProps) => ReactNode = ({application}) => {
    return (<Grid container className={styles.card}>
            <Grid item {...fullWidth}>
                <Typography variant={"h3"}>Subsidy Calculation</Typography>

                {application.programs.length
                    ? application.programs.map(p => <SubsidyDetail key={p} program={p} application={application}/>)
                    : <i>No programs</i>
                }
            </Grid>
        </Grid>
    );
};

type SubsidyDetailProps = {
    program: string;
    application: Application;
}

const SubsidyDetail: (props: SubsidyDetailProps) => ReactNode = ({program, application}) => {
    return (
        <Accordion defaultExpanded>
            <AccordionSummary className={styles.subsidyHeader} expandIcon={<ExpandMoreIcon/>}>
                <Typography variant={"h4"}>{program}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.subsidyBody}>{RateCalculation.get(program, application)}</AccordionDetails>
        </Accordion>
    );
}

export {
    SubsidyCalculation
}