import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";
import {GeneralSettings} from "library";

const loadSettings = createAppAsyncThunk("settings/load", async (_: void, {getState}) => {
    const {settings: {loading, settings}} = getState();
    if (!loading) return settings;

    return agent.Settings.get();
});

const saveSettings = createAppAsyncThunk("settings/save", async (settings: GeneralSettings) => {
    return agent.Settings.save(settings);
});

const initialState = {
    loading: true,
    settings: new GeneralSettings()
};

const settings = createSlice({
    initialState,
    name: "settings",
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadSettings.pending, (state) => ({...state, loading: true}));
        builder.addCase(loadSettings.fulfilled, (state, {payload: settings}) => ({settings, loading: false}));
        builder.addCase(saveSettings.pending, (state) => ({...state, loading: true}));
        builder.addCase(saveSettings.fulfilled, (state, {payload: settings}) => ({settings, loading: false}));
    }
});

export default settings.reducer;
export {loadSettings, saveSettings};