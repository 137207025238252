import styles from "styles/application.module.scss";

import React, {ReactNode, useState} from "react";
import {Button, Dialog, DialogActions, DialogTitle, Grid, Typography} from "@mui/material";
import {ApplicationDocuments, Document, DocumentTypeMap, fullWidth, IDocuments} from "library";
import {DocumentsCard} from "components/DocumentsCard";

interface ISupportingDocumentsProps {
    documents: ApplicationDocuments;
    readonly?: boolean;
    onView: (fileName: string, documentType: keyof IDocuments) => Promise<void>;
    onDownload: (fileName: string, documentType: keyof IDocuments) => Promise<void>;
    onAdd: (document: File[], type: keyof IDocuments) => Promise<void>;
    onDelete: (document: Document) => Promise<void>;
}

interface IDialogProps {
    open: boolean;
    pending?: boolean;
    document?: Document
}

const SupportingDocuments: (props: ISupportingDocumentsProps) => ReactNode = ({documents, readonly, onView, onDownload, onAdd, onDelete}) => {
    const [dialog, setDialog] = useState<IDialogProps | undefined>();

    const onPendingDelete = (document: Document) => {
        if (dialog?.open) return;
        setDialog({open: true, document: document});
    }

    const onClose = () => setDialog({open: false, pending: false, document: undefined});

    const onAccept = async () => {
        if (!dialog?.document) return;
        setDialog({...dialog, pending: true})
        await onDelete(dialog.document);
        onClose();
    }
        
    return (
        <Grid container className={styles.card}>
            <Grid item {...fullWidth}>
                <Typography variant={"h3"} className={styles.title}>
                    Supporting Documents {!!documents.documentCount && `(${documents.documentCount})`}
                </Typography>
            </Grid>
            <div className={styles.documentGrid}>
                {Object.entries(DocumentTypeMap).map(([k, v], i) =>
                    <DocumentsCard key={i} label={v} files={documents[k].files}
                                   readonly={readonly}
                                   emptyLabel={"No supporting documents uploaded at this moment."}
                                   onView={fileName => onView(fileName, k as keyof IDocuments)}
                                   onDownload={fileName => onDownload(fileName, k as keyof IDocuments)}
                                   onAdd={files => onAdd(files, k as keyof IDocuments)}
                                   onDelete={file => onPendingDelete({file, documentType: k as keyof IDocuments})}/>)
                }
            </div>
            <Dialog
                open={!!dialog?.open}
                onClose={onClose}>
                <DialogTitle>{"Are you sure you wish to delete this document?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={onClose}>No (Cancel)</Button>
                    <Button disabled={dialog?.pending} onClick={onAccept}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export {
    SupportingDocuments
}