import styles from "styles/address.module.scss";

import {FC, useCallback, useEffect, useState} from "react";
import {Address, isEmpty, oneThirdWidth} from "library";
import {useCanadaPost} from "hooks/useCanadaPost";
import {TextField, TextFieldVariants} from "@mui/material";
import {useSelector} from "store";

interface IAddressProps {
	value: Address;
	variant?: TextFieldVariants;
	lookup: string;
	columnWidth?: {xs: number, sm: number, md:  number};
	prependId?: string;
	onChange: (value: Partial<Address>) => void;
}

const AddressForm: FC<IAddressProps> = ({value, variant, lookup, columnWidth, onChange}) => {
	const [lookupValue, setLookupValue] = useState<string>("");
	const {address, lookup: cpLookup} = useSelector(s => s.canadaPost);
	useCanadaPost(lookup);

	const handleChange = useCallback((v: Partial<Address>) => {
		onChange(new Address({...value, ...v}));
	}, [value, onChange]);

	useEffect(() => {
		if (isEmpty(address ?? {}) || lookup !== cpLookup) return;

		onChange(new Address({...address}));
		setLookupValue("");
		// eslint-disable-next-line 
	}, [address]);

	return (
		<div className={styles.address}>
			<TextField variant={variant ?? "standard"} id={lookup} value={lookupValue} label={"Address Lookup"} autoComplete={"address lookup"} fullWidth onChange={v => setLookupValue(v.target.value)}/>
			<TextField variant={variant ?? "standard"} value={value.streetNumber ?? ""} error={value.errorState?.streetNumber} label={"Street Number"} autoComplete={"street number"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({streetNumber: v.target.value})} />
			<TextField variant={variant ?? "standard"} value={value.streetName ?? ""} error={value.errorState?.streetName} label={"Street Name"} autoComplete={"streetname"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({streetName: v.target.value})} />
			<TextField variant={variant ?? "standard"} value={value.unit ?? ""} error={value.errorState?.unit} label={"Unit / Suite Number"} autoComplete={"unitnumber"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({unit: v.target.value})} />
			<TextField variant={variant ?? "standard"} value={value.city ?? ""} error={value.errorState?.city} label={"City"} autoComplete={"city"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({city: v.target.value})} />
			<TextField variant={variant ?? "standard"} value={value.province ?? ""} error={value.errorState?.province} label={"Province"} autoComplete={"province"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({province: v.target.value})} />
			<TextField variant={variant ?? "standard"} value={value.postalCode ?? ""} error={value.errorState?.postalCode} label={"Postal Code"} autoComplete={"postal-code"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({postalCode: v.target.value})} />
			<TextField variant={variant ?? "standard"} value={value.country ?? ""} error={value.errorState?.country} label={"Country"} autoComplete={"shipping country"} {...(columnWidth ?? oneThirdWidth)} onChange={v => handleChange({country: v.target.value})} />
		</div>
	);
};

export {
	AddressForm
};