import styles from "styles/gridTable.module.scss";

import {GridColDef} from "@mui/x-data-grid";
import {Application, ApplicationStatus, ApplicationType, DateDisplay} from "library";

class ApplicationRow {
    public static Columns: Record<string, GridColDef> = {
        code: {field: "code", headerName: "Application ID", minWidth: 120, headerClassName: styles.columnHeader},
        confirmation: {field: "confirmation", headerName: "Confirmation #", minWidth: 120, headerClassName: styles.columnHeader},
        status: {field: "status", headerName: "Status", minWidth: 120, headerClassName: styles.columnHeader},
        applicant: {field: "applicant", headerName: "Applicant", minWidth: 200, headerClassName: styles.columnHeader},
        pointScore: {field: "pointScore", headerName: "Point Score", minWidth: 120, headerClassName: styles.columnHeader},
        bedroomCount: {field: "bedroomCount", headerName: "Bedroom Count", minWidth: 120, headerClassName: styles.columnHeader},
        calculatedBedroomCount: {field: "calculatedBedroomCount", headerName: "Bedroom Count (Calculated)", minWidth: 200, headerClassName: styles.columnHeader},
        programs: {field: "programs", headerName: "Applying for", minWidth: 120, headerClassName: styles.columnHeader},
        createdOn: {field: "createdOn", headerName: "Created On", minWidth: 150, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        modifiedOn: {field: "modifiedOn", headerName: "Modified On", minWidth: 150, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        dateOfBirth: {field: "dateOfBirth", headerName: "Date of Birth", minWidth: 150, valueGetter: v => DateDisplay.Standard(v.value), headerClassName: styles.columnHeader},
        email: {field: "email", headerName: "Email", flex: 1, headerClassName: styles.columnHeader},
        phone: {field: "phone", headerName: "Phone", flex: 1, headerClassName: styles.columnHeader},
        primaryAssignee: {field: "primaryAssignee", headerName: "Primary Assignee", minWidth: 200, headerClassName: styles.columnHeader},
        secondaryAssignee: {field: "secondaryAssignee", headerName: "Secondary Assignee", minWidth: 200, headerClassName: styles.columnHeader}
    };

    constructor(readonly id: string,
                readonly type: ApplicationType,
                readonly confirmation: string,
                readonly code: string,
                readonly applicant: string,
                readonly dateOfBirth: Date | undefined,
                readonly pointScore: number,
                readonly bedroomCount: number,
                readonly calculatedBedroomCount: number,
                readonly email: string | undefined,
                readonly phone: string | undefined,
                readonly programs: string[],
                readonly status: ApplicationStatus,
                readonly createdOn: Date,
                readonly modifiedOn: Date,
                readonly primaryAssignee: string | undefined,
                readonly secondaryAssignee: string | undefined) {
    }

    static From(application: Application): ApplicationRow | undefined {
        return application.type === ApplicationType.Standard ? new ApplicationRow(
            application.id,
            application.type,
            application.confirmation,
            application.code,
            application.applicant.displayName,
            application.applicant.dateOfBirth,
            application.pointScore.total,
            application.bedroomCount,
            application.pointScore.bedroomCount.value ?? 0,
            application.applicant.email?.address,
            application.applicant.primaryPhone?.number,
            application.programs,
            application.status,
            application.createdOn,
            application.modifiedOn,
            application.assignments?.primary?.name,
            application.assignments?.secondary?.name
        ) : undefined;
    }
}

export {ApplicationRow};