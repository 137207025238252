import {BedroomCount, YesNo} from "../enums";
import {getDateFrom} from "../dates";

interface IHousing {
	monthlyRent: number;
	bedrooms: BedroomCount;
	liveInAide: YesNo;
	water: YesNo;
	electricity: YesNo;
	heating: YesNo;
	monthlyUtilities: number;
	leaseExpiry?: Date;
	onAMonthToMonthLease: YesNo;
	inProviderProperty?: YesNo;
}

class Housing implements IHousing {
	readonly bedrooms: BedroomCount;
	readonly electricity: YesNo;
	readonly heating: YesNo;
	readonly water: YesNo;
	readonly leaseExpiry?: Date;
	readonly liveInAide: YesNo;
	readonly monthlyRent: number;
	readonly monthlyUtilities: number;
	readonly onAMonthToMonthLease: YesNo;
	readonly inProviderProperty?: YesNo;
	
	constructor(from?: Partial<IHousing>) {
		this.bedrooms = from?.bedrooms ?? BedroomCount.NotSpecified;
		this.electricity = from?.electricity ?? YesNo.No;
		this.heating = from?.heating ?? YesNo.No;
		this.water = from?.water ?? YesNo.No;
		this.onAMonthToMonthLease = from?.onAMonthToMonthLease ?? YesNo.No;
		this.leaseExpiry = this.onAMonthToMonthLease === YesNo.Yes ? undefined : getDateFrom(from?.leaseExpiry);
		this.liveInAide = from?.liveInAide ?? YesNo.No;
		this.monthlyRent = from?.monthlyRent ?? 0;
		this.monthlyUtilities = from?.monthlyUtilities ?? 0;
		this.inProviderProperty = from?.inProviderProperty;
	}
}

export type {IHousing};
export {Housing};