import {createErrorState, IErrorState} from "library/common";
import {getDateFrom, isValidDate} from "library/dates";
import {ApplicationType} from "library/enums";

export class ApproveApplicationState implements IErrorState<ApproveApplicationState> {
    readonly type: ApplicationType;
    readonly program?: string;
    readonly clientCode?: string;
    readonly specialMessage: string;
    readonly notes: string;
    readonly start: Date;
    readonly amount?: number;
    readonly sendEmail: boolean;
    private readonly validated: boolean;

    constructor(from?: Partial<ApproveApplicationState>) {
        this.type = from?.type ?? ApplicationType.Standard;
        this.program = from?.program;
        this.clientCode = from?.clientCode;
        this.specialMessage = from?.specialMessage ?? "";
        this.notes = from?.notes ?? "";
        this.start = getDateFrom(from?.start);
        this.amount = from?.amount;
        this.sendEmail = from?.sendEmail ?? true;
        this.validated = (from as ApproveApplicationState)?.validated ?? false;
    }

    validate() {
        return new ApproveApplicationState({ ...this, validated: true });
    }

    get errorState() {
        return this.validated ? createErrorState<ApproveApplicationState>({
            start: !this.start
                ? "Required" 
                : isValidDate(this.start)
                    ? ""
                    : "Please use date format YYYY-MM-DD",
            amount: !this.amount || this.amount < 0 ? "Amount must be greater than 0" : "",
            program: !this.program ? "Required" : ""
        }) : {};
    }
}